import React, { useState, useEffect } from 'react';
import { Chart } from 'react-google-charts';
import { isEmpty, map, toInteger } from 'lodash';

import './styles.css';
import { Row, Col, Container, Table, Card } from 'react-bootstrap';
import api from '../../../services/API';
import capitalize from '../../../utils/capitalize';
import { useDispatch } from 'react-redux';
import { desautenticarUsuarioRelatorio } from '../../../store/modules/autenticacao/actions';

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import moment from 'moment-timezone';
import Datatable from '../../DataTable';

export default function Dashboard() {
  const [parceirosCadastrados, setParceirosCadastrados] = useState(0);
  const [valorTotalNf, setValorTotalNf] = useState(0);
  const [parceirosPontuados, setParceirosPontuados] = useState(0);
  const [valorMedioNf, setValorMedioNf] = useState(0);
  const [usuariosTotais, setTopParceirosPontuados] = useState([]);
  const [topProdutos, setTopProdutos] = useState([]);
  const [novosParceiros, setNovosParceiros] = useState([]);
  const [notasEnviadas, setNotasEnviadas] = useState([]);
  const [totalVendas, setTotalVendas] = useState([]);

  const [buscando, setBuscando] = useState(false);
  const dispatch = useDispatch();

  const [usuariosCursos, setUsuariosCursos] = useState([]);
  const [cursosSelecionados, setCursosSelecionados] = useState([]);
  const [usuarioSelecionado, setUsuarioSelecionado] = useState(null);

  const [premiosResgatados, setPremiosResgatados] = useState([]);

  const [resgates, setResgates] = useState([]);

  const [pintoresCadastrados, setPintoresCadastrados] = useState(0);
  const [pintoresAcheSeuPintor, setPintoresAcheSeuPintor] = useState(0);
  const [pintoresComCurso, setPintoresComCurso] = useState(0);
  const [pintoresComResgate, setPintoresComResgate] = useState(0);
  const [pintoresAtivos, setPintoresAtivos] = useState(0);
  const [pintoresInativos, setPintoresInativos] = useState(0);

  useEffect(() => {
  }, [usuarioSelecionado, cursosSelecionados]);

  const buscarDados = async () => {
    setBuscando(true);
    const resultado = await api.get(`/api/relatorio/dashboard`);


    if (resultado) {
      setParceirosCadastrados(resultado.data.parceirosCadastrados);
      setParceirosPontuados(resultado.data.parceirosPontuados);
      setValorMedioNf(resultado.data.valorMedioNf);
      setTopParceirosPontuados(
        resultado.data.usuariosTotais.map((item) => ({
          ...item,
          totalVendido: item.totalVendido ? item.totalVendido : 0,
          mediaNf: item.mediaNf ? parseFloat(item.mediaNf).toFixed(2) : 0,
          pontuou: item.totalVendido ? 'Sim' : 'Não',
        }))
      );
      // console.log(
      //   `resultado.data.topProdutos`,
      //   resultado.data,
      //   resultado.data.topProdutos
      // );
      if (resultado.data.topProdutos) {
        setTopProdutos(resultado.data.topProdutos);
      }
      setValorTotalNf(resultado.data.valorTotalNf);
      setNovosParceiros(resultado.data.novosParceiros);
      setNotasEnviadas(resultado.data.notasEnviadas);
      setTotalVendas(resultado.data.totalVendas);

      if (resultado.data.usuariosCursos) {
        setUsuariosCursos(resultado.data.usuariosCursos);
      }

      if (resultado.data.premiosResgatados) {
        setPremiosResgatados(resultado.data.premiosResgatados);
      }

      if (resultado.data.resgates) {
        setResgates(resultado.data.resgates);
      }

      if (resultado.data.pintoresCadastrados) {
        setPintoresCadastrados(resultado.data.pintoresCadastrados);
      }

      if (resultado.data.pintoresAcheSeuPintor) {
        setPintoresAcheSeuPintor(resultado.data.pintoresAcheSeuPintor);
      }

      if (resultado.data.pintoresComCurso) {
        setPintoresComCurso(resultado.data.pintoresComCurso);
      }

      if (resultado.data.pintoresComResgate) {
        setPintoresComResgate(resultado.data.pintoresComResgate);
      }

      if (resultado.data.pintoresAtivos) {
        setPintoresAtivos(resultado.data.pintoresAtivos);
      }

      if (resultado.data.pintoresInativos) {
        setPintoresInativos(resultado.data.pintoresInativos);
      }
    }
    setBuscando(false);
  };

  useEffect(() => {
    buscarDados();
  }, []);

  const sair = () => {
    dispatch(desautenticarUsuarioRelatorio());
  };

  const columns = [
    {
      dataField: 'nome',
      text: 'Pintor',
      formatter: (celula, valor) => capitalize(valor.nome),
      sort: true,
    },
    {
      dataField: 'celular',
      text: 'Celular',
      formatter: (celula, valor) => {
        if (!valor.celular) return '';
        
        const numero = valor.celular.replace(/\D/g, '');
        
        if (numero.length === 11) {
          return numero.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
        } else if (numero.length === 10) {
          return numero.replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3');
        } else if (numero.length === 8) {
          return numero.replace(/(\d{4})(\d{4})/, '$1-$2');
        } else if (numero.length === 9) {
          return numero.replace(/(\d{5})(\d{4})/, '$1-$2');
        }
        
        return valor.celular;
      },
      sort: true,
      csvFormatter: (celula) => {
        if (!celula) return '';
        
        const numero = celula.replace(/\D/g, '');
        
        if (numero.length === 11) {
          return numero.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
        } else if (numero.length === 10) {
          return numero.replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3');
        } else if (numero.length === 8) {
          return numero.replace(/(\d{4})(\d{4})/, '$1-$2');
        } else if (numero.length === 9) {
          return numero.replace(/(\d{5})(\d{4})/, '$1-$2');
        }
        
        return celula;
      }
    },
    {
      dataField: 'cidade',
      text: 'Cidade',
      sort: true,
    },
    {
      dataField: 'uf',
      text: 'UF',
      sort: true,
    },
    {
      dataField: 'temCursos',
      text: 'Realizou cursos?',
      sort: true,
      formatter: (cell) => cell ? 'Sim' : 'Não'
    },
    {
      dataField: 'programa',
      text: 'Está no Ache Seu Pintor',
      sort: true,
      formatter: (cell) => cell === 1 ? 'Sim' : 'Não'
    },
    {
      dataField: 'estaAtivo',
      text: 'Ativo',
      sort: true,
      formatter: (cell) => cell ? 'Sim' : 'Não'
    },
    {
      dataField: 'diasSemAcesso',
      text: 'Dias sem acesso',
      sort: true,
      formatter: (cell) => {
        if (!cell || cell < 0) return '0';
        return cell.toString();
      }
    },
    {
      dataField: 'qtdeVendas',
      text: 'Qtde Notas',
      sort: true,
      sortFunc: (a, b, order) => {
        if (order === 'asc') {
          return Number(a) - Number(b);
        }
        return Number(b) - Number(a);
      },
      formatter: (cell) => Number(cell).toLocaleString('pt-BR')
    },
    {
      dataField: 'totalVendas',
      text: 'R$ Vendido',
      sort: true,
      sortFunc: (a, b, order) => {
        if (order === 'asc') {
          return Number(a) - Number(b);
        }
        return Number(b) - Number(a);
      },
      formatter: (cell) => Number(cell).toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL'
      })
    },
    {
      dataField: 'mediaNf',
      text: 'Soma Média de NF',
      sort: true,
      sortFunc: (a, b, order, dataField, rowA, rowB) => {
        const mediaA = rowA.totalVendas / (rowA.qtdeVendas || 1);
        const mediaB = rowB.totalVendas / (rowB.qtdeVendas || 1);
        if (order === 'asc') {
          return mediaA - mediaB;
        }
        return mediaB - mediaA;
      },
      formatter: (cell, row) => {
        const media = row.totalVendas / (row.qtdeVendas || 1);
        return Number(media).toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL'
        }).replace(/\u00a0/g, ' ');
      }
    },
    {
      dataField: 'cpf',
      text: 'CPF',
      formatter: (celula, valor) => {
      return valor.cpf.substring(0, 3) + '.XXX.XXX-' + valor.cpf.slice(-2);
      },
      sort: true
    },
    {
      dataField: 'pontuou',
      text: 'Pontuou?',
      sort: true,
      formatter: (cell, row) => row.totalVendas > 0 ? 'Sim' : 'Não'
    }
  ];

  const columnsTotalEstado = [
    {
      dataField: 'produto',
      text: 'Produto',
      sort: true,
    },
    {
      dataField: 'qtde',
      text: 'Quantidade',
      sort: true,
      sortFunc: (a, b, order) => {
        if (order === 'asc') {
          return Number(a) - Number(b);
        }
        return Number(b) - Number(a);
      },
      formatter: (celula, valor) => Number(valor.qtde)
    },
    {
      dataField: 'totalVendido',
      text: 'R$ Vendido',
      sort: true,
      sortFunc: (a, b, order) => {
        if (order === 'asc') {
          return Number(a) - Number(b);
        }
        return Number(b) - Number(a);
      },
      formatter: (celula, valor) => {
        return Number(valor.totalVendido)
          .toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          })
          .toString()
          .replace(/\u00a0/g, ' ');
      },
    },
  ];

  const columnsCursos = [
    {
      dataField: 'nome',
      text: 'Pintor',
      formatter: (celula, valor) => capitalize(valor.nome),
      sort: true,
    },
    {
      dataField: 'qtdeCursos',
      text: 'Quantidade de Cursos',
      sort: true,
    }
  ];

  const columnsCursosDetalhes = [
    {
      dataField: 'nomeCurso',
      text: 'Nome do Curso',
      sort: true,
      formatter: (cell) => cell.trim()
    },
    {
      dataField: 'dataConclusao',
      text: 'Data de Conclusão',
      sort: true,
      formatter: (cell) => moment(cell).format('DD/MM/YYYY')
    }
  ];

  const columnsPremios = [
    {
      dataField: 'premio',
      text: 'Prêmio',
      sort: true
    },
    {
      dataField: 'quantidade',
      text: 'Quantidade de Resgates',
      sort: true,
      formatter: (cell) => Number(cell).toLocaleString('pt-BR')
    }
  ];

  const columnsResgates = [
    {
      dataField: 'id',
      text: 'ID',
      sort: true,
      formatter: (cell) => cell || '-',
      headerStyle: () => ({ width: '80px' }),
      style: { textAlign: 'center' },
    },
    {
      dataField: 'nome',
      text: 'Pintor',
      sort: true,
      formatter: (celula, valor) => capitalize(valor.nome)
    },
    {
      dataField: 'premio',
      text: 'Produto Resgatado',
      formatter: (celula, valor) => 
        valor.obs ? `${valor.premio} - ${valor.obs}` : `${valor.premio}`,
      sort: true,
    },
    {
      dataField: 'pontosGastos',
      text: 'Pontos Utilizados',
      sort: true,
      sortFunc: (a, b, order) => {
        const pontosA = parseFloat(a);
        const pontosB = parseFloat(b);
        
        if (order === 'asc') {
          return pontosA - pontosB;
        }
        return pontosB - pontosA;
      },
      formatter: (cell) => {
        return parseFloat(cell).toLocaleString('pt-BR', {
          minimumFractionDigits: 0,
          maximumFractionDigits: 2
        });
      }
    },
    {
      dataField: 'status',
      text: 'Entregue',
      formatter: (celula, valor) => {
        if (valor.status === 0) return <span><i className="fa fa-times"></i> Não</span>;
        if (valor.status === 1) return <span><i className="fa fa-check"></i> Sim</span>;
        if (valor.status === 2) return <span><i className="fa fa-truck"></i> Em trânsito</span>;
        if (valor.status === 3) return <span><i className="fa fa-times"></i> Cancelado</span>;
        if (valor.status === 4) return <span><i className="fa fa-times"></i> Excluído</span>;
      },
      csvFormatter: (value) => {
        switch (value) {
          case 0: return 'Não';
          case 1: return 'Sim';
          case 2: return 'Em trânsito';
          case 3: return 'Cancelado';
          case 4: return 'Excluído';
          default: return '';
        }
      },
      sort: true,
    },
    {
      dataField: 'resgatadoEm',
      text: 'Data do Resgate',
      formatter: (celula, valor) => moment(valor.resgatadoEm).format('DD/MM/YYYY HH:mm:ss'),
      sort: true,
    },
    {
      dataField: 'previsaoEntrega',
      text: 'Previsão de Entrega',
      formatter: (celula, valor) => {
        return valor.previsaoEntrega
          ? moment(valor.previsaoEntrega).format('DD/MM/YYYY')
          : '-';
      },
      sort: true,
    },
    {
      dataField: 'dataEntrega',
      text: 'Data de Entrega',
      formatter: (celula, valor) => {
        return valor.dataEntrega
          ? moment(valor.dataEntrega).format('DD/MM/YYYY')
          : '-';
      },
      sort: true,
    }
  ];

  const ToSearch = (props) => {
    let input;
    const handleClick = () => {
      props.onSearch(input.value);
    };

    const handleEnterKey = (e) => {
      if (e.key === 'Enter') {
        props.onSearch(input.value);
      }
    };

    return (
      <div className="d-flex justify-content-center align-items-center">
        <input
          className="input-theme busca mr-1"
          ref={(n) => (input = n)}
          type="text"
          onKeyUp={handleEnterKey}
        />
        <button
          className="btn-primario-slim"
          onClick={handleClick}
          style={{ width: 40, height: 40 }}
        >
          <i className="fa fa-search px-2"></i>
        </button>
      </div>
    );
  };

  const options = {
    sizePerPage: 10,
    showTotal: true,
    sizePerPageList: [
      {
        text: '5',
        value: 5,
      },
      {
        text: '10',
        value: 10,
      },
      {
        text: '50',
        value: 50,
      },
      {
        text: '100',
        value: 100,
      },
      {
        text: 'Todos',
        value: usuariosTotais.length,
      },
    ],
    paginationTotalRenderer: (from, to, size) =>
      size > 0 ? (
        <span className="react-bootstrap-table-pagination-total px-2">
          {from}-{to} de {size} resultados
        </span>
      ) : (
        <span className="react-bootstrap-table-pagination-total px-2">
          Nenhum resultado
        </span>
      ),
  };

  const ExportCSV = (props) => {
    const handleClick = () => {
      props.onExport();
    };
    return (
      <div>
        <button className="btn-primario-slim ml-2" onClick={handleClick}>
          <i className="fa fa-file-download px-2"></i>
        </button>
      </div>
    );
  };

  const formatChart = (data, title) => {
    if (isEmpty(data)) return [];
    const array = ['Meses', title];
    const chartData = map(data, (item) => [
      item.mes,
      toInteger(item.total) ?? 0,
    ]);
    return [array, ...chartData];
  };

  const data = formatChart(novosParceiros, 'Novos Pintores');
  const dataNotas = formatChart(notasEnviadas, 'Notas');
  const dataTotal = formatChart(totalVendas, 'Total Notas');

  const optionsChart = {
    chart: {
      title: 'Novos pintores Cadastrados',
      subtitle: 'Pintores cadastrados nos ultimos 12 meses',
    },
  };

  const optionsNota = {
    chart: {
      title: 'Quantidade Notas Enviandas',
      subtitle: 'Quantidade de notas enviandas ultimos 12 meses',
    },
  };

  const optionsTotal = {
    chart: {
      title: 'Total Notas Enviandas',
      subtitle: 'Valor total de notas enviandas ultimos 12 meses',
    },
  };

  const expandRow = {
    onlyOneExpanding: true,
    renderer: row => (
      <div className="p-3">
        <table className="table table-dark text-center rounded">
          <thead>
            <tr>
              <th>Nome do Curso</th>
              <th>Data de Conclusão</th>
            </tr>
          </thead>
          <tbody>
            {row.cursos.map((curso, index) => (
              <tr key={index}>
                <td>{curso.nomeCurso}</td>
                <td>{moment(curso.dataConclusao).format('DD/MM/YYYY')}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    ),
    showExpandColumn: true,
    expandColumnPosition: 'left',
    expandHeaderColumnRenderer: ({ isAnyExpands }) => (
      <i 
        className={`fa fa-${isAnyExpands ? 'minus' : 'plus'}-square`} 
        style={{ color: '#fff' }}
      />
    ),
    expandColumnRenderer: ({ expanded }) => (
      <i 
        className={`fa fa-${expanded ? 'minus' : 'plus'}-square`} 
        style={{ color: '#007bff' }}
      />
    )
  };

  const formatCSVFileName = (baseName) => {
    return `${baseName}-${moment().format('DD-MM-YYYY')}.csv`;
  };

  return (
    <Container fluid className="dashboard-datatable">
      <div className="d-flex flex-row justify-content-between">
        <h4 className="pt-3 pb-3 f-black">Relatório</h4>
        <button onClick={sair} className="border-0 shadow-none">
          Sair
        </button>
      </div>
      <hr className="hr-loja mt-0 mb-4" />
      <Row className="pb-2">
        <Col className="pb-1" xs={12} sm={6} lg>
          <div className="card-dash">
            <div>
              <span className="card-dash-titulo">
                <strong>Pintores Cadastrados</strong>
              </span>
              <div className="card-dash-subtitulo">
                {buscando ? (
                  <i className="fas fa-circle-notch fa-spin"></i>
                ) : (
                  Number(parceirosCadastrados).toLocaleString('pt-BR')
                )}
              </div>
            </div>
            <div className="card-dash-footer"></div>
          </div>
        </Col>
        <Col className="pb-1" xs={12} sm={6} lg>
          <div className="card-dash">
            <div>
              <span className="card-dash-titulo">
                <strong>Pintores Pontuados </strong>
              </span>

              <div className="card-dash-subtitulo">
                {buscando ? (
                  <i className="fas fa-circle-notch fa-spin"></i>
                ) : (
                  Number(parceirosPontuados).toLocaleString('pt-BR')
                )}
              </div>
            </div>
            <div className="card-dash-footer"></div>
          </div>
        </Col>
        <Col className="pb-1" xs={12} sm={6} lg>
          <div className="card-dash">
            <div>
              <span className="card-dash-titulo">
                <strong>Pintores sem Pontuar</strong>
              </span>
              <div className="card-dash-subtitulo">
                {buscando ? (
                  <i className="fas fa-circle-notch fa-spin"></i>
                ) : (
                  Number(
                    parceirosCadastrados - parceirosPontuados
                  ).toLocaleString('pt-BR')
                )}
              </div>
            </div>
            <div className="card-dash-footer"></div>
          </div>
        </Col>
        <Col className="pb-1" xs={12} sm={6} lg>
          <div className="card-dash">
            <div>
              <span className="card-dash-titulo">
                <strong>R$ Medio de NF</strong>
              </span>
              <div>
                <span
                  className="card-dash-subtitulo"
                  style={{ fontSize: '22px' }}
                >
                  {buscando ? (
                    <i className="fas fa-circle-notch fa-spin"></i>
                  ) : (
                    Number(valorMedioNf)
                      .toLocaleString('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                      })
                      .toString()
                      .replace(/\u00a0/g, ' ')
                  )}
                </span>
              </div>
            </div>
            <div className="card-dash-footer"></div>
          </div>
        </Col>
        <Col className="pb-1" xs={12} sm={6} lg>
          <div className="card-dash">
            <span className="card-dash-titulo" style={{ width: '100%' }}>
              <strong>R$ Total de NF </strong>
            </span>
            <div>
              <span
                className="card-dash-subtitulo"
                style={{ fontSize: '22px' }}
              >
                {buscando ? (
                  <i className="fas fa-circle-notch fa-spin"></i>
                ) : (
                  Number(valorTotalNf)
                    .toLocaleString('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    })
                    .toString()
                    .replace(/\u00a0/g, ' ')
                )}
              </span>
            </div>
            <div className="card-dash-footer"></div>
          </div>
        </Col>
      </Row>
      <Row className="pb-2">
        <Col className="pb-1" xs={12} sm={6} md={3}>
          <div className="card-dash">
            <div>
              <span className="card-dash-titulo">
                <strong>Pintores no Ache seu Pintor</strong>
              </span>
              <div className="card-dash-subtitulo">
                {buscando ? (
                  <i className="fas fa-circle-notch fa-spin"></i>
                ) : (
                  Number(pintoresAcheSeuPintor).toLocaleString('pt-BR')
                )}
              </div>
            </div>
            <div className="card-dash-footer"></div>
          </div>
        </Col>

        <Col className="pb-1" xs={12} sm={6} md={3}>
          <div className="card-dash">
            <div>
              <span className="card-dash-titulo">
                <strong>Pintores fora do Ache seu Pintor</strong>
              </span>
              <div className="card-dash-subtitulo">
                {buscando ? (
                  <i className="fas fa-circle-notch fa-spin"></i>
                ) : (
                  Number(parceirosCadastrados - pintoresAcheSeuPintor).toLocaleString('pt-BR')
                )}
              </div>
            </div>
            <div className="card-dash-footer"></div>
          </div>
        </Col>

        <Col className="pb-1" xs={12} sm={6} md={3}>
          <div className="card-dash">
            <div>
              <span className="card-dash-titulo">
                <strong>Pintores que fizeram curso</strong>
              </span>
              <div className="card-dash-subtitulo">
                {buscando ? (
                  <i className="fas fa-circle-notch fa-spin"></i>
                ) : (
                  Number(pintoresComCurso).toLocaleString('pt-BR')
                )}
              </div>
            </div>
            <div className="card-dash-footer"></div>
          </div>
        </Col>

        <Col className="pb-1" xs={12} sm={6} md={3}>
          <div className="card-dash">
            <div>
              <span className="card-dash-titulo">
                <strong>Pintores que não fizeram curso</strong>
              </span>
              <div className="card-dash-subtitulo">
                {buscando ? (
                  <i className="fas fa-circle-notch fa-spin"></i>
                ) : (
                  Number(parceirosCadastrados - pintoresComCurso).toLocaleString('pt-BR')
                )}
              </div>
            </div>
            <div className="card-dash-footer"></div>
          </div>
        </Col>
      </Row>
      <Row className="pb-2">
        <Col className="pb-1" xs={12} sm={6} md={3}>
          <div className="card-dash">
            <div>
              <span className="card-dash-titulo">
                <strong>Pintores que resgataram</strong>
              </span>
              <div className="card-dash-subtitulo">
                {buscando ? (
                  <i className="fas fa-circle-notch fa-spin"></i>
                ) : (
                  Number(pintoresComResgate).toLocaleString('pt-BR')
                )}
              </div>
            </div>
            <div className="card-dash-footer"></div>
          </div>
        </Col>

        <Col className="pb-1" xs={12} sm={6} md={3}>
          <div className="card-dash">
            <div>
              <span className="card-dash-titulo">
                <strong>Pintores que não resgataram</strong>
              </span>
              <div className="card-dash-subtitulo">
                {buscando ? (
                  <i className="fas fa-circle-notch fa-spin"></i>
                ) : (
                  Number(parceirosCadastrados - pintoresComResgate).toLocaleString('pt-BR')
                )}
              </div>
            </div>
            <div className="card-dash-footer"></div>
          </div>
        </Col>

        <Col className="pb-1" xs={12} sm={6} md={3}>
          <div className="card-dash">
            <div>
              <span className="card-dash-titulo">
                <strong>Pintores ativos</strong>
              </span>
              <div className="card-dash-subtitulo">
                {buscando ? (
                  <i className="fas fa-circle-notch fa-spin"></i>
                ) : (
                  Number(pintoresAtivos).toLocaleString('pt-BR')
                )}
              </div>
            </div>
            <div className="card-dash-footer"></div>
          </div>
        </Col>

        <Col className="pb-1" xs={12} sm={6} md={3}>
          <div className="card-dash">
            <div>
              <span className="card-dash-titulo">
                <strong>Pintores inativos</strong>
              </span>
              <div className="card-dash-subtitulo">
                {buscando ? (
                  <i className="fas fa-circle-notch fa-spin"></i>
                ) : (
                  Number(pintoresInativos).toLocaleString('pt-BR')
                )}
              </div>
            </div>
            <div className="card-dash-footer"></div>
          </div>
        </Col>
      </Row>
      <Row className="p-3">
        <Col xs className="p-2" style={{ background: '#fff' }}>
          <Chart
            chartType="Bar"
            width="100%"
            height="200px"
            data={data}
            options={optionsChart}
          />
        </Col>
      </Row>
      <Row className="p-3">
        <Col xs className="p-2" style={{ background: '#fff' }}>
          <Chart
            chartType="Bar"
            width="100%"
            height="200px"
            data={dataNotas}
            options={optionsNota}
          />
        </Col>
      </Row>
      <Row className="p-3">
        <Col xs className="p-2" style={{ background: '#fff' }}>
          <Chart
            chartType="Bar"
            width="100%"
            height="300px"
            data={dataTotal}
            options={optionsTotal}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} xl={12} className="mb-4">
          <div className="table-bottom-bar" style={{ marginBottom: '15px' }}></div>
          <Datatable
            title="Pintores"
            data={usuariosTotais}
            columns={columns}
            enableInternalExport
            enableInternalSearch
            csvProps={{
              fileName: 'Pintores-Cadastrados',
              separator: ';',
              noAutoBOM: false,
            }}
          />
          <div className="table-bottom-bar"></div>
        </Col>
      </Row>
      <Row>
        <Col xs={12} xl={12} className="mb-4">
          <Datatable
            title="Top 20 produtos mais vendidos"
            data={topProdutos}
            columns={columnsTotalEstado}
            enableInternalExport
            enableInternalSearch
            csvProps={{
              fileName: 'Top-20-Produtos-Vendidos',
              separator: ';',
              noAutoBOM: false,
            }}
          />
          <div className="table-bottom-bar"></div>
        </Col>
      </Row>
      <Row>
        <Col xs={12} xl={12} className="mb-4">
          <Datatable
            title="Pintores X Cursos"
            data={usuariosCursos}
            columns={columnsCursos}
            enableInternalSearch
            enableInternalExport
            expandRow={expandRow}
            csvProps={{
              fileName: 'Pintores-e-Cursos',
            }}
          />
          <div className="table-bottom-bar"></div>
        </Col>
      </Row>
      {usuarioSelecionado && cursosSelecionados.length > 0 && (
        <Row>
          <Col xs={12} xl={12} className="mb-4">
            <Card border="light" className="shadow-sm mb-4">
              <Card.Body>
                <h5>Cursos de {usuarioSelecionado.nome}</h5>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>Nome do Curso</th>
                      <th>Data de Conclusão</th>
                    </tr>
                  </thead>
                  <tbody>
                    {cursosSelecionados.map((curso, index) => (
                      <tr key={index}>
                        <td>{curso.nomeCurso.trim()}</td>
                        <td>{moment(curso.dataConclusao).format('DD/MM/YYYY')}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )}
      <Row>
        <Col xs={12} xl={12} className="mb-4">
          <Datatable
            title="Brindes Mais Resgatados"
            data={premiosResgatados}
            columns={columnsPremios}
            hover={true}
            enableInternalSearch
            enableInternalPagination
            enableInternalExport
            csvProps={{
              fileName: 'Premios-Mais-Resgatados',
              separator: ';',
              noAutoBOM: false,
            }}
          />
          <div className="table-bottom-bar"></div>
        </Col>
      </Row>
      <Row>
        <Col xs={12} xl={12} className="mb-4">
          <Datatable
            title="Resgates"
            data={resgates}
            columns={columnsResgates}
            hover={true}
            enableInternalSearch
            enableInternalPagination
            enableInternalExport
            csvProps={{
              fileName: 'Historico-Resgates',
              separator: ';',
              noAutoBOM: false,
            }}
          />
        </Col>
      </Row>
    </Container>
  );
}
