import React, { useEffect } from 'react';
import { Container, Carousel } from 'react-bootstrap';
import './styles.css';
import { Link } from 'react-router-dom';
import Footer from '../../../components/Loja/Footer';
import Header from '../../../components/Loja/Header';


export default function Home() {
  useEffect(() => {
    document.title = 'Clube Pro Pintor - Clube de Fidelidade';
  }, []);

  return (
    <>
      <Header auto />
      <div className="d-block d-md-none justify-content-center align-items-center">
        <div className="mt-5">
          <Carousel interval={3000}>
            <Carousel.Item>
              <Link to="/cadastrar">
                <img
                  className="full-width banner-home"
                  src="imagens/banner-mobile.jpg"
                  alt="Clube Pro Pintor"
                ></img>
              </Link>
            </Carousel.Item>
            <Carousel.Item>
              <a href="https://www.acheseupintor.com.br/">
                <img
                  className="w-100 full-width banner2-home-mobile"
                  src="imagens/banner2-home-mobile.jpg"
                  alt="Clube Pro Pintor"
                ></img>
              </a>
            </Carousel.Item>
          </Carousel>
        </div>
      </div>
      <Container className="d-flex flex-column justify-content-center align-items-center">
        <div className="mt-5">
          <Carousel interval={3000}>
            <Carousel.Item>
              <Link to="/cadastrar" className="d-none d-md-block">
                <img
                  className="w-100 banner-home"
                  src="imagens/banner.jpg"
                  alt="Clube Pro Pintor"
                ></img>
              </Link>
            </Carousel.Item>
            <Carousel.Item>
              <a href="https://www.acheseupintor.com.br/" className="d-none d-md-block">
                <img
                  className="w-100 banner2-home"
                  src="imagens/banner2-home.jpg"
                  alt="Clube Pro Pintor"
                ></img>
              </a>
            </Carousel.Item>
          </Carousel>
        </div>
        <div className="p-3 f-22">
          <hr className="hr-loja mt-5 mb-4" />
          <span className="f-blue font-weight-bold font-italic f-22 mr-md-5 ml-md-5">
            SWPRO - Canal de apoio ao pintor profissional Programa de fidelidade
          </span>
          <br></br>
          <br></br>
          <span className="d-flex text-justify font-color-secondary font-italic mr-md-5 ml-md-5">
            SWPRO é um programa em que pintores encontram informações úteis para
            seu desenvolvimento pessoal e profissional, atualizam-se com as
            regulamentações de trabalho e fortalecem o relacionamento com outros
            pintores, contribuindo com opiniões e sugestões. E, no Programa de
            Fidelidade SWPRO, suas compras valem pontos! Faça parte e conquiste
            prêmios incríveis.
          </span>
          <div className="mt-5 mb-5 d-flex w-100 justify-content-center align-items-center">
            <a href="cadastrar" className="btn-cadastrar">
              Cadastre-se aqui
            </a>
          </div>
          <div className="mt-4 mb-4 d-block d-md-flex flex-row justify-content-center align-items-center">
            <span className="font-color-secondary font-italic">
              Conheça nossas redes sociais:
            </span>
            <div className="d-flex align-items-center mt-3 mb-3 mt-md-0 mb-md-0 ml-md-3 mr-md-3">
              <i className="fab fa-instagram-square f-30 f-blue mr-2"></i>
              <span className="font-italic">
                <a
                  className="link-clean font-color-secondary"
                  href="http://www.instagram.com/swpropintor/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  @swpropintor
                </a>
              </span>
            </div>
            <div className="d-flex align-items-center mb-3 mb-md-0 mr-md-3">
              <i className="fab fa-youtube-square f-30 f-blue mr-2"></i>
              <span className="font-italic">
                <a
                  className="link-clean font-color-secondary"
                  href="https://www.youtube.com/sherwinwilliamsbr"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  /sherwinwilliamsbr
                </a>
              </span>
            </div>
            <div className="d-flex align-items-center">
              <span className="font-italic">
                <a
                  className="link-clean font-color-secondary"
                  href="http://www.swpropintor.com.br"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  www.swpropintor.com.br
                </a>
              </span>
            </div>
          </div>
          <hr className="hr-loja mt-4 d-flex mb-3" />
        </div>
      </Container>

      <Footer />
    </>
  );
}
