import React, { useState, useEffect } from 'react';
import { Modal, Row, Col, Tabs, Tab } from 'react-bootstrap';
import api from '../../../../services/API';
import { toast } from 'react-toastify';
import { isEmpty } from 'lodash';

export default function ModalAula({
  show,
  id,
  onHide,
  onAulaClose,
  idModulo,
  data,
}) {
  const [alterando, setAlterando] = useState(false);

  const [aula, setAula] = useState(false);
  const [avaliacao, setAvaliacao] = useState(false);

  const [materiais, setMateriais] = useState([]);
  const [perguntas, setPerguntas] = useState([]);
  const [pergunta, setPergunta] = useState({});

  useEffect(() => {
    if (data) {
      setAula({ descricao: data.descricao, nome: data.nome });
      setMateriais(data.Materiais);
      if (!isEmpty(data.Avaliacoes)) {
        const tempData = data.Avaliacoes[0];
        tempData.perguntas =
          typeof tempData.perguntas === 'string'
            ? JSON.parse(tempData.perguntas)
            : tempData.perguntas;

        setPergunta({ nome: tempData.nome, descricao: tempData.descricao });
        setPerguntas(tempData.perguntas);
        setAvaliacao(tempData);
      } else {
        setAvaliacao({});
      }
    }
  }, [data]);

  const onCreate = async (e) => {
    e.preventDefault();

    setAlterando(true);

    const formData = new FormData(e.target);

    const resultado = await api.post(
      `/api//cursos/curso/${id}/modulo/${idModulo}/aula`,
      formData
    );

    if (resultado?.data) {
      toast.success(`Aula criada com sucesso`, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      onAulaClose();
    }
    setAlterando(false);
  };

  const onUpdate = async (e) => {
    e.preventDefault();

    setAlterando(true);

    const formData = new FormData(e.target);

    const resultado = await api.put(`/api/cursos/aula/${data.id}`, formData);

    if (resultado?.data) {
      toast.success(`Aula atualizada com sucesso`, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      onAulaClose();
    }
    setAlterando(false);
  };

  const onDelete = async (e) => {
    e.preventDefault();

    setAlterando(true);

    const resultado = await api.delete(`/api//cursos/aula/${data.id}`);

    if (resultado) {
      toast.success(`Aula deletado com sucesso`, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      onAulaClose();
    }

    setAlterando(false);
  };

  const onChange = (e) => {
    const v = e.target.value;
    const n = e.target.name;

    setAula({ ...aula, [n]: v });
  };
  const onChangePergunta = (e) => {
    const v = e.target.value;
    const n = e.target.name;

    setPergunta({ ...pergunta, [n]: v });
  };

  const [key, setKey] = useState('home');

  const createAvaliacao = async (e) => {
    e.preventDefault();

    setAlterando(true);
    try {
      const resultado = await api.post(
        `/api//cursos/curso/${id}/modulo/${idModulo}/aula/${data.id}/avaliacao`,
        {
          ...pergunta,
          perguntas: [],
        }
      );

      setAvaliacao(resultado?.data);

      if (resultado?.data) {
        toast.success(`Avaliação criada com sucesso`, {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 10000,
        });
      }
    } catch (error) {}

    setAlterando(false);
  };

  const updateAvaliacao = async (e) => {
    e.preventDefault();

    setAlterando(true);
    try {
      const data = isEmpty(pergunta)
        ? { ...pergunta, perguntas: [...perguntas] }
        : {
            ...pergunta,
            perguntas: [
              ...perguntas,
              {
                pergunta: pergunta.pergunta,
                respostas_possiveis: [
                  pergunta.opcao1,
                  pergunta.opcao2,
                  pergunta.opcao3,
                ],
                resposta_correta: pergunta.certa,
              },
            ],
          };

      const resultado = await api.put(
        `/api/cursos/avaliacao/${avaliacao?.id}`,
        data
      );

      const tempData = resultado?.data;
      setPerguntas(tempData.perguntas);
      setAvaliacao(tempData);
      setPergunta({});
      if (resultado?.data) {
        toast.success(`Pergunta criada com sucesso`, {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 10000,
        });
      }
    } catch (error) {
      console.log(error);
    }

    setAlterando(false);
  };

  const removePergunta = (e, index) => {
    const temp = perguntas;

    setPerguntas(temp.filter((v, i) => i !== index));
  };

  return (
    <Modal
      show={true}
      onHide={onAulaClose}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      scrollable
    >
      <Modal.Header closeButton>
        <Modal.Title>Aula {data ? ` - ${data?.nome}` : ''}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-0" style={{ height: '27rem' }}>
        <Tabs className="mb-3" activeKey={key} onSelect={(k) => setKey(k)}>
          <Tab
            eventKey="home"
            title={<span style={{ color: 'black' }}>Aula</span>}
          >
            <div className="d-flex justify-content-center align-items-center">
              <form
                className="form p-4 d-flex justify-content-center align-items-center"
                id="form"
                onSubmit={data ? onUpdate : onCreate}
              >
                <Row>
                  <Col className="d-flex flex-column" xs={12}>
                    <label htmlFor="nome">Nome</label>
                    <input
                      id="nome"
                      name="nome"
                      type="text"
                      placeholder="Nome"
                      value={aula.nome}
                      onChange={onChange}
                    />
                  </Col>
                  <Col className="d-flex flex-column" xs={12}>
                    <label htmlFor="descricao">Descrição</label>
                    <input
                      id="descricao"
                      name="descricao"
                      type="text"
                      placeholder="Descrição"
                      value={aula.descricao}
                      onChange={onChange}
                    />
                  </Col>
                  <Col className="d-flex flex-column" xs={12} sm={6}>
                    <label htmlFor="linkVideo">Link Video</label>
                    <input
                      id="linkVideo"
                      name="linkVideo"
                      type="file"
                      placeholder="Link Video"
                    />
                  </Col>
                  <Col className="d-flex flex-column" xs={12} sm={6}>
                    <label htmlFor="linkCapa">Link Capa</label>
                    <input
                      type="file"
                      id="linkCapa"
                      name="linkCapa"
                      placeholder="Link Capa"
                    />
                  </Col>
                </Row>{' '}
                {data && (
                  <button
                    form="form"
                    className="btn btn-danger m-1"
                    onClick={onDelete}
                  >
                    EXCLUIR AULA
                  </button>
                )}
              </form>
            </div>
          </Tab>
          <Tab
            eventKey="profile"
            title={<span style={{ color: 'black' }}>Avaliação</span>}
          >
            <div className="d-flex justify-content-center align-items-center">
              <form
                className="form  d-flex justify-content-center align-items-center"
                id="form"
                onSubmit={
                  isEmpty(avaliacao) ? createAvaliacao : updateAvaliacao
                }
              >
                <Row>
                  <Col className="d-flex flex-column" xs={12}>
                    <label htmlFor="nome">Nome </label>
                    <input
                      id="nome"
                      name="nome"
                      type="text"
                      placeholder="Nome"
                      value={pergunta.nome}
                      onChange={onChangePergunta}
                    />
                  </Col>{' '}
                  <Col className="d-flex flex-column" xs={12}>
                    <label htmlFor="descricao">Descrição</label>
                    <input
                      id="descricao"
                      name="descricao"
                      type="text"
                      placeholder="Descrição"
                      value={pergunta.descricao}
                      onChange={onChangePergunta}
                    />
                  </Col>{' '}
                  {isEmpty(avaliacao) && (
                    <Col>
                      {' '}
                      <div className="d-flex justify-content-center align-items-center w-100">
                        {alterando ? (
                          <button
                            type="submit"
                            className="btn btn-success m-1"
                            disabled
                          >
                            ALTERANDO...
                          </button>
                        ) : (
                          <button type="submit" className="btn btn-success m-1">
                            SALVAR AVALIAÇÂO
                          </button>
                        )}
                      </div>
                    </Col>
                  )}
                </Row>
                <hr className="hr-loja mt-0" />

                {!isEmpty(avaliacao) && (
                  <Row>
                    <Col className="d-flex flex-column" xs={12}>
                      <label htmlFor="pergunta">Pergunta</label>
                      <input
                        id="pergunta"
                        name="pergunta"
                        type="text"
                        placeholder="Pergunta"
                        value={pergunta.pergunta || ''}
                        onChange={onChangePergunta}
                      />
                    </Col>{' '}
                    <Col className="d-flex flex-column" xs={6}>
                      <label htmlFor="nome">Opção 0</label>
                      <input
                        id="opcao1"
                        name="opcao1"
                        type="text"
                        placeholder="Opção 0"
                        value={pergunta.opcao1 || ''}
                        onChange={onChangePergunta}
                      />
                    </Col>{' '}
                    <Col className="d-flex flex-column" xs={6}>
                      <label htmlFor="nome">Opção 1</label>
                      <input
                        id="opcao2"
                        name="opcao2"
                        type="text"
                        placeholder="Opção 1"
                        value={pergunta.opcao2 || ''}
                        onChange={onChangePergunta}
                      />
                    </Col>{' '}
                    <Col className="d-flex flex-column" xs={6}>
                      <label htmlFor="nome">Opção 2</label>
                      <input
                        id="opcao3"
                        name="opcao3"
                        type="text"
                        placeholder="Opção 2"
                        value={pergunta.opcao3 || ''}
                        onChange={onChangePergunta}
                      />
                    </Col>{' '}
                    <Col className="d-flex flex-column" xs={6}>
                      <label htmlFor="certa">Número da resposta certa</label>
                      <input
                        id="certa"
                        name="certa"
                        type="number"
                        placeholder="Resposta Certa"
                        value={pergunta.certa || ''}
                        onChange={onChangePergunta}
                      />
                    </Col>{' '}
                    <Col xs={12}>
                      {' '}
                      <div className="d-flex justify-content-center align-items-center w-100">
                        {alterando ? (
                          <button
                            type="submit"
                            className="btn btn-success m-1"
                            disabled
                          >
                            ALTERANDO...
                          </button>
                        ) : (
                          <button type="submit" className="btn btn-success m-1">
                            SALVAR PERGUNTA
                          </button>
                        )}
                      </div>
                    </Col>
                    {avaliacao &&
                      perguntas.map((p, i) => (
                        <Col xs={6}>
                          <div className="d-flex justify-content-between align-items-center mt-2 box-pergunta p-1">
                            <span>{p.pergunta}</span>{' '}
                            <i
                              class="fas fa-trash"
                              onClick={() => removePergunta(p, i)}
                              role="button"
                            />
                          </div>
                        </Col>
                      ))}
                  </Row>
                )}
              </form>
            </div>
          </Tab>
        </Tabs>
      </Modal.Body>
      <Modal.Footer>
        {key === 'home' && (
          <div className="d-flex justify-content-center align-items-center w-100">
            {alterando ? (
              <button type="submit" className="btn btn-success m-1" disabled>
                ALTERANDO...
              </button>
            ) : (
              <button type="submit" form="form" className="btn btn-success m-1">
                {data ? 'ALTERAR' : 'CADASTRAR'}
              </button>
            )}

            <button
              type="button"
              className="btn btn-danger m-1"
              onClick={onHide}
            >
              CANCELAR
            </button>
          </div>
        )}
      </Modal.Footer>
    </Modal>
  );
}
