/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import ModalMidia from '../ModalMidia';
import { Row, Col } from 'react-bootstrap';
import moment from 'moment-timezone';
import sortFunc from '../../../utils/ordernarColunaInt';
import RemoteTable from '../RemoteTable';
import capitalize from '../../../utils/capitalize';

export default function HistoricoPontuacoes() {
  const [dataInicial, setDataInicial] = useState(
    moment().subtract(7, 'days').toISOString().substr(0, 10)
  );
  const [dataFinal, setDataFinal] = useState(
    moment().toISOString().substr(0, 10)
  );
  const [tipo, setTipo] = useState('');
  const [mostrarModalFoto, setMostrarModalFoto] = useState(false);
  const [arquivo, setArquivo] = useState([]);
  const [filtros, setFiltros] = useState({
    dataFinal: moment().toISOString().substr(0, 10),
    dataInicial: moment().subtract(7, 'days').toISOString().substr(0, 10),
  });

  const setarFiltros = (e) => {
    e.preventDefault();

    setFiltros({
      dataInicial,
      dataFinal,
    });
  };

  const columns = [
    {
      text: 'ID',
      dataField: 'idVenda',
      sort: true,
    },
    {
      text: 'Pintor',
      dataField: 'pintor',
      sort: true,
      formatter: (celula, valor) => {
        return capitalize(valor.pintor);
      },
      style: { textAlign: 'left' },
      headerStyle: { textAlign: 'left' },
    },
    {
      dataField: 'cpf',
      text: 'CPF',
      formatter: (celula, valor) => {
        let cpf = valor.cpf.replace(/[^\d]/g, '');
        return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
      },
      csvFormatter: (celula, valor) => {
        let cpf = valor.cpf.replace(/[^\d]/g, '');
        return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
      },
    },
    {
      dataField: 'celular',
      text: 'Celular',
      formatter: (celula, valor) => {
        return `(${valor.celular.substring(0, 2)}) ${valor.celular.substring(
          2,
          7
        )}-${valor.celular.substring(7)}`;
      },
      csvFormatter: (celula, valor) => {
        return `(${valor.celular.substring(0, 2)}) ${valor.celular.substring(
          2,
          7
        )}-${valor.celular.substring(7)}`;
      },
    },
    {
      text: 'Email',
      dataField: 'email',
      sort: true,
    },
    {
      text: 'Dias sem Pontuar',
      dataField: 'diasSemPontuar',
      sort: sortFunc,
      formatter: (celula, valor) => {
        return Math.floor(Number(valor.diasSemPontuar)).toLocaleString('pt-BR');
      },
      csvFormatter: (celula, valor) => {
        return Math.floor(Number(valor.diasSemPontuar)).toLocaleString('pt-BR');
      },
    },
    {
      dataField: 'endCidade',
      text: 'Cidade / UF',
      sort: true,
      formatter: (celula, valor) => {
        return `${valor.cidade} / ${valor.estado}`;
      },
      csvFormatter: (celula, valor) => {
        return `${valor.cidade} / ${valor.estado}`;
      },
    },

    {
      text: 'Cod. Produto',
      dataField: 'codProduto',
      sort: true,
    },
    {
      text: 'Produto',
      dataField: 'produto',
      sort: true,
    },
    {
      text: 'Qtde',
      dataField: 'qtde',
      sort: sortFunc,
      formatter: (celula, valor) => {
        return Number(valor.qtde).toLocaleString('pt-BR');
      },
      csvFormatter: (celula, valor) => {
        return Number(valor.qtde).toLocaleString('pt-BR');
      },
    },
    {
      text: 'Valor Unitário',
      dataField: 'valorUnitario',
      sort: sortFunc,
      formatter: (celula, valor) => {
        return Number(valor.valorUnitario)
          .toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          })
          .toString()
          .replace(/\u00a0/g, ' ');
      },
      csvFormatter: (celula, valor) => {
        return Number(valor.valorUnitario)
          .toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          })
          .toString()
          .replace(/\u00a0/g, ' ');
      },
    },
    {
      text: 'Valor Total',
      dataField: 'valorTotal',
      sort: sortFunc,
      formatter: (celula, valor) => {
        return Number(valor.valorTotal)
          .toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          })
          .toString()
          .replace(/\u00a0/g, ' ');
      },
      csvFormatter: (celula, valor) => {
        return Number(valor.valorTotal)
          .toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          })
          .toString()
          .replace(/\u00a0/g, ' ');
      },
    },
    {
      text: 'Pontos Unitário',
      dataField: 'valorPontos',
      sort: sortFunc,
      formatter: (celula, valor) => {
        return Number(valor.valorPontos).toLocaleString('pt-BR');
      },
      csvFormatter: (celula, valor) => {
        return Number(valor.valorPontos).toLocaleString('pt-BR');
      },
    },
    {
      text: 'Pontos Total',
      dataField: 'valorTotalPontos',
      sort: sortFunc,
      formatter: (celula, valor) => {
        return Number(valor.valorTotalPontos).toLocaleString('pt-BR');
      },
      csvFormatter: (celula, valor) => {
        return Number(valor.valorTotalPontos).toLocaleString('pt-BR');
      },
    },
    {
      text: 'Tipo de Venda',
      dataField: 'tipoVenda',
      sort: true,
    },
    {
      text: 'Cód. Cupom/NF',
      dataField: 'identificadorVenda',
      sort: true,
    },
    {
      text: 'Data Venda',
      dataField: 'realizadoEm',
      formatter: (celula, valor) => {
        return moment(valor.realizadoEm).format('DD/MM/YYYY');
      },
      csvFormatter: (celula, valor) => {
        return moment(valor.realizadoEm).format('DD/MM/YYYY');
      },
      sort: true,
    },
    {
      text: 'Data Pontuado',
      dataField: 'pontuadoEm',
      formatter: (celula, valor) => {
        return moment(valor.pontuadoEm).format('DD/MM/YYYY HH:mm:ss');
      },
      csvFormatter: (celula, valor) => {
        return moment(valor.pontuadoEm).format('DD/MM/YYYY HH:mm:ss');
      },
      sort: true,
    },
    {
      text: 'Foto',
      dataField: 'dirFoto',
      formatter: (celula, valor) =>
        valor.diretorioArquivo ? (
          <button
            className="btn-limpo f-12 p-1"
            onClick={() => {
              visualizarModalFoto(valor.diretorioArquivo, valor.tipo);
            }}
          >
            <i className="fas fa-image"></i>
          </button>
        ) : (
          <button className="btn-limpo f-12 p-1" disabled>
            SEM FOTO
          </button>
        ),
      csvExport: false,
    },
    {
      text: 'Loja',
      dataField: 'revenda',
      sort: true,
      formatter: (celula, valor) => {
        return capitalize(valor.revenda);
      },
    },
    {
      dataField: 'cnpj',
      text: 'CNPJ',
      sort: true,
      formatter: (celula, valor) => {
        if (valor.cnpj) {
          let cnpj = valor.cnpj.replace(/[^\d]/g, '');
          return cnpj.replace(
            /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
            '$1.$2.$3/$4-$5'
          );
        } else {
          return '';
        }
      },
      csvFormatter: (celula, valor) => {
        if (valor.cnpj) {
          let cnpj = valor.cnpj.replace(/[^\d]/g, '');
          return cnpj.replace(
            /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
            '$1.$2.$3/$4-$5'
          );
        } else {
          return '';
        }
      },
    },
    {
      dataField: 'cidadeRevenda',
      text: 'Cidade',
      sort: true,
      formatter: (celula, valor) => {
        if (valor.cidadeRevenda) {
          return capitalize(`${valor.cidadeRevenda} / ${valor.estadoRevenda}`);
        } else {
          return '';
        }
      },
    },
    {
      text: 'Revenda cadastrada?',
      dataField: 'revendaCadastrada',
      sort: true,
    },
    {
      text: 'Operador',
      dataField: 'operador',
      sort: true,
    },
  ];

  const visualizarModalFoto = (dirFoto, tipo) => {
    if (dirFoto.endsWith('.pdf')) {
      window.open(`${process.env.REACT_APP_API_BASE_URL}/arquivos/${dirFoto}`);
    } else {
      setArquivo(dirFoto);
      setTipo(tipo);
      setMostrarModalFoto(true);
    }
  };

  const fecharModalFoto = () => {
    setMostrarModalFoto(false);
  };

  return (
    <Container fluid>
      <h4 className="pt-3 pb-3 f-black">Histórico</h4>
      <hr className="hr-loja mt-0 mb-4" />
      <Row className="mb-3">
        <Col xs={12} sm={3} md={3} lg={2} className="d-flex flex-column">
          <label>De</label>
          <input
            type="date"
            className="input-theme"
            value={dataInicial}
            onChange={(e) => setDataInicial(e.target.value)}
            max="2999-12-31"
          ></input>
        </Col>
        <Col xs={12} sm={3} md={3} lg={2} className="d-flex flex-column">
          <label>Até</label>
          <input
            type="date"
            className="input-theme"
            onChange={(e) => setDataFinal(e.target.value)}
            value={dataFinal}
            max="2999-12-31"
          ></input>
        </Col>
        <Col
          xs={12}
          sm={1}
          className="d-flex flex-column justify-content-center align-items-center d-sm-block"
        >
          <button className="btn-busca" type="submit" onClick={setarFiltros}>
            <i className="fas fa-search"></i>
          </button>
        </Col>
      </Row>
      <div>
        <RemoteTable
          dataId="idVendaItem"
          exportar={{
            fileName: `historico`,
          }}
          url={`/api/pontuacoes/historico`}
          filtros={filtros}
          colunas={columns}
          ordenacaoInicial={{ campo: 'idVenda', direcao: 'desc' }}
        />
      </div>
      <ModalMidia
        show={mostrarModalFoto}
        onHide={fecharModalFoto}
        diretorioArquivo={arquivo}
        tipo={tipo}
      />
    </Container>
  );
}
