import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import api from '../../../services/API';
import { toast } from 'react-toastify';
import historico from '../../../services/Historico';

export default function ModalCancelarPontuacao(props) {
  const [cancelando, setCancelando] = useState(false);
  const cancelarPontuacao = async () => {
    setCancelando(true);
    const resultado = await api.post('/api/pontuacoes/cancelar', {
      identificador: props.pontuacao,
    });

    if (resultado) {
      toast.success(`Pontuação cancelada com sucesso`, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      props.onHide(props.pontuacao);
      historico.push('/painel/pontuacoes/pontuado');
    }
    setCancelando(false);
  };

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      scrollable
    >
      <Modal.Header closeButton>
        <Modal.Title>Cancelar Pontuação</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-0">
        <div className="d-flex justify-content-center align-items-center text-center w-100 p-4">
          <span>Tem certeza que deseja cancelar essa pontuação?</span>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex justify-content-center align-items-center w-100">
          {cancelando ? (
            <button
              id="cancelarPontuacao"
              type="button"
              className="btn btn-success m-1"
              onClick={cancelarPontuacao}
              disabled
            >
              CANCELANDO...
            </button>
          ) : (
            <button
              id="cancelarPontuacao"
              type="button"
              className="btn btn-success m-1"
              onClick={cancelarPontuacao}
            >
              SIM
            </button>
          )}
          <button
            id="naoCancelarPontuacao"
            type="button"
            className="btn btn-danger m-1"
            onClick={props.onHide}
          >
            NÃO
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
