import React, { useEffect, useState } from 'react';
import { Modal, Col, Row } from 'react-bootstrap';
import api from '../../../services/API';
import { toast } from 'react-toastify';

export default function ModalEntrega({ open, onClose, resgate }) {
  const [alterando, setAlterando] = useState(false);
  const [date, setDate] = useState();

  useEffect(() => {
    setDate(resgate.dataEntrega);
  }, [resgate]);

  const alterarEntrega = async () => {
    setAlterando(true);
    const resultado = await api.put(`/api/data-resgate`, {
      identificador: resgate.id,
      date,
      isDataEntrega: true
    });

    if (resultado) {
      toast.success(`Data de entrega atualizada com sucesso`, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      onClose({ identificador: resgate.id, dataEntrega: date });
    }
    setAlterando(false);
  };

  return (
    <Modal
      show={open}
      onHide={onClose}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      scrollable
    >
      <Modal.Header closeButton>
        <Modal.Title>Data de entrega: </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-0">
        <form className="form mw-100 mt-4 d-flex justify-content-center align-items-center">
          <Row>
            <Col className="d-flex flex-column" xs={12}>
              <label htmlFor="data">Data de Entrega</label>
              <input
                id="data"
                name="data"
                type="date"
                placeholder="Data de Entrega"
                value={date}
                onChange={(e) => setDate(e.target.value)}
              ></input>
            </Col>
          </Row>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex justify-content-center align-items-center w-100">
          {alterando ? (
            <button type="button" className="btn btn-success m-1" disabled>
              ALTERANDO...
            </button>
          ) : (
            <button
              type="button"
              className="btn btn-success m-1"
              onClick={alterarEntrega}
            >
              ALTERAR
            </button>
          )}
          <button
            type="button"
            className="btn btn-danger m-1"
            onClick={onClose}
          >
            CANCELAR
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
