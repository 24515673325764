import React, { useEffect, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Container } from 'react-bootstrap';
import api from '../../../services/API';
import ModalAlterarStatusResgate from '../ModalAlterarStatusResgate';
import ModalCancelarResgate from '../ModalCancelarResgate';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { toast } from 'react-toastify';

const ToSearch = (props) => {
  let input;
  const handleClick = () => {
    props.onSearch(input.value);
  };

  const handleEnterKey = (e) => {
    if (e.key === 'Enter') {
      props.onSearch(input.value);
    }
  };

  return (
    <div className="d-flex justify-content-center align-items-center">
      <input
        className="input-theme busca mr-1"
        ref={(n) => (input = n)}
        type="text"
        onKeyUp={handleEnterKey}
      />
      <button
        className="btn-primario-slim"
        onClick={handleClick}
        style={{ width: 40, height: 40 }}
      >
        <i className="fa fa-search px-2"></i>
      </button>
    </div>
  );
};

export default function Resgates() {
  const [
    mostrarModalAlterarStatusResgate,
    setMostrarModalAlterarStatusResgate,
  ] = useState(false);
  const [mostrarModalCancelarResgate, setMostrarModalCancelarResgate] =
    useState(false);
  const [resgate, setResgate] = useState('');
  const [data, setData] = useState([]);
  const [buscando, setBuscando] = useState(false);
  const [enviando, setEnviando] = useState(false);


  const [titulo, setTitulo] = useState('');
  const [texto, setTexto] = useState('');

  const columns = [
    {
      dataField: 'id',
      text: 'ID',
      sort: true,
    },
    {
      dataField: 'titulo',
      text: 'Título',
      sort: true,
    },
    {
      dataField: 'texto',
      text: 'Texto',
      sort: true,
    }
  ];

  const defaultSorted = [
    {
      dataField: 'id',
      order: 'desc',
    },
  ];


  const cadastrarAdministrador = async (e) => {
    e.preventDefault();
    if (!titulo) {
      toast.error('Título obrigatório', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      return;
    }
    if (!texto) {
      toast.error('Texto obrigatório', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      return;
    }

    setEnviando(true);
    const resultado = await api.post('/api/notificacoes/para-todos', {
      titulo, texto
    });

    if (resultado) {
      toast.success('Push enviado com sucesso', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      buscarResgates();
      resetForm();
    }
    setEnviando(false);
  };

  const resetForm = () => {
    setTexto('');
    setTitulo('');
  };

  useEffect(() => {
    buscarResgates();
  }, []);

  const buscarResgates = async () => {
    setBuscando(true);
    const resultado = await api.get(`/api/notificacoes`);
    if (resultado) {
      setData(resultado.data.notificacoes);
    }
    setBuscando(false);
  };

  const visualizarModalAlterarStatusResgate = (id) => {
    setResgate(id);
    setMostrarModalAlterarStatusResgate(true);
  };

  const fecharModalAlterarStatusResgate = (toUpdate) => {
    setMostrarModalAlterarStatusResgate(false);
    toUpdate &&
      setData(
        data.map((d) => {
          if (d.id === toUpdate.identificador) {
            d.status = +toUpdate.status;
          }
          return d;
        })
      );
  };

  const visualizarModalCancelarResgate = (id) => {
    setResgate(id);
    setMostrarModalCancelarResgate(true);
  };

  const fecharModalCancelarResgate = () => {
    setMostrarModalCancelarResgate(false);
  };

  const options = {
    sizePerPage: 10,
    showTotal: true,
    sizePerPageList: [
      {
        text: '5',
        value: 5,
      },
      {
        text: '10',
        value: 10,
      },
      {
        text: '50',
        value: 50,
      },
      {
        text: '100',
        value: 100,
      },
      {
        text: 'Todos',
        value: data.length,
      },
    ],
    paginationTotalRenderer: (from, to, size) =>
      size > 0 ? (
        <span className="react-bootstrap-table-pagination-total px-2">
          {from}-{to} de {size} resultados
        </span>
      ) : (
        <span className="react-bootstrap-table-pagination-total px-2">
          Nenhum resultado
        </span>
      ),
  };

  return (
    <Container fluid>
      <h4 className="pt-3 pb-3 f-black">Notificações Push</h4>
      <hr className="hr-loja mt-0 mb-4" />

      <form onSubmit={cadastrarAdministrador}>
        <label htmlFor="texto">TÍTULO</label>
        <input id="titulo" className="form-control"
               type="text"
               placeholder="Título"
               onChange={(e) => setTitulo(e.target.value)}
               value={titulo}/>

        <div class="pt-3">
          <label htmlFor="texto">TEXTO</label>
          <textarea id="texto" className="form-control"
                    type="text"
                    placeholder="Texto"
                    onChange={(e) => setTexto(e.target.value)}
                    value={texto}></textarea>
        </div>

        <div className="pt-3">
          {enviando ? (
            <button disabled className="btn-primario btn-block" type="submit">
              ENVIANDO...
            </button>
          ) : (
            <button className="btn-primario btn-block" type="submit">
              ENVIAR PUSH
            </button>
          )}
        </div>
      </form>

      <div class="pb-3 pt-3">
        <hr/>
      </div>

      <div>
        <ToolkitProvider
          bootstrap4
          keyField="id"
          data={data}
          columns={columns}
          search
        >
          {(props) => (
            <div>
              <div className="d-flex flex-row-reverse mb-2">
                <ToSearch {...props.searchProps} />
              </div>
              <BootstrapTable
                defaultSorted={defaultSorted}
                noDataIndication={() =>
                  buscando ? (
                    <span>
                      Carregando
                      <i className="fas fa-circle-notch fa-spin ml-3"></i>
                    </span>
                  ) : (
                    <span>Sem resultados</span>
                  )
                }
                classes="table-dark text-center rounded"
                wrapperClasses="rounded"
                bordered={false}
                pagination={paginationFactory(options)}
                {...props.baseProps}
              />
            </div>
          )}
        </ToolkitProvider>
      </div>

    </Container>
  );
}
