import axios from 'axios';
import { toast } from 'react-toastify';

import { store } from '../store/index';
import { desautenticar } from '../store/modules/autenticacao/actions';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL || 'https://www.clubepropintor.com.br/',
});

function errorResponseHandler(error) {
  if (
    error.config.hasOwnProperty('errorHandle') &&
    error.config.errorHandle === false
  ) {
    return Promise.reject(error);
  }

  if (!error.response) {
    return;
  }

  if (error.response.status === 401) {
    store.dispatch(desautenticar());
  }

  if (error.response.data.erro) {
    toast.error(error.response.data.erro, {
      position: toast.POSITION.BOTTOM_CENTER,
      autoClose: 10000,
    });

    return error;
  }

  if (error.response.data.mensagem) {
    return error.response;
  }
}

// apply interceptor on response
api.interceptors.response.use((response) => response, errorResponseHandler);

api.interceptors.request.use(
  (config) => {
    config.headers.AppLocation = window.location.href;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default api;
