import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import api from '../../../services/API';
import { useParams } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';

export default function DetalheRevenda() {
  const { cnpj } = useParams();
  const [revenda, setRevenda] = useState([]);
  const [produtos, setProdutos] = useState([]);
  const [buscando, setBuscando] = useState(false);

  useEffect(() => {
    buscarRevenda();
    buscarProdutos();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const buscarProdutos = async () => {
    setBuscando(true);
    const resultado = await api.get(
      `/api/produtos_revenda?cnpjRevenda=${cnpj}`
    );
    if (resultado) {
      setProdutos(resultado.data.produtosRevenda);
    }
    setBuscando(false);
  };

  const buscarRevenda = async () => {
    setBuscando(true);
    const filtros = {
      cnpj,
    };
    const resultado = await api.get(
      `/api/revendas?filtros=${JSON.stringify(filtros)}`
    );
    if (resultado) {
      const revenda = resultado.data.revendas[0];

      revenda.tipoPontuacao = revenda.tipoPontuacao ? 'Foto' : 'Sistema';

      let cnpj = revenda.cnpj.replace(/[^\d]/g, '');
      revenda.cnpj = cnpj.replace(
        /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
        '$1.$2.$3/$4-$5'
      );

      revenda.endereco = revenda.endComplemento
        ? `${revenda.endRua}, ${revenda.endNumero}<br/>${revenda.endBairro} - ${revenda.endComplemento}<br/>${revenda.endCidade} - ${revenda.endEstado}`
        : `${revenda.endRua}, ${revenda.endNumero}<br/>${revenda.endBairro}<br/>${revenda.endCidade} - ${revenda.endEstado}`;

      revenda.telefone = revenda.telefone
        ? `(${revenda.telefone.substring(0, 2)}) ${revenda.telefone.substring(
            2,
            6
          )}-${revenda.telefone.substring(6)}`
        : '';

      revenda.celular = revenda.telefone
        ? `(${revenda.celular.substring(0, 2)}) ${revenda.celular.substring(
            2,
            7
          )}-${revenda.celular.substring(7)}`
        : '';

      revenda.contato = '';
      if (revenda.telefone && revenda.celular) {
        revenda.contato = `${revenda.celular} / ${revenda.telefone}`;
      } else if (revenda.celular) {
        revenda.contato = `${revenda.celular}`;
      } else {
        revenda.contato = '';
      }

      setRevenda(revenda);
      setBuscando(false);
    }
  };

  const columns = [
    {
      text: 'Código Revenda',
      dataField: 'codigo',
    },
    {
      text: 'Código Sherwin-Williams',
      dataField: 'codigoPadrao',
    },
    {
      text: 'Descrição do Produto',
      dataField: 'nome',
    },

    {
      text: 'Pontos Unitário',
      dataField: 'valorPontos',
      formatter: (celula, valor) => {
        return Number(valor.valorPontos).toLocaleString('pt-BR');
      },
      sort: true,
      sortFunc: (a, b, order, dataField, rowA, rowB) => {
        if (order === 'asc') {
          return a - b;
        }
        return b - a;
      },
    },
  ];

  const defaultSorted = [
    {
      dataField: 'valorPontos',
      order: 'desc',
    },
  ];

  return (
    <Container fluid>
      <h4 className="pt-3 pb-3 f-black">{revenda.nomeFantasia}</h4>
      <hr className="hr-loja mt-0 mb-4" />
      <Row>
        <Col md={6} lg={3} className="mb-2">
          <div className="card-detalhe p-3">
            <span>
              <b>Razão Social</b>
            </span>
            <span>{revenda.razaoSocial}</span>
            <br />
            <span>
              <b>Tipo de Pontuação</b>
            </span>
            <span>{revenda.tipoPontuacao}</span>
          </div>
        </Col>
        <Col md={6} lg={3} className="mb-2">
          <div className="card-detalhe p-3">
            <span>
              <b>CNPJ</b>
            </span>
            <span>{revenda.cnpj}</span>
            <br />
            <span>
              <b>Email</b>
            </span>
            <span>{revenda.email}</span>
          </div>
        </Col>
        <Col md={6} lg={3} className="mb-2">
          <div className="card-detalhe p-3">
            <span>
              <b>Responsável</b>
            </span>
            <span>{revenda.responsavel}</span>
            <br />
            <span>
              <b>Contato</b>
            </span>
            <span>{revenda.contato}</span>
          </div>
        </Col>
        <Col md={6} lg={3} className="mb-2">
          <div className="card-detalhe p-3">
            <span>
              <b>Endereço</b>
            </span>
            <span dangerouslySetInnerHTML={{ __html: revenda.endereco }}></span>
            <br />
            <span>
              <b>CEP</b>
            </span>
            <span>{revenda.endCep}</span>
          </div>
        </Col>
      </Row>
      <h4 className="pt-3 pb-3 f-black">Produtos</h4>
      <hr className="hr-loja mt-0 mb-4" />
      <div>
        <BootstrapTable
          bootstrap4
          noDataIndication={() =>
            buscando ? (
              <span>
                Carregando
                <i className="fas fa-circle-notch fa-spin ml-3"></i>
              </span>
            ) : (
              <span>Sem resultados</span>
            )
          }
          classes="table-dark text-center rounded"
          wrapperClasses="rounded"
          bordered={false}
          keyField="id"
          data={produtos}
          columns={columns}
          pagination={paginationFactory()}
          defaultSorted={defaultSorted}
        />
      </div>
    </Container>
  );
}
