import React from 'react';
import { Link } from 'react-router-dom';
import './styles.css';
import { useSelector, useDispatch } from 'react-redux';
import { atualizarPerfil } from '../../../store/modules/parceiro/actions';
import { Nav } from 'react-bootstrap';

export default function Navbar() {
  const dispatch = useDispatch();
  const parceiro = useSelector((state) => state.parceiro);

  const atualizarVerTutorial = () => {
    const data = { verTutorial: true, nav: true };
    dispatch(atualizarPerfil(data));
  };

  return (
    <nav className="nav d-flex justify-content-center align-items-center mt-2 mb-3">
      {/*<Link className="nav-link" to="/loja">*/}
      {/*  Home*/}
      {/*</Link>*/}
      <Link className="nav-link" to="/loja">
        Loja
      </Link>{' '}
      <Link className="nav-link" to="/perfil">
        Meu Perfil
      </Link>
      <Link className="nav-link" to="/pontuacoes">
        Pontuações
      </Link>
      <Link className="nav-link" to="/resgates">
        Resgates
      </Link>
      <Link className="nav-link" to="/ache-seu-pintor">
        Ache Seu Pintor
      </Link>
      <Link className="nav-link" to="/treinamentos">
        Treinamentos
      </Link>
      <Link className="nav-link" to="/cursos">
        Cursos
      </Link>
      {/* <Link className="nav-link" to="/cartao">
        Cartão
      </Link> */}
      <Link className="nav-link" to="/enviar-nf">
        Enviar Nota Fiscal
      </Link>
      <Link className="nav-link" to="/pontos-produtos">
        Pontos por Produto
      </Link>
      {/* <Link className="nav-link" to="/fotos-videos">
        Fotos e Vídeos
      </Link> */}
      {/*<Link className="nav-link" onClick={atualizarVerTutorial}>*/}
      {/*  Tutorial*/}
      {/*</Link>*/}
      {/*<Link className="nav-link" to="/regulamento">*/}
      {/*  Regulamento Smart*/}
      {/*</Link>*/}
      <Link className="nav-link" to="/contato">
        Contato
      </Link>
    </nav>
  );
}
