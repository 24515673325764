import React, { useState, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import moment from 'moment-timezone';

import api from '../../../../services/API';
import ModalAula from './ModalAula';
import ModalModulo from './ModalModulo';
import ModalMateriais from './ModalMateriais';

export default function EditCurso({ data, onClose }) {
  const [buscando, setBuscando] = useState(false);

  const [idModulo, setIdModulo] = useState(null);
  const [modulos, setModulos] = useState([]);
  const [curso, setCurso] = useState({});

  const [modulo, setModulo] = useState(false);
  const [aula, setAula] = useState(false);

  const [material, setMaterial] = useState(false);

  const { id } = data;

  useEffect(() => {
    getCurso();
  }, [id]);

  const getCurso = async () => {
    setBuscando(true);

    try {
      const { data: res } = await api.get(`/api/cursos/curso/` + id);
      setModulos(res.Modulos);
      const {
        nome,
        preco,
        descricao,
        cargaHoraria,
        categoria,
        avaliacao,
        certificado,
      } = res;
      setCurso({
        nome,
        preco,
        descricao,
        cargaHoraria,
        categoria,
        avaliacao,
        certificado,
      });
    } catch (error) {
      // console.log(error);
    }

    setBuscando(false);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setBuscando(true);

    try {
      const formData = new FormData(e.target);

      for (var pair of formData.entries()) {
        if (pair[0] === 'certificado') {
          formData.set('certificado', '1');
        }
        if (pair[0] === 'avaliacao') {
          formData.set('avaliacao', '1');
        }
      }
      // console.log(formData.entries());
      await api.put(`/api/cursos/curso/${id}`, formData);

      toast.success('Curso atualizado com sucesso.');
    } catch (error) {
      console.log(error);
    }

    setBuscando(false);
  };

  const onDelete = async (e) => {
    e.preventDefault();
    setBuscando(true);

    try {
      await api.delete(`/api/cursos/curso/${id}`, {
        ...curso,
      });
      toast.success('Curso deletado com sucesso.');
      onClose();
    } catch (error) {
      console.log(error);
    }

    setBuscando(false);
  };
  const onEditModulo = (data) => {
    setModulo({ open: true, data });
    getCurso();
  };

  const onModulo = () => {
    setModulo({ open: !modulo.open, data: null });
    getCurso();
  };

  const onModuloClose = () => {
    setModulo(false);
    getCurso();
  };

  const onAula = (id) => {
    setAula({ open: !aula.open });
    setIdModulo(id);
  };

  const onAulaEdit = (id, data) => {
    setAula({ open: !aula.open, data });
    setIdModulo(id);
  };

  const onAulaClose = () => {
    setAula(false);
    getCurso();
  };

  const onChange = (e) => {
    let v = e.target.value;
    const n = e.target.name;
    const c = e.target.checked;

    if (n === 'avaliacao' || n === 'certificado') {
      v = c;
    }

    setCurso({ ...curso, [n]: v });
  };

  const onMaterial = (id, idAula) => {
    setMaterial({ open: !material.open, idAula });
    setIdModulo(id);
  };

  const onMaterialEdit = (id, idAula, data) => {
    setMaterial({ open: !material.open, idAula, data });
    setIdModulo(id);
  };

  const onMaterialClose = () => {
    setMaterial(false);
    getCurso();
  };

  return (
    <div className="d-flex flex-column align-items-center">
      <form className="form mb-3" onSubmit={onSubmit}>
        <Row>
          <Col className="d-flex flex-column" xs={12}>
            <label htmlFor="nome">Nome</label>
            <input
              id="nome"
              name="nome"
              type="text"
              placeholder="Nome"
              value={curso.nome}
              onChange={onChange}
            />
          </Col>
          <Col className="d-flex flex-column" xs={12} sm={6}>
            <label htmlFor="categoria">Categoria</label>
            <input
              id="categoria"
              name="categoria"
              type="text"
              placeholder="Categoria"
              value={curso.categoria}
              onChange={onChange}
            />
          </Col>{' '}
          <Col className="d-flex flex-column" xs={12} sm={6}>
            <label htmlFor="cargaHoraria">Carga Horaria</label>
            <input
              id="cargaHoraria"
              name="cargaHoraria"
              value={curso.cargaHoraria}
              type="text"
              placeholder="Carga Horaria"
              onChange={onChange}
            />
          </Col>
          <Col className="d-flex flex-column" xs={12} sm={6}>
            <label htmlFor="linkVideo">Link Video</label>
            <input
              id="linkVideo"
              name="linkVideo"
              type="file"
              placeholder="Link Video"
            />
          </Col>
          <Col className="d-flex flex-column" xs={12} sm={6}>
            <label htmlFor="linkCapaCurso">Link Capa</label>
            <input
              type="file"
              id="linkCapaCurso"
              name="linkCapaCurso"
              placeholder="Link Capa"
            />
          </Col>
          <Col className="d-flex flex-column" xs={12}>
            <label htmlFor="descricao">Descrição</label>
            <input
              id="descricao"
              name="descricao"
              value={curso.descricao}
              type="text"
              placeholder="Descrição"
              onChange={onChange}
            />
          </Col>{' '}
          <Col className="d-flex flex-column" xs={12} sm={6}>
            <label htmlFor="cargaHoraria">Preço</label>
            <input
              id="preco"
              name="preco"
              type="number"
              step="any"
              placeholder="Preço"
              onChange={onChange}
              value={curso.preco}
            ></input>
          </Col>
          <Col className="d-flex flex-column" xs={12} sm={6}>
            <div class="custom-control custom-switch mt-4">
              <input
                type="checkbox"
                class="custom-control-input"
                id="certificado"
                name="certificado"
                checked={!!curso?.certificado}
                onChange={onChange}
              />
              <label class="custom-control-label" for="certificado">
                Certificados{' '}
              </label>
            </div>{' '}
            <div class="custom-control custom-switch">
              <input
                type="checkbox"
                class="custom-control-input"
                id="avaliacao"
                name="avaliacao"
                checked={!!curso?.avaliacao}
                onChange={onChange}
              />
              <label class="custom-control-label" for="avaliacao">
                Avaliações{' '}
              </label>
            </div>
          </Col>
        </Row>

        {buscando ? (
          <button disabled className="btn-primario mb-2">
            ATUALIZANDO...
          </button>
        ) : (
          <button className="btn-primario mb-2">ATUALIZAR CURSO</button>
        )}

        {buscando ? (
          <button className="btn btn-danger">PROCESSANDO...</button>
        ) : (
          <button className="btn btn-danger" onClick={onDelete}>
            <strong> DELETAR CURSO</strong>
          </button>
        )}
      </form>

      <hr className="hr-loja mt-0 mb-4" />
      {buscando ? (
        <button disabled className="btn-primario mb-4">
          ADCIONANDO...
        </button>
      ) : (
        <button
          className="btn-primario px-3 mb-4"
          style={{ width: '450px' }}
          onClick={onModulo}
        >
          ADCIONAR MODULO
        </button>
      )}

      {modulos.map((i) => (
        <div className="box-modulo">
          <Row>
            <Col
              className="d-flex justify-content-between align-items-center"
              xs={12}
            >
              <h4 style={{ color: '#000' }}>Módulo - {i.nome}</h4>

              <button
                className="btn-primario-slim"
                onClick={onEditModulo.bind(this, i)}
              >
                {/* <i className="fa fa-plus px-2"></i> */}
                <i className="fas fa-edit"></i>
              </button>
            </Col>
            <hr className="hr-loja mt-0 mb-4 mt-2" />
            {i.Aulas.map((aulaItem) => (
              <div className="w-100 boxAula">
                <div
                  id="aula"
                  role="button"
                  className="w-100 p-2"
                  onClick={onAulaEdit.bind(this, i.id, aulaItem)}
                >
                  <div className="d-flex justify-content-between w-100">
                    <div>
                      <strong> Aula - {aulaItem.nome}</strong>
                    </div>
                    <div>{moment(aulaItem.criadoEm).format('DD/MM/YYYY')} </div>
                  </div>
                  <p>{aulaItem.descricao}</p>
                </div>
                <hr className="hr-loja mt-0 mb-2 mt-2" />
                <p>
                  <strong> Materiais de apoio</strong>
                </p>
                <div className="d-flex w-100 align-items-center">
                  {aulaItem.Materiais.map((ma) => (
                    <div
                      className="d-flex boxMaterial"
                      onClick={onMaterialEdit.bind(this, i.id, aulaItem.id, ma)}
                    >
                      {ma.nome}
                    </div>
                  ))}

                  <button
                    className="btn-primario-slim"
                    onClick={onMaterial.bind(this, i.id, aulaItem.id)}
                  >
                    <i className="fa fa-plus px-1"></i>{' '}
                  </button>
                </div>
              </div>
            ))}
            <Col
              xs={12}
              className="d-flex justify-content-center align-items-center"
              onClick={onAula.bind(this, i.id)}
            >
              {buscando ? (
                <button className="btn-primario mb-2 w-100  d-flex justify-content-center align-items-center">
                  ATUALIZANDO...
                </button>
              ) : (
                <button className="btn-primario mb-2 w-100  d-flex justify-content-center align-items-center">
                  ADCIONAR AULA
                </button>
              )}
            </Col>{' '}
          </Row>
        </div>
      ))}

      {modulo?.open && (
        <ModalModulo
          show={modulo.open}
          onHide={onModulo}
          onModuloClose={onModuloClose}
          id={id}
          data={modulo.data}
        />
      )}

      {aula?.open && (
        <ModalAula
          show={aula.open}
          data={aula.data}
          onHide={onAula}
          onAulaClose={onAulaClose}
          id={id}
          idModulo={idModulo}
        />
      )}

      {material?.open && (
        <ModalMateriais
          show={material.open}
          data={material.data}
          idAula={material.idAula}
          onHide={onMaterial}
          onAulaClose={onMaterialClose}
          id={id}
          idModulo={idModulo}
        />
      )}
    </div>
  );
}
