/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import api from '../../../services/API';
import './styles.css';
import { toast } from 'react-toastify';

export default function SelectCidades(props) {
  const [cidades, setCidades] = useState([]);
  const [buscando, setBuscando] = useState(false);
  const [inputManual, setInputManual] = useState(false);
  const [value, setValue] = useState('');

  const buscarCidades = async () => {
    setBuscando(true);
    try {
      const resultado = await api.get(
        `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${props.endEstado}/municipios`,
        { timeout: 5000 }
      );

      setCidades(
        resultado.data.map((c) => ({
          value: c.nome,
          label: c.nome,
        }))
      );
    } catch (error) {
      setInputManual(true);
      toast.error(
        'Aconteceu algum problema ao listar as cidades, informe manualmente',
        {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 10000,
        }
      );
    }
    setBuscando(false);
  };

  useEffect(() => {
    if (props.endEstado) {
      buscarCidades();
    } else {
      setCidades([]);
      setValue('');
    }
  }, [props.endEstado]);

  useEffect(() => {
    if (props.valueChanged) {
      setValue({ value: props.valueChanged, label: props.valueChanged });
    }
  }, [props.valueChanged]);

  if (buscando) return <input disabled placeholder="Buscando..." />;

  if (inputManual)
    return (
      <input
        id="endCidade"
        name="endCidade"
        type="text"
        placeholder="Cidade"
        value={value}
        onChange={(value) => {
          setValue(value);
          props.onChange(value);
        }}
      ></input>
    );

  return (
    <Select
      noOptionsMessage={() => 'Sem resultados'}
      placeholder="Cidade"
      className="react-select-container mb-3"
      classNamePrefix="react-select"
      options={cidades}
      onChange={(value) => {
        setValue(value);
        props.onChange(value.value);
      }}
      value={value}
      isSearchable
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          text: 'black',
          primary25: '#4A76B9',
          primary: '#4A76B9',
        },
      })}
    />
  );
}
