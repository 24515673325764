import React, { useEffect, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { Container, Row, Col } from 'react-bootstrap';
import api from '../../../services/API';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import moment from 'moment-timezone';
import { toast } from 'react-toastify';

export default function Relatorios() {
  const columns = [
    {
      dataField: 'resgatadoEm',
      text: 'Data',
      formatter: (celula, valor) => {
        if (valor.resgatadoEm === 'TOTAL') return valor.resgatadoEm;
        return new Date(valor.resgatadoEm).toLocaleString('pt-BR', {
          day: '2-digit',
          month: 'long',
        });
      },
    },
    {
      dataField: 'pontosResgatados',
      text: 'Pontos Resgatados',
      formatter: (celular, valor) =>
        new Intl.NumberFormat('pt-BR').format(valor.pontosResgatados),
    },
    {
      dataField: 'pontosEntregues',
      text: 'Pontos Entregues',
      formatter: (celular, valor) =>
        new Intl.NumberFormat('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        }).format(valor.pontosEntregues),
    },
  ];

  const columnsVerbaMensal = [
    {
      dataField: 'verbaUtilizada',
      text: 'Verba Utilizada',
      formatter: (celular, valor) =>
        new Intl.NumberFormat('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        }).format(valor.verbaUtilizada),
    },
    {
      dataField: 'verbaDisponivel',
      text: 'Verba Disponível',
      formatter: (celular, valor) =>
        new Intl.NumberFormat('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        }).format(valor.verbaDisponivel),
    },
    {
      dataField: 'pontosResgatadosTotais',
      text: 'Pontos Resgatados',
      formatter: (celular, valor) =>
        new Intl.NumberFormat('pt-BR').format(valor.pontosResgatadosTotais),
    },
    {
      dataField: 'diasRestantes',
      text: 'Dias Restantes',
    },
    {
      dataField: 'verbaDiariaDisponivel',
      text: 'Verba Diária Disponível',
      formatter: (celular, valor) =>
        new Intl.NumberFormat('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        }).format(valor.verbaDiariaDisponivel),
    },
  ];

  const meses = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ];

  const processarRelatorio = () => {
    if (
      verbaMensalData &&
      verbaMensal &&
      verbaMensalData.verbaMensal !== verbaMensal
    ) {
      setBuscando(true);
      api
        .put(`/api/verba_mensal/${verbaMensalData.id}`, {
          mesSelecionado,
          anoSelecionado,
          verbaMensal,
        })
        .then((response) => {
          if (response?.data?.resgates) setData(response.data.resgates);
          else setData([]);
          if (response?.data?.verbaMensal) {
            setVerbaMensalData(response.data.verbaMensal);
            setVerbaMensal(response.data.verbaMensal.verbaMensal);
          } else setVerbaMensalData(null);
        })
        .catch((error) => {
          toast.error('Error interno', {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 10000,
          });
        })
        .finally(() => setBuscando(false));
    } else {
      setBuscando(true);
      api
        .get(
          `/api/verba_mensal?mes=${mesSelecionado}&ano=${anoSelecionado}&verbaMensal=${
            verbaMensalData === null && verbaMensal ? verbaMensal : ''
          }`
        )
        .then((response) => {
          if (response?.data?.resgates) setData(response.data.resgates);
          else setData([]);
          if (response?.data?.verbaMensal) {
            setVerbaMensalData(response.data.verbaMensal);
            setVerbaMensal(response.data.verbaMensal.verbaMensal);
          } else setVerbaMensalData(null);
        })
        .catch((error) => {
          toast.error('Error interno', {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 10000,
          });
        })
        .finally(() => setBuscando(false));
    }
  };

  useEffect(() => {
    processarRelatorio();
  }, []);

  const [data, setData] = useState([]);
  const [verbaMensalData, setVerbaMensalData] = useState(null);
  const [verbaMensal, setVerbaMensal] = useState('');
  const [anoSelecionado, setAnoSelecionado] = useState(moment().year());
  const [mesSelecionado, setMesSelecionado] = useState(moment().month());
  const [buscando, setBuscando] = useState(false);

  return (
    <Container fluid>
      <h4 className="pt-3 pb-3 f-black">Verba Mensal</h4>
      <hr className="hr-loja mt-0 mb-4" />
      <Row className="mb-3">
        <Col xs={12} sm={6} md={6} lg={3} className="d-flex flex-column">
          <label htmlFor="verbaMensal">Verba Mensal</label>
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">R$</span>
            </div>
            <input
              min={0}
              lang="pt-BR"
              type="number"
              class="form-control"
              value={Number(verbaMensal)}
              onChange={(e) => {
                e.persist();
                setVerbaMensal(e.target.value);
              }}
            />
          </div>
        </Col>
        <Col xs={12} sm={3} md={3} lg={2} className="d-flex flex-column">
          <label htmlFor="mesSelecionado">Mês</label>
          <select
            className="input-select mb-3"
            name="mesSelecionado"
            id="mesSelecionado"
            onChange={(e) => setMesSelecionado(e.target.value)}
            defaultValue={mesSelecionado}
          >
            {(() => {
              let mesesSelect = [];
              if (anoSelecionado === 2020) {
                for (let j = 7; j <= moment().month(); j++) {
                  mesesSelect.push(
                    <option value={j + 1}>{meses[j - 1]}</option>
                  );
                }
              } else {
                if (Number(anoSelecionado) === new Date().getFullYear())
                  for (let k = 0; k <= moment().month(); k++) {
                    mesesSelect.push(<option value={k}>{meses[k]}</option>);
                  }
                else
                  for (const mes of meses)
                    mesesSelect.push(
                      <option value={meses.indexOf(mes) + 1}>{mes}</option>
                    );
              }
              return mesesSelect;
            })()}
          </select>
        </Col>
        <Col xs={12} sm={3} md={3} lg={2} className="d-flex flex-column">
          <label htmlFor="anoSelecionado">Ano</label>
          <select
            className="input-select mb-3"
            name="anoSelecionado"
            id="anoSelecionado"
            onChange={(e) => setAnoSelecionado(e.target.value)}
            defaultValue={anoSelecionado}
          >
            {(() => {
              let anosSelect = [];
              for (let j = 2020; j <= moment().year(); j++) {
                anosSelect.push(<option value={j}>{j}</option>);
              }
              return anosSelect;
            })()}
          </select>
        </Col>
        <Col
          xs={12}
          sm={3}
          md={3}
          lg={2}
          className="d-flex flex-column justify-content-end mb-3"
        >
          <button
            className="btn-primario-slim"
            onClick={processarRelatorio}
            disabled={buscando}
          >
            {buscando ? 'Processando ...' : 'Calcular'}
          </button>
        </Col>
      </Row>
      <div>
        {!buscando && (
          <>
            {verbaMensalData && (
              <ToolkitProvider
                keyField="pdf"
                data={[verbaMensalData]}
                columns={columnsVerbaMensal}
                bootstrap4
              >
                {(props) => (
                  <BootstrapTable
                    noDataIndication={() =>
                      buscando ? (
                        <span>
                          Carregando
                          <i className="fas fa-circle-notch fa-spin ml-3"></i>
                        </span>
                      ) : (
                        <span>Sem resultados</span>
                      )
                    }
                    classes="table-dark text-center rounded"
                    wrapperClasses="rounded"
                    bordered={false}
                    {...props.baseProps}
                  />
                )}
              </ToolkitProvider>
            )}
            <ToolkitProvider
              keyField="pdf"
              data={data}
              columns={columns}
              bootstrap4
            >
              {(props) => (
                <BootstrapTable
                  noDataIndication={() =>
                    buscando ? (
                      <span>
                        Carregando
                        <i className="fas fa-circle-notch fa-spin ml-3"></i>
                      </span>
                    ) : (
                      <span>Sem resultados</span>
                    )
                  }
                  classes="table-dark text-center rounded"
                  wrapperClasses="rounded"
                  bordered={false}
                  {...props.baseProps}
                />
              )}
            </ToolkitProvider>
          </>
        )}
      </div>
    </Container>
  );
}
