import React, { useEffect, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Container, Col, Row } from 'react-bootstrap';
import api from '../../../services/API';

import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import sortFunc from '../../../utils/ordernarColunaInt';
import moment from 'moment-timezone';

const ToSearch = (props) => {
  let input;
  const handleClick = () => {
    props.onSearch(input.value);
  };

  const handleEnterKey = (e) => {
    if (e.key === 'Enter') {
      props.onSearch(input.value);
    }
  };

  return (
    <div className="d-flex justify-content-center align-items-center">
      <input
        className="input-theme busca mr-1"
        ref={(n) => (input = n)}
        type="text"
        onKeyUp={handleEnterKey}
      />
      <button
        className="btn-primario-slim"
        onClick={handleClick}
        style={{ width: 40, height: 40 }}
      >
        <i className="fa fa-search px-2"></i>
      </button>
    </div>
  );
};

export default function RelatorioResgates() {
  const [data, setData] = useState([]);
  const [buscando, setBuscando] = useState(false);
  const formatarData = (celula, valor) => {
    return moment(valor.resgatadoEm).format('DD/MM/YYYY HH:mm:ss');
  };

  const columns = [
    {
      dataField: 'pintor',
      text: 'Pintor',
      sort: true,
    },
    {
      dataField: 'endCidade',
      text: 'Cidade/UF',
      sort: true,
      formatter: (celula, valor) => {
        return `${valor.endCidade} / ${valor.endEstado}`;
      },
    },
    {
      dataField: 'produtoNome',
      text: 'Produto Resgatado',
      sort: true,
      sortFunc: sortFunc,
    },
    {
      dataField: 'qtde',
      text: 'Quantidade',
      sort: true,
    },
    {
      dataField: 'pontosGastos',
      text: 'Pontos Utilizados',
      formatter: (celula, valor) => {
        return Number(valor.pontosGastos).toLocaleString('pt-BR');
      },
      sort: true,
    },
    {
      dataField: 'saldoAnterior',
      text: 'Pontos Créditos',
      formatter: (celula, valor) => {
        return Number(valor.saldoAnterior).toLocaleString('pt-BR');
      },
      sort: true,
    },

    {
      dataField: 'valorPorPonto',
      text: 'R$ Ponto',

      sort: true,
      sortFunc: sortFunc,
    },
    {
      dataField: 'totalPagar',
      text: 'A pagar de Pontos',
      formatter: (celula, valor) => {
        return Number(valor.totalPagar).toFixed(2);
      },
      sort: true,
      sortFunc: sortFunc,
    },
    {
      dataField: 'resgatadoEm',
      text: 'Data do Resgate',
      formatter: formatarData,
      sort: true,
    },
  ];

  const defaultSorted = [
    {
      dataField: 'id',
      order: 'desc',
    },
  ];

  useEffect(() => {
    buscarResgates();
  }, []);

  const buscarResgates = async () => {
    setBuscando(true);
    const resultado = await api.get(
      `/api/relatorios/resgate/${mesSelecionado}/${anoSelecionado}`
    );
    if (resultado) {
      setData(resultado.data);
    }
    setBuscando(false);
  };

  const meses = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ];
  const [anoSelecionado, setAnoSelecionado] = useState(moment().year());
  const [mesSelecionado, setMesSelecionado] = useState(moment().month());

  const options = {
    sizePerPage: 10,
    showTotal: true,
    sizePerPageList: [
      {
        text: '5',
        value: 5,
      },
      {
        text: '10',
        value: 10,
      },
      {
        text: '50',
        value: 50,
      },
      {
        text: '100',
        value: 100,
      },
      {
        text: 'Todos',
        value: data.length,
      },
    ],
    paginationTotalRenderer: (from, to, size) =>
      size > 0 ? (
        <span className="react-bootstrap-table-pagination-total px-2">
          {from}-{to} de {size} resultados
        </span>
      ) : (
        <span className="react-bootstrap-table-pagination-total px-2">
          Nenhum resultado
        </span>
      ),
  };

  return (
    <Container fluid>
      <h4 className="pt-3 pb-3 f-black">Relatório Resgates</h4>
      <hr className="hr-loja mt-0 mb-4" />
      <div>
        <ToolkitProvider
          bootstrap4
          keyField="id"
          data={data}
          columns={columns}
          search
        >
          {(props) => (
            <div>
              <Row className="justify-content-between mb-2">
                <Col>
                  <Row xs={6}>
                    <Col
                      xs={12}
                      sm={3}
                      md={3}
                      lg={2}
                      className="d-flex flex-column"
                    >
                      <label htmlFor="anoSelecionado">Ano</label>
                      <select
                        className="input-select mb-3"
                        name="anoSelecionado"
                        id="anoSelecionado"
                        onChange={(e) => setAnoSelecionado(e.target.value)}
                        defaultValue={anoSelecionado}
                      >
                        {(() => {
                          let anosSelect = [];
                          for (let j = 2020; j <= moment().year(); j++) {
                            anosSelect.push(<option value={j}>{j}</option>);
                          }
                          return anosSelect;
                        })()}
                      </select>
                    </Col>
                    <Col
                      xs={12}
                      sm={3}
                      md={3}
                      lg={2}
                      className="d-flex flex-column"
                    >
                      <label htmlFor="mesSelecionado">Mês</label>
                      <select
                        className="input-select mb-3"
                        name="mesSelecionado"
                        id="mesSelecionado"
                        onChange={(e) => setMesSelecionado(e.target.value)}
                        defaultValue={mesSelecionado}
                      >
                        {(() => {
                          let mesesSelect = [];
                          if (anoSelecionado === 2020) {
                            for (var j = 7; j <= moment().month(); j++) {
                              mesesSelect.push(
                                <option value={j}>{meses[j - 1]}</option>
                              );
                            }
                          } else {
                            for (var k = 1; k <= moment().month(); k++) {
                              mesesSelect.push(
                                <option value={k}>{meses[k - 1]}</option>
                              );
                            }
                          }
                          return mesesSelect;
                        })()}
                      </select>
                    </Col>
                    <Col
                      xs={12}
                      sm={1}
                      onClick={buscarResgates}
                      className="d-flex flex-column justify-content-center align-items-center d-sm-block"
                    >
                      <button className="btn-busca" type="submit">
                        <i className="fas fa-search"></i>
                      </button>
                    </Col>
                  </Row>
                </Col>
                <ToSearch {...props.searchProps} />
              </Row>
              <BootstrapTable
                defaultSorted={defaultSorted}
                noDataIndication={() =>
                  buscando ? (
                    <span>
                      Carregando
                      <i className="fas fa-circle-notch fa-spin ml-3"></i>
                    </span>
                  ) : (
                    <span>Sem resultados</span>
                  )
                }
                classes="table-dark text-center rounded"
                wrapperClasses="rounded"
                bordered={false}
                pagination={paginationFactory(options)}
                {...props.baseProps}
              />
            </div>
          )}
        </ToolkitProvider>
      </div>
    </Container>
  );
}
