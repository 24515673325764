import React, { useCallback, useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { toast } from 'react-toastify';
import api from '../../../services/API';
import DragDropTable from '../DragDropTable';

export default function Banners() {
  const [showModalInativar, setShowModalInativar] = useState(false);
  const [ativo, setAtivo] = useState();
  const [enviando, setEnviando] = useState(false);
  const [id, setId] = useState(null);
  const [showModalDeletar, setShowModalDeletar] = useState(false);
  const [banners, setBanner] = useState();

  const columns = [
    {
      dataField: 'id',
      hidden: true,
    },
    {
      dataField: 'titulo',
      text: 'Título',
      sort: true,
    },
    {
      dataField: 'descricao',
      text: 'Descrição',
    },
    {
      dataField: 'dataInicial',
      text: 'Data Inicial',
      sort: true,
      formatter: (celula, valor) => {
        return new Date(valor.dataInicial).toLocaleDateString('pt-BR');
      },
    },
    {
      dataField: 'dataFinal',
      text: 'Data Final',
      sort: true,
      formatter: (celula, valor) => {
        return new Date(valor.dataFinal).toLocaleDateString('pt-BR');
      },
    },
    {
      dataField: 'ordem',
      text: 'Ordem',
      sort: true,
    },
    {
      dataField: 'ativo',
      text: 'Alterar Status',
      formatter: (celula, valor) => {
        return (
          <button
            className="bg-transparent border-0"
            type="button"
            onClick={() => {
              setId(valor.id);
              setAtivo(valor.ativo);
              setShowModalInativar(true);
            }}
          >
            <label className="switch">
              {valor.ativo === 1 ? (
                <input type="checkbox" checked disabled />
              ) : (
                <input type="checkbox" disabled />
              )}
              <span className="slider round"></span>
            </label>
          </button>
        );
      },
    },
    {
      text: 'Deletar',
      dataField: 'deletar',
      formatter: (celular, valor) => (
        <button
          className="btn-limpo f-12 p-1"
          onClick={() => {
            setId(valor.id);
            setShowModalDeletar(true);
          }}
        >
          <i className="fas fa-trash" style={{ color: 'red' }}></i>
        </button>
      ),
    },
  ];

  const loadBanners = useCallback(async () => {
    const resultado = await api.get(`/api/banners`);

    if (resultado && resultado.erro) {
      toast.error(resultado.erro, {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    } else if (resultado) {
      setBanner(resultado.data.banners);
    }
  }, []);

  useEffect(() => {
    loadBanners();
  }, []);

  const handleDragEnd = (result) => {
    const { destination, source } = result;

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const sourceRow = banners.at(source.index);
    const destinationRow = banners.at(destination.index);

    if (!sourceRow || !destinationRow) return;

    const temp = Array.from(banners);
    temp.splice(source.index, 1);
    temp.splice(destination.index, 0, sourceRow);
    console.log(temp);
    setBanner(temp.map((banner, index) => ({ ...banner, ordem: index + 1 })));
    mudarOrdem(temp);
    return;
  };

  const mudarOrdem = async (newBannersOrdem) => {
    const body = newBannersOrdem.map(({ id }, index) => ({
      id,
      ordem: index + 1,
    }));

    const resultado = await api.patch('/api/banners/bulk', body);

    if (resultado) {
      toast.success('Ordem dos banners alterada com sucesso!', {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    } else {
      toast.error('Falha ao re-ordenar os banners!', {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    }
  };

  const handleDelete = async (e) => {
    e.preventDefault();
    setEnviando(true);
    const resultado = await api.delete(`/api/banners/${id}`);

    if (resultado) {
      toast.success('Banner deletado com sucesso!', {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    } else {
      toast.error('Falha ao deletar o banner!', {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    }
    setEnviando(false);
    setShowModalDeletar(false);
    window.location.reload();
  };

  const handleAtivoSubmit = async (e) => {
    e.preventDefault();
    setEnviando(true);
    const resultado = await api.put(`/api/banners/${id}`, { ativo: !ativo });

    if (resultado) {
      toast.success('Status alterado com sucesso!', {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    } else {
      toast.error('Falha ao atualizar o Status!', {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    }
    setEnviando(false);
    setShowModalInativar(false);
    window.location.reload();
  };

  return (
    <Container fluid>
      <h4 className="pt-3 pb-3 f-black">Banners</h4>
      <hr className="hr-loja mt-0 mb-4" />
      <div>
        <DragDropTable
          onDragEnd={handleDragEnd}
          colunas={columns}
          rowsData={banners}
        />
      </div>
      {showModalInativar && (
        <div id="modal">
          <div className="conteudoModal">
            <form onSubmit={handleAtivoSubmit}>
              {ativo === 1 ? (
                <span className="textoInativar">
                  Tem certeza que deseja inativar o banner?
                  <br />
                </span>
              ) : (
                <span className="textoInativar">
                  Tem certeza que deseja ativar o banner?
                  <br />
                </span>
              )}
              <div className="botoes">
                {!enviando ? (
                  <button type="submit">
                    {ativo === 1 ? 'Inativar' : 'Ativar'}
                  </button>
                ) : (
                  <button type="button" disabled>
                    Enviando...
                  </button>
                )}
                <button
                  type="button"
                  onClick={() => setShowModalInativar(false)}
                >
                  Cancelar
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
      {showModalDeletar && (
        <div id="modal">
          <div className="conteudoModal">
            <form onSubmit={handleDelete}>
              <span className="textoInativar">
                Tem certeza que deseja deletar o banner?
                <br />
              </span>
              <div className="botoes">
                {!enviando ? (
                  <button type="submit">Deletar</button>
                ) : (
                  <button type="button" disabled>
                    Enviando...
                  </button>
                )}
                <button
                  type="button"
                  onClick={() => setShowModalDeletar(false)}
                >
                  Cancelar
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </Container>
  );
}
