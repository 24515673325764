import React, { useState, useEffect } from 'react';
import { Container, Card } from 'react-bootstrap';
import api from '../../../services/API';
import { Row, Col } from 'react-bootstrap';
import moment from 'moment-timezone';
import './styles.css';
import ModalMidia from '../../Painel/ModalMidia';

export default function Midias() {
  const [mostrarModalMidia, setMostrarModalMidia] = useState(false);
  const [arquivo, setArquivo] = useState('');
  const [tipo, setTipo] = useState('');
  const [data, setData] = useState([]);
  const [buscando, setBuscando] = useState(false);

  const buscarMidias = async () => {
    setBuscando(true);
    const resultado = await api.get(`/api/resgates/fotos_videos`);
    if (resultado) {
      setData(resultado.data.midias);
    }
    setBuscando(false);
  };

  useEffect(() => {
    buscarMidias();
  }, []);

  const fecharModalMidia = () => {
    setMostrarModalMidia(false);
  };

  return (
    <Container className="mt-5 mb-5">
      <h4 className="pt-3 pb-3 f-black">
        {buscando ? 'Buscando...' : 'Fotos e Vídeos'}
      </h4>
      <hr className="hr-loja mt-0 mb-4" />
      <Row>
        {data.length === 0 && (
          <Col xs={12}>
            <Card className="p-3">
              <span>Sem resultados</span>
            </Card>
          </Col>
        )}
        {data.length > 0 &&
          data.map((midia, idx) => (
            <Col
              xs={12}
              sm={12}
              md={6}
              lg={4}
              xl={3}
              className="d-flex flex-column text-center"
              key={idx}
            >
              <div className="card-midia">
                <div className="card-imagem">
                  <button
                    className="btn-limpo w-100 h-100"
                    onClick={(e) => {
                      setArquivo(midia.midia.url);
                      setTipo(midia.midia.tipo);
                      setMostrarModalMidia(true);
                    }}
                  >
                    {midia.midia.tipo.includes('image') ? (
                      <img
                        alt="Midia"
                        src={`${process.env.REACT_APP_API_BASE_URL}/arquivos/${midia.midia.url}`}
                      ></img>
                    ) : (
                      <video
                        src={`${process.env.REACT_APP_API_BASE_URL}/arquivos/${midia.midia.url}`}
                      ></video>
                    )}
                  </button>
                </div>
                <div className="card-infos">
                  <span className="card-midia-titulo">{`${midia.id} - ${midia.parceiro} - ${midia.premio}`}</span>
                  <span className="card-midia-subtitulo">
                    {moment(midia.resgatadoEm).format('DD/MM/YYYY')}
                  </span>
                </div>
                <div className="card-links">
                  <a
                    href={`https://api.whatsapp.com/send?text=Resgate%20do%20${midia.parceiro}%20${process.env.REACT_APP_API_BASE_URL}/arquivos/${midia.midia.url}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span className="fa-stack fa-lg">
                      <i className="fa fa-circle fa-stack-2x"></i>
                      <i className="fab fa-whatsapp fa-stack-1x fa-inverse"></i>
                    </span>
                  </a>
                  <a
                    href={`mailto:?body=Resgate%20do%20${midia.parceiro}%20${process.env.REACT_APP_API_BASE_URL}/arquivos/${midia.midia.url}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span className="fa-stack fa-lg">
                      <i className="fa fa-circle fa-stack-2x"></i>
                      <i className="fa fa-envelope fa-stack-1x fa-inverse"></i>
                    </span>
                  </a>
                  <a
                    href={`${process.env.REACT_APP_API_BASE_URL}/arquivos/${midia.midia.url}`}
                    download
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span className="fa-stack fa-lg">
                      <i className="fa fa-circle fa-stack-2x"></i>
                      <i className="fa fa-arrow-down fa-stack-1x fa-inverse"></i>
                    </span>
                  </a>
                </div>
              </div>
            </Col>
          ))}
      </Row>
      <ModalMidia
        show={mostrarModalMidia}
        onHide={fecharModalMidia}
        diretorioArquivo={arquivo}
        tipo={tipo}
      />
    </Container>
  );
}
