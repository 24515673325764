import React, { useEffect } from 'react';
import { toast } from 'react-toastify';

import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import api from '../../../../services/API';
import { Col, Row } from 'react-bootstrap';
import axios from 'axios';

export default function ModalPintor({ painter, open, onHide }) {
  useEffect(() => {
    if (open) {
      for (let key of Object.keys(painter)) {
        const element = document.getElementById(key);
        if (key === 'cpf')
          element.value = painter[key].replace(
            /(.{3})(.{3})(.{3})(.{2})/,
            '$1.$2.$3-$4'
          );
        else if (element) element.value = painter[key];
      }
    }
  }, [open]);

  const getFormData = () => {
    const data = {};
    for (let key of Object.keys(painter)) {
      const element = document.getElementById(key);
      if (element) Object.assign(data, { [key]: element.value });
    }
    return data;
  };

  const populateCepFields = (data) => {
    const fields = [
      'endRua',
      'endCep',
      'endCidade',
      'endBairro',
      'endEstado',
      'endComplemento',
      'endNumero',
    ];

    for (let field of fields) {
      const element = document.getElementById(field);
      if (element) element.value = data[field];
    }
  };

  const updatePainter = async (data) => {
    delete data.cpf;
    const result = await api.put(`/api/parceiros/${painter.id}`, data);

    onHide(result);
  };

  const buscaCep = async (cep) => {
    if (!cep) return;

    const resultado = await axios.get(`https://viacep.com.br/ws/${cep}/json`);

    if (!resultado || resultado.data.erro) {
      toast.error('CEP inválido', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
    } else {
      const { data } = resultado;
      populateCepFields({
        endRua: data.logradouro,
        endCidade: data.localidade,
        endBairro: data.bairro,
        endEstado: data.uf,
        endComplemento: '',
        endNumero: '',
      });
    }
  };

  return (
    <Modal
      show={open}
      onHide={() => {
        onHide();
      }}
      size={'xl'}
    >
      <Modal.Header closeButton>
        <Modal.Title>Editar Pintor</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Row className="flex flex-column flex-md-row">
            <Col>
              <Form.Group className="mb-3">
                <Form.Label htmlFor="nome">Nome</Form.Label>
                <Form.Control id="nome" />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label htmlFor="cpf">CPF</Form.Label>
                <Form.Control id="cpf" disabled pattern="" />
              </Form.Group>
            </Col>
          </Row>
          <Row className="flex flex-column flex-md-row">
            <Col>
              <Form.Group className="mb-3">
                <Form.Label htmlFor="celular">Celular</Form.Label>
                <Form.Control id="celular" />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label htmlFor="dataNascimento">
                  Data de Nascimento
                </Form.Label>
                <Form.Control id="dataNascimento" type="date" />
              </Form.Group>
            </Col>
          </Row>
          <Row className="flex flex-column flex-md-row">
            <Col>
              <Form.Group className="mb-3">
                <Form.Label htmlFor="email">E-mail</Form.Label>
                <Form.Control id="email" />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label htmlFor="sexo">Sexo</Form.Label>
                <Form.Control id="sexo" as="select">
                  <option>M</option>
                  <option>F</option>
                  <option>O</option>
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <Row className="flex flex-column flex-md-row">
            <Col>
              <Form.Group className="mb-3">
                <Form.Label htmlFor="telefone">Telefone</Form.Label>
                <Form.Control id="telefone" />
              </Form.Group>
            </Col>
            <Col></Col>
          </Row>
          <Row className="flex flex-column flex-md-row">
            <Col>
              <Form.Group className="mb-3">
                <Form.Label htmlFor="endCep">CEP</Form.Label>
                <Form.Control
                  id="endCep"
                  onBlur={(e) => {
                    if (e.target.value !== painter.endCep)
                      buscaCep(e.target.value);
                  }}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label htmlFor="endCidade">Cidade</Form.Label>
                <Form.Control id="endCidade" disabled />
              </Form.Group>
            </Col>
          </Row>
          <Row className="flex flex-column flex-md-row">
            <Col>
              <Form.Group className="mb-3">
                <Form.Label htmlFor="endEstado">Estado</Form.Label>
                <Form.Control id="endEstado" disabled />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label htmlFor="endBairro">Bairro</Form.Label>
                <Form.Control id="endBairro" disabled />
              </Form.Group>
            </Col>
          </Row>
          <Row className="flex flex-column flex-md-row">
            <Col>
              <Form.Group className="mb-3">
                <Form.Label htmlFor="endRua">Logradouro</Form.Label>
                <Form.Control id="endRua" disabled />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label htmlFor="endNumero">Número</Form.Label>
                <Form.Control id="endNumero" />
              </Form.Group>
            </Col>
          </Row>
          <Row className="flex flex-column flex-md-row">
            <Col>
              <Form.Group className="mb-3">
                <Form.Label htmlFor="endComplemento">Complemento</Form.Label>
                <Form.Control id="endComplemento" />
              </Form.Group>
            </Col>
            <Col></Col>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => {
            onHide();
          }}
        >
          Fechar
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            updatePainter(getFormData());
          }}
        >
          Salvar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
