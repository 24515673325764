import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import { toast } from 'react-toastify';
import api from '../../../services/API';
import moment from 'moment-timezone';

export default function CadastrarCoigoPromocional() {
  const [enviando, setEnviando] = useState(false);

  const [qtd, setQtd] = useState('');
  const [prefixo, setPrefixo] = useState('');
  const [operacao, setOperacao] = useState('');
  const [valor, setValor] = useState('');
  const [expiraEm, setExpiraEm] = useState('');
  const [apenasVenda, setApenasVenda] = useState(0);

  const cadastrarCodigo = async () => {
    if (!qtd) {
      toast.error('Informe a quantidade', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      return;
    }

    if (!operacao) {
      toast.error('Informe o tipo de operação', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      return;
    }

    if (!valor) {
      toast.error('Informe o valor em pontos', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      return;
    }

    if (expiraEm) {
      if (moment(expiraEm).isBefore(moment())) {
        toast.error('Data de expiração não', {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 10000,
        });
        return;
      }
    }

    setEnviando(true);
    const resultado = await api.post('/api/codigo-promocional', {
      qtd,
      prefixo,
      operacao,
      valor,
      expiraEm,
      apenasVenda,
    });

    if (resultado) {
      toast.success(
        Number(qtd) > 1
          ? 'Códigos promocionais gerados com sucesso'
          : 'Código promocional gerado com sucesso',
        {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 10000,
        }
      );
      setQtd('');
      setPrefixo('');
      setOperacao('');
      setValor('');
      setExpiraEm('');
      setApenasVenda(0);
    }
    setEnviando(false);
  };
  return (
    <Container fluid>
      <div>
        <h4 className="pt-3 pb-3 f-black">Cadastrar Código Promocional</h4>
        <hr className="hr-loja mt-0 mb-5" />
      </div>
      <div className="d-flex flex-column align-items-center">
        <form className="form">
          <label htmlFor="qtd">Quantidade *</label>
          <input
            id="qtd"
            name="qtd"
            type="number"
            placeholder="Quantidade"
            value={qtd}
            onChange={(e) => setQtd(e.target.value)}
          ></input>
          <label htmlFor="prefixo">Prefixo</label>
          <input
            id="prefixo"
            name="prefixo"
            type="text"
            placeholder="Prefixo"
            value={prefixo}
            onChange={(e) => setPrefixo(e.target.value)}
          ></input>
          <label htmlFor="valor">Valor *</label>
          <input
            id="valor"
            name="valor"
            type="number"
            placeholder="Valor"
            value={valor}
            onChange={(e) => setValor(e.target.value)}
          ></input>
          <label htmlFor="operacao">Operação *</label>
          <select
            id="operacao"
            name="operacao"
            type="text"
            placeholder="Operação"
            value={operacao}
            onChange={(e) => setOperacao(e.target.value)}
          >
            <option value="" disabled>
              Selecione
            </option>
            <option value="ADICIONAR">Adicionar</option>
            <option value="MULTIPLICAR">Multiplicar</option>
          </select>
          <label htmlFor="expiraEm">Validade</label>
          <input
            id="expiraEm"
            name="expiraEm"
            type="date"
            placeholder="Validade"
            max="2999-12-31"
            value={expiraEm}
            onChange={(e) => setExpiraEm(e.target.value)}
          ></input>
          <div className="d-flex flex-row justify-content-center align-items-center text-left">
            <label className="mb-0">Utilização limitada a vendas</label>
            <label className="m-0 p-3 f-16" htmlFor="apenasVendaSim">
              Sim
            </label>
            <input
              className="m-0 p-3"
              id="apenasVendaSim"
              name="apenasVenda"
              type="radio"
              value={1}
              checked={apenasVenda === 1}
              onChange={(e) => setApenasVenda(1)}
            ></input>
            <label className="m-0 p-3 f-16" htmlFor="apenasVendaNao">
              Não
            </label>
            <input
              className="m-0 p-3"
              id="apenasVendaNao"
              name="apenasVenda"
              type="radio"
              value={0}
              checked={apenasVenda === 0}
              onChange={(e) => setApenasVenda(0)}
            ></input>
          </div>
          {enviando ? (
            <button disabled className="btn-primario" type="submit">
              CADASTRANDO...
            </button>
          ) : (
            <button
              className="btn-primario"
              type="submit"
              onClick={cadastrarCodigo}
            >
              CADASTRAR
            </button>
          )}
        </form>
      </div>
    </Container>
  );
}
