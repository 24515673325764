import React, { useEffect } from 'react';
import './styles.css';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import Dashboard from '../../../components/Relatorio/Dashboard';

export default function Home() {
  let match = useRouteMatch();

  useEffect(() => {
    document.title = 'Clube Pro Pintor - Relatório';
  }, []);

  return (
    <>
      <main className="main-painel">
        <Switch>
          <Route
            exact
            path={`${match.path}/dashboard`}
            component={(props) => <Dashboard />}
          />
        </Switch>
      </main>
    </>
  );
}
