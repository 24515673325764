import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import { toast } from 'react-toastify';
import api from '../../../services/API';

export default function CadastrarBanner() {
  const [enviando, setEnviando] = useState(false);
  const [titulo, setTitulo] = useState('');
  const [descricao, setDescricao] = useState('');
  const [dataInicial, setDataInicial] = useState('');
  const [dataFinal, setDataFinal] = useState('');
  const [arquivo, setArquivo] = useState(null);
  const [status, setStatus] = useState('Procurar...');

  const cadastrarBanner = async (e) => {
    e.preventDefault();
    if (!titulo) {
      toast.error('Título obrigatório', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      return;
    }
    if (!dataInicial) {
      toast.error('Data inicial obrigatório', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      return;
    }
    if (!dataFinal) {
      toast.error('Data final obrigatório', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      return;
    }
    if (new Date(dataInicial).valueOf() > new Date(dataFinal).valueOf()) {
      toast.error('Data inicial não pode ser maior que data final  ', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      return;
    }
    let resultado;
    if (arquivo) {
      const body = new FormData();
      body.append('arquivo', arquivo[0]);
      body.append('titulo', titulo);
      body.append('dataInicial', dataInicial);
      body.append('dataFinal', dataFinal);
      descricao && body.append('descricao', descricao);

      setEnviando(true);
      resultado = await api.post('/api/upload/banner', body, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    } else {
      setEnviando(true);
      resultado = await api.post('/api/banners', {
        titulo,
        descricao,
        dataInicial,
        dataFinal,
      });
    }
    setEnviando(false);

    if (resultado?.status === 200) {
      toast.success('Banner cadastrado com sucesso', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
    }
    resetForm();
  };

  const resetForm = () => {
    setTitulo('');
    setDescricao('');
    setDataInicial('');
    setDataFinal('');
    setArquivo(null);
    setStatus('Procurar...');
  };

  return (
    <Container fluid>
      <div>
        <h4 className="pt-3 pb-3 f-black">Cadastrar Banner</h4>
        <hr className="hr-loja mt-0 mb-5" />
      </div>
      <div className="d-flex flex-column align-items-center">
        <form className="form" onSubmit={cadastrarBanner}>
          <label htmlFor="titulo">Título</label>
          <input
            id="titulo"
            name="titulo"
            type="text"
            placeholder="Título"
            onChange={(e) => setTitulo(e.target.value)}
            value={titulo}
          ></input>
          <label htmlFor="descricao">Descrição</label>
          <textarea
            id="descricao"
            type="text"
            placeholder="Descrição"
            onChange={(e) => setDescricao(e.target.value)}
            value={descricao}
            maxlength={255}
          ></textarea>
          <label htmlFor="dataInicial">Data Inicial</label>
          <input
            id="dataInicial"
            name="dataInicial"
            type="date"
            placeholder="Data Inicial"
            max="2999-12-31"
            value={dataInicial}
            onChange={(e) => setDataInicial(e.target.value)}
          ></input>
          <label htmlFor="dataFinal">Data Final</label>
          <input
            id="dataFinal"
            name="dataFinal"
            type="date"
            placeholder="Data Final"
            max="2999-12-31"
            value={dataFinal}
            onChange={(e) => setDataFinal(e.target.value)}
          ></input>
          <label htmlFor="arquivo">Selecione a imagem</label>
          <input
            type="file"
            id="arquivo"
            name="arquivo"
            accept="image/*"
            style={{ display: 'none' }}
            onChange={(e) => {
              setArquivo(e.target.files);
              e.target.files.length > 0
                ? setStatus(`${e.target.files.length} imagem(s) selecionado(s)`)
                : setStatus('Procurar...');
            }}
          />
          <input
            id="botao"
            type="button"
            value={status}
            onClick={(e) => {
              document.getElementById('arquivo').click();
            }}
          />
          {enviando ? (
            <button disabled className="btn-primario" type="submit">
              CADASTRANDO...
            </button>
          ) : (
            <button className="btn-primario" type="submit">
              CADASTRAR
            </button>
          )}
        </form>
      </div>
    </Container>
  );
}
