import moment from 'moment-timezone';
import React, { useState } from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { toInteger } from 'lodash';

import api from '../../../services/API';

export default function CadastrarVoucher() {
  const [enviando, setEnviando] = useState(false);
  const [voucher, setVoucher] = useState('');
  const [operacao, setOperacao] = useState('');
  const [valor, setValor] = useState('');
  const [isData, setIsData] = useState(0);

  const [iniciaEm, setIniciaEm] = useState('');
  const [expiraEm, setExpiraEm] = useState('');

  const cadastrarCodigo = async () => {
    if (!voucher) {
      toast.error('Informe o voucher', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      return;
    }

    if (!operacao) {
      toast.error('Informe o tipo de operação', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      return;
    }

    if (!valor) {
      toast.error('Informe o valor em pontos', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      return;
    }

    setEnviando(true);

    const resultado = await api.post('/api/vouchers', {
      voucher,
      operacao,
      valor,
      iniciaEm: isData ? iniciaEm : null,
      expiraEm: isData ? expiraEm : null,
    });

    if (resultado) {
      toast.success('Voucher gerados com sucesso', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      setVoucher('');
      setOperacao('');
      setValor('');
    }
    setEnviando(false);
  };
  return (
    <Container fluid>
      <div>
        <h4 className="pt-3 pb-3 f-black">Cadastrar Voucher</h4>
        <hr className="hr-loja mt-0 mb-5" />
      </div>
      <div className="d-flex flex-column align-items-center">
        <form className="form">
          <label htmlFor="voucher">Voucher</label>
          <input
            id="voucher"
            name="voucher"
            type="text"
            placeholder="Voucher"
            value={voucher}
            onChange={(e) => setVoucher(e.target.value)}
          ></input>
          <label htmlFor="pontos">Valor *</label>
          <input
            id="valor"
            name="valor"
            type="number"
            placeholder="Valor"
            value={valor}
            onChange={(e) => setValor(e.target.value)}
          ></input>
          <label htmlFor="isData"> Validar por data? *</label>
          <select
            id="isData"
            name="isData"
            type="text"
            value={isData}
            onChange={(e) => setIsData(toInteger(e.target.value))}
          >
            <option value={0}>Nao</option>
            <option value={1}>Sim</option>
          </select>
          {isData ? (
            <Row>
              <Col xs={12} sm={6} className="d-flex flex-column">
                <label>Inicia em</label>
                <input
                  type="date"
                  className="input-theme"
                  value={iniciaEm}
                  defaultValue={iniciaEm}
                  onChange={(e) => setIniciaEm(e.target.value)}
                  max="2999-12-31"
                ></input>
              </Col>{' '}
              <Col xs={12} sm={6} className="d-flex flex-column">
                <label>Valido até</label>
                <input
                  type="date"
                  className="input-theme"
                  value={expiraEm}
                  defaultValue={expiraEm}
                  onChange={(e) => setExpiraEm(e.target.value)}
                  max="2999-12-31"
                ></input>
              </Col>
            </Row>
          ) : null}

          <label htmlFor="operacao">Operação *</label>
          <select
            id="operacao"
            name="operacao"
            type="text"
            placeholder="Operação"
            value={operacao}
            onChange={(e) => setOperacao(e.target.value)}
          >
            <option value="" disabled>
              Selecione
            </option>
            <option value="ADICIONAR">Adicionar</option>
            <option value="MULTIPLICAR">Multiplicar</option>
          </select>
          {enviando ? (
            <button disabled className="btn-primario" type="submit">
              CADASTRANDO...
            </button>
          ) : (
            <button
              className="btn-primario"
              type="submit"
              onClick={cadastrarCodigo}
            >
              CADASTRAR
            </button>
          )}
        </form>
      </div>
    </Container>
  );
}
