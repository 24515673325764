/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import ModalItensPontuacao from '../ModalItensPontuacao';
import ModalMidia from '../ModalMidia';
import ModalCancelarPontuacao from '../ModalCancelarPontuacao';
import { Row, Col } from 'react-bootstrap';
import moment from 'moment-timezone';
import sortFunc from '../../../utils/ordernarColunaInt';
import RemoteTable from '../RemoteTable';
import capitalize from '../../../utils/capitalize';

export default function Pontuacoes() {
  const [dataInicial, setDataInicial] = useState(
    moment().subtract(7, 'days').toISOString().substr(0, 10)
  );
  const [dataFinal, setDataFinal] = useState(
    moment().toISOString().substr(0, 10)
  );
  const [tipo, setTipo] = useState('');
  const [mostrarModalItens, setMostrarModalItens] = useState(false);
  const [produtos, setProdutos] = useState([]);
  const [mostrarModalFoto, setMostrarModalFoto] = useState(false);
  const [arquivo, setArquivo] = useState([]);
  const [mostrarModalCancelarPontuacao, setMostrarModalCancelarPontuacao] =
    useState(false);
  const [pontuacao, setPontuacao] = useState('');
  const [filtros, setFiltros] = useState({
    dataFinal: moment().toISOString().substr(0, 10),
    dataInicial: moment().subtract(7, 'days').toISOString().substr(0, 10),
  });

  const setarFiltros = (e) => {
    e.preventDefault();
    setFiltros({
      dataInicial,
      dataFinal,
    });
  };

  const columns = [
    {
      text: 'ID',
      dataField: 'id',
      sort: true,
    },
    {
      text: 'Pintor',
      dataField: 'nome',
      sort: true,
      formatter: (celula, valor) => {
        return capitalize(valor.nome);
      },
      style: { textAlign: 'left' },
      headerStyle: { textAlign: 'left' },
    },
    {
      text: 'Cidade',
      dataField: 'endCidade',
      sort: true,
    },
    {
      text: 'Pontos',
      dataField: 'totalPontos',
      formatter: (celula, valor) => {
        return Number(valor.totalPontos).toLocaleString('pt-BR');
      },
      sort: true,
      sortFunc: sortFunc,
    },
    {
      text: 'Data da Venda',
      dataField: 'realizadoEm',
      formatter: (celula, valor) => {
        return moment(valor.realizadoEm).format('DD/MM/YYYY');
      },
      sort: true,
    },
    {
      text: 'Data da Pontuação',
      dataField: 'pontuadoEm',
      formatter: (celula, valor) => {
        return moment(valor.pontuadoEm).format('DD/MM/YYYY HH:mm:ss');
      },
      sort: true,
    },
    {
      text: 'Validade dos Pontos',
      dataField: 'venceEm',
      formatter: (celula, valor) => {
        return moment(valor.venceEm).format('DD/MM/YYYY HH:mm:ss');
      },
      sort: true,
    },
    {
      text: 'Produtos',
      dataField: 'itens',
      formatter: (celula, valor) => {
        return (
          <button
            className="btn-limpo f-12 p-1"
            onClick={() => visualizarModalItens(valor.itens)}
          >
            <i className="fas fa-list"></i>
          </button>
        );
      },
    },
    {
      text: 'Foto',
      dataField: 'dirFoto',
      formatter: (celula, valor) =>
        valor.diretorioArquivo ? (
          <button
            className="btn-limpo f-12 p-1"
            onClick={() => {
              visualizarModalFoto(valor.diretorioArquivo, valor.tipo);
            }}
          >
            <i className="fas fa-image"></i>
          </button>
        ) : (
          <button className="btn-limpo f-12 p-1" disabled>
            SEM FOTO
          </button>
        ),
    },
    {
      text: 'Código',
      dataField: 'codigo',
      formatter: (celula, valor) => {
        if (Number(valor.pontosDobrados) === 1) return 'REVENDAMASTER';
        return valor.codigo;
      },
      sort: true,
    },
    {
      text: 'Cancelar',
      dataField: 'id',
      formatter: (celula, valor) => {
        return (
          <button
            className="btn-limpo f-12 p-1"
            onClick={() => {
              visualizarModalCancelarPontuacao(valor.id);
            }}
          >
            <i className="fas fa-times f-red"></i>
          </button>
        );
      },
    },
  ];

  const visualizarModalItens = (produtos) => {
    setProdutos(produtos);
    setMostrarModalItens(true);
  };

  const fecharModalItens = () => {
    setMostrarModalItens(false);
  };

  const visualizarModalFoto = (dirFoto, tipo) => {
    if (dirFoto.endsWith('.pdf')) {
      window.open(`${process.env.REACT_APP_API_BASE_URL}/arquivos/${dirFoto}`);
    } else {
      setArquivo(dirFoto);
      setTipo(tipo);
      setMostrarModalFoto(true);
    }
  };

  const fecharModalFoto = () => {
    setMostrarModalFoto(false);
  };

  const visualizarModalCancelarPontuacao = (id) => {
    setPontuacao(id);
    setMostrarModalCancelarPontuacao(true);
  };

  const fecharModalCancelarPontuacao = () => {
    setMostrarModalCancelarPontuacao(false);
  };

  return (
    <Container fluid>
      <h4 className="pt-3 pb-3 f-black">Pontuações</h4>
      <hr className="hr-loja mt-0 mb-4" />
      <Row className="mb-3">
        <Col xs={12} sm={3} md={3} lg={2} className="d-flex flex-column">
          <label>De</label>
          <input
            type="date"
            className="input-theme"
            value={dataInicial}
            onChange={(e) => setDataInicial(e.target.value)}
            max="2999-12-31"
          ></input>
        </Col>
        <Col xs={12} sm={3} md={3} lg={2} className="d-flex flex-column">
          <label>Até</label>
          <input
            type="date"
            className="input-theme"
            onChange={(e) => setDataFinal(e.target.value)}
            value={dataFinal}
            max="2999-12-31"
          ></input>
        </Col>
        <Col
          xs={12}
          sm={1}
          className="d-flex flex-column justify-content-center align-items-center d-sm-block"
        >
          <button className="btn-busca" type="submit" onClick={setarFiltros}>
            <i className="fas fa-search"></i>
          </button>
        </Col>
      </Row>
      <div>
        <RemoteTable
          dataId="id"
          url={`/api/pontuacoes`}
          exportar={{
            fileName: 'pontuacoes',
          }}
          filtros={filtros}
          colunas={columns}
          ordenacaoInicial={{ campo: 'pontuadoEm', direcao: 'desc' }}
        />
      </div>
      <ModalItensPontuacao
        show={mostrarModalItens}
        onHide={fecharModalItens}
        produtos={produtos}
      />
      <ModalMidia
        show={mostrarModalFoto}
        onHide={fecharModalFoto}
        diretorioArquivo={arquivo}
        tipo={tipo}
      />
      <ModalCancelarPontuacao
        show={mostrarModalCancelarPontuacao}
        onHide={fecharModalCancelarPontuacao}
        pontuacao={pontuacao}
      />
    </Container>
  );
}
