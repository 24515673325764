export function desautenticar() {
  return {
    type: '@autenticacao/DESAUTENTICAR',
  };
}

export function autenticarUsuario(cpf, senha) {
  return {
    type: '@autenticacaoUsuario/AUTENTICAR',
    payload: { cpf, senha },
  };
}

export function desautenticarUsuario() {
  return {
    type: '@autenticacaoUsuario/DESAUTENTICAR',
  };
}

export function autenticacaoSucessoUsuario(tokenPainel, usuario) {
  return {
    type: '@autenticacaoUsuario/AUTENTICAR_SUCESSO',
    payload: { tokenPainel, usuario },
  };
}

export function autenticacaoFalhouUsuario() {
  return {
    type: '@autenticacaoUsuario/AUTENTICAR_FALHOU',
  };
}

export function autenticarParceiro(cpf, senha) {
  return {
    type: '@autenticacaoParceiro/AUTENTICAR',
    payload: { cpf, senha },
  };
}

export function autenticarParceiroSocial(index, social_token) {
  return {
    type: '@autenticacaoParceiro/AUTENTICAR_REDESOCIAL',
    payload: { index, social_token },
  };
}

export function desautenticarParceiro() {
  return {
    type: '@autenticacaoParceiro/DESAUTENTICAR',
  };
}

export function autenticacaoSucessoParceiro(tokenLoja, parceiro) {
  return {
    type: '@autenticacaoParceiro/AUTENTICAR_SUCESSO',
    payload: { tokenLoja, parceiro },
  };
}

export function autenticacaoFalhouParceiro() {
  return {
    type: '@autenticacaoParceiro/AUTENTICAR_FALHOU',
  };
}

export function atualizarAntifraude(redesAntifraude) {
  return {
    type: '@autenticacaoUsuario/ATUALIZAR_ANTIFRAUDE',
    payload: { redesAntifraude },
  };
}

export function autenticarUsuarioRelatorio(cnpj, senha) {
  return {
    type: '@autenticacaoUsuarioRelatorio/AUTENTICAR',
    payload: { cnpj, senha },
  };
}

export function desautenticarUsuarioRelatorio() {
  return {
    type: '@autenticacaoUsuarioRelatorio/DESAUTENTICAR',
  };
}

export function autenticacaoSucessoUsuarioRelatorio(
  tokenPainelRelatorio,
  usuario
) {
  return {
    type: '@autenticacaoUsuarioRelatorio/AUTENTICAR_SUCESSO',
    payload: { tokenPainelRelatorio, usuario },
  };
}

export function autenticacaoFalhouUsuarioRelatorio() {
  return {
    type: '@autenticacaoUsuarioRelatorio/AUTENTICAR_FALHOU',
  };
}
