import React, { useEffect, useState } from 'react';
import { Modal, Row, Col, Container } from 'react-bootstrap';
import api from '../../../services/API';

const ModalBanner = ({ handleClose, ...props }) => {
  const [showModal, setShowModal] = useState(false);
  const [banners, setBanners] = useState([]);

  useEffect(() => {
    const showedModal = sessionStorage.getItem('showedModal');

    if (!showedModal) {
      carregarBanners();
    } else {
      handleClose();
    }
  }, []);

  const carregarBanners = async () => {
    const { data } = await api.get('/api/banners/intervalo');

    if (data && data.length > 0) {
      setBanners(data);
      setShowModal(true);
    } else {
      handleClose();
    }
  };

  const handleHideBanner = () => {
    if (banners.length > 0) {
      const temp = Array.from(banners);
      temp.shift();
      setBanners(temp);
      if (temp.length === 0) {
        sessionStorage.setItem('showedModal', true);
        setShowModal(false);
        handleClose();
      }
    } else {
      setShowModal(false);
      handleClose();
    }
  };

  return (
    <Modal
      {...props}
      show={showModal}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      id="banner-modal"
      onHide={handleHideBanner}
    >
      <Modal.Header closeButton>
        <Modal.Title style={{ width: '100%', textAlign: 'center' }}>
          {banners && banners.length > 0 && banners.at(0).titulo}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal-body">
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          {banners && banners.length > 0 && banners.at(0).diretorioImagem && (
            <div
              style={{
                maxHeight: '80vh',
                maxWidth: '80vw',
              }}
            >
              <img
                style={{
                  height: '100%',
                  width: '100%',
                  overflow: 'hidden',
                  objectFit: 'contain',
                }}
                src={`${process.env.REACT_APP_API_BASE_URL}/arquivos/${
                  banners.at(0).diretorioImagem
                }`}
              />
            </div>
          )}
        </div>
        <div
          style={{ textAlign: 'center' }}
          dangerouslySetInnerHTML={{
            __html: banners && banners.length > 0 && banners.at(0).descricao,
          }}
        ></div>
      </Modal.Body>
    </Modal>
  );
};

export default ModalBanner;
