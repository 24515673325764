import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import api from '../../../services/API';
import InputMask from 'react-input-mask';
import { toast } from 'react-toastify';
import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import historico from '../../../services/Historico';
import Viewer from 'react-viewer';
import SelectCidades from '../SelectCidades/index';
import validarCnpj from '../../../utils/validarCnpj';
import axios from 'axios';
import { isEmpty } from 'lodash';
import moment from 'moment-timezone';
import ModalReprovarNota from '../ModalReprovarNota';
import { useSelector } from 'react-redux';
import SelectProduct from '../SelectProduct';

function NovaVenda(props) {
  const [enviando, setEnviando] = useState(false);
  const [itens, setItens] = useState([]);
  const [notaItens, setNotaItens] = useState([]);

  const [cpf, setCpf] = useState('');
  const [parceiro, setParceiro] = useState('');
  const [tipoFiscal, setTipoFiscal] = useState('');
  const [identificador, setIdentificador] = useState('');
  const [realizadoEm, setRealizadoEm] = useState('');
  const [codigo, setCodigo] = useState('');
  const [nome, setNome] = useState('');
  const [valorUnitario, setValorUnitario] = useState('');
  const [qtde, setQtde] = useState('');
  const [valorDescontoItem, setValorDescontoItem] = useState('');
  const [subTotalItem, setSubTotalItem] = useState('');
  const [descontos, setDescontos] = useState('');
  const [total, setTotal] = useState('');
  const [url, setUrl] = useState('');
  const [nomeArquivo, setNomeArquivo] = useState('');
  const [colFormat, setColFormat] = useState(12);
  const [enviarMensagem, setEnviarMensagem] = useState(true);
  const [codigoPromocional, setCodigoPromocional] = useState('');
  const [codigoPromocionalInfo, setCodigoPromocionalInfo] = useState({});

  const [nomeLoja, setNomeLoja] = useState('');
  const [cnpjLoja, setCnpjLoja] = useState('');
  const [cidadeLoja, setCidadeLoja] = useState('');
  const [estadoLoja, setEstadoLoja] = useState('');
  const [lojaCadastrada, setLojaCadastrada] = useState(false);
  const [pdf, setPdf] = useState(false);
  const [idFoto, setIdFoto] = useState('');

  const [openModalReprovar, setOpenModalReprovar] = useState(false);
  const [motivo, setMotivo] = useState('');

  const buscarImagem = async (dirAquivo) => {
    const diretorioArquivo = dirAquivo.split('/');
    const index = diretorioArquivo.findIndex((valor) => valor === 'storage');
    let nome = '';
    for (let i = index + 1; i < diretorioArquivo.length; i += 1) {
      i + 1 === diretorioArquivo.length
        ? (nome += `${diretorioArquivo[i]}`)
        : (nome += `${diretorioArquivo[i]}/`);
    }
    setNomeArquivo(nome);
    setUrl(`${process.env.REACT_APP_API_BASE_URL}/arquivos/${nome}`);
    setColFormat(6);

    if (nome.includes('.pdf')) {
      setPdf(true);
      setColFormat(12);
    }
  };

  useEffect(() => {
    if (tipoFiscal && identificador && cnpjLoja) {
      verificarIdentificador();
    }
  }, [tipoFiscal, cnpjLoja]);

  const getItens = async () => {
    try {
      const { data } = await api.get(`/api/nota/${idFoto}`);
      setNotaItens(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (props.location.state) {
      setCpf(props.location.state.cpf);
      validarCpf(props.location.state.cpf);
      buscarImagem(props.location.state.diretorioArquivo);
      buscarCodigoPromocional(props.location.state.codigoPromocional);
      setCodigoPromocional(props.location.state.codigoPromocional);
      setCnpjLoja(props.location.state.cpfCnpj);
      setCidadeLoja(props.location.state.cidade);
      setEstadoLoja(props.location.state.uf);
      setNomeLoja(props.location.state.loja);
      setRealizadoEm(moment(props.location.state.dataEmissao).format('D/MM/Y'));
      setIdentificador(props.location.state.chave);
      setTipoFiscal(props.location.state.tipoFiscal);
      setIdFoto(props.location.state.idFoto);
    }

    if (idFoto) getItens();
  }, [props, idFoto]);

  const buscarCodigoPromocional = async (codigoParaBuscar) => {
    if (codigoParaBuscar) {
      const resultado = await api.get(
        `/api/codigo-promocional/validar?codigo=${codigoParaBuscar}`
      );

      if (resultado) {
        setCodigoPromocionalInfo(resultado.data.codigoPromocional);
      } else {
        setCodigoPromocional('');
        setCodigoPromocionalInfo({});
        toast.error(`Código inválido`, {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 10000,
        });
        return;
      }
    }
  };

  const validarCpf = async (cpf) => {
    cpf = cpf.replace(/[^\d]/g, '');
    if (!cpf) return;
    const filtros = {
      cpf,
    };
    const resultado = await api.get(
      `/api/parceiros?filtros=${JSON.stringify(filtros)}`
    );
    if (resultado.data.parceiros.length === 0) {
      toast.error(`CPF inválido`, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      setCpf('');
    } else {
      setParceiro(resultado.data.parceiros[0].nome);
    }
  };

  const validarDataVenda = async (e) => {
    const data = e.target.value.replace(/[^\d]/g, '');
    if (!data) return;
    if (data.length < 8) {
      toast.error(`${e.target.placeholder} inválido`, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      setRealizadoEm('');
    }
  };

  const verificarIdentificador = async (e) => {
    try {
      if (!identificador || !tipoFiscal || !cnpjLoja) return;
      const resultado = await api.get(
        `/api/vendas?identificador=${identificador.replace(
          /[^\d]/g,
          ''
        )}&tipoFiscal=${tipoFiscal}&cnpj=${cnpjLoja.replace(/[^\d]/g, '')}`
      );
      if (resultado.data.vendas.length > 0) {
        toast.error(`Venda já processada`, {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 10000,
        });
        setIdentificador('');
        setTipoFiscal('');
      }
    } catch (error) {
      if (error instanceof axios) {
        toast.error(error.message, {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 10000,
        });
      }
    }
  };

  const validarCodigo = async (e) => {
    if (!codigo) return;
    const filtros = {
      codigo,
    };
    const resultado = await api.get(
      `/api/produtos?filtros=${JSON.stringify(filtros)}`
    );
    if (resultado.data.produtos.length === 0) {
      toast.error(`Produto não encontrado`, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      setCodigo('');
      setNome('');
    } else {
      setNome(resultado.data.produtos[0].nome);
    }
  };

  const calcularSubTotalItem = () => {
    const subTotal = (
      Number(valorUnitario) * Number(qtde) -
      Number(valorDescontoItem)
    ).toFixed(2);
    setSubTotalItem(subTotal);
  };

  const addItem = (e) => {
    e.preventDefault();
    if (!codigo || !valorUnitario || !qtde) {
      toast.error(`Informe todos os campos para adicionar um item`, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      return;
    }
    if (
      Number(valorUnitario) < 0 ||
      Number(qtde) < 1 ||
      Number(valorDescontoItem) < 0
    ) {
      toast.error(`Informe somente valores válidos`, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      return;
    }
    const item = {
      codigo,
      nome,
      qtde,
      valorUnitario,
      valorDesconto: valorDescontoItem,
      subTotal: subTotalItem,
    };
    setItens([...itens, item]);
    setCodigo('');
    setNome('');
    setValorUnitario('');
    setValorDescontoItem('');
    setQtde('');
    setSubTotalItem('');

    setDescontos((Number(descontos) + Number(valorDescontoItem)).toFixed(2));
    setTotal((Number(total) + Number(subTotalItem)).toFixed(2));
  };

  const addNotaItem = (data) => {
    const { nome, qtde, valorUnitario, valorDesconto, subTotal, id } = data;

    if (!valorUnitario || !qtde) {
      toast.error(`Informe todos os campos para adicionar um item`, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      return;
    }

    if (
      Number(valorUnitario) < 0 ||
      Number(qtde) < 1 ||
      Number(valorDescontoItem) < 0
    ) {
      toast.error(`Informe somente valores válidos`, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      return;
    }

    const item = {
      codigo,
      nome,
      qtde,
      valorUnitario,
      valorDesconto,
      subTotal,
    };

    setItens([...itens, item]);
    setNotaItens(notaItens.filter((item) => item.id !== id));
    setCodigo('');
    setNome('');
    setDescontos((Number(descontos) + Number(valorDesconto)).toFixed(2));
    setTotal((Number(total) + Number(subTotalItem)).toFixed(2));
  };

  const delItem = (e) => {
    e.preventDefault();
    const posicao = Number(e.target.id);
    setDescontos(
      (Number(descontos) - Number(itens[posicao].valorDesconto)).toFixed(2)
    );
    setTotal((Number(total) - Number(itens[posicao].subTotal)).toFixed(2));
    setItens(itens.filter((item, index) => index !== posicao));
  };

  const validarCampos = (dados) => {
    const entries = Object.entries(dados);

    let temNulo = false;
    entries.forEach((param) => {
      if (temNulo) return;
      if (param[1] === '' && param[0] === 'estadoLoja') {
        toast.error(`Estado obrigatório`, {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 10000,
        });
        temNulo = true;
        return;
      }
      if (param[0] === 'itens' && param[1].length === 0) {
        toast.error(`Adicione itens antes de processar`, {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 10000,
        });
        temNulo = true;
        return;
      }
      if (param[1] === '' && param[0] === 'tipoFiscal') {
        toast.error(`Tipo Fiscal obrigatório`, {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 10000,
        });
        temNulo = true;
        return;
      }
      if (param[0] === 'enviarMensagem') {
        return;
      }
      if (
        param[1] === '' &&
        param[0] !== 'totalVenda' &&
        param[0] !== 'totalDescontos' &&
        param[0] !== 'codigoPromocional' &&
        param[0] !== 'cidadeLoja' &&
        param[0] !== 'nomeInputLoja'
      ) {
        // console.log(param[0]);
        const elemento = document.getElementsByName(param[0])[0];
        toast.error(`${elemento.placeholder} obrigatório`, {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 10000,
        });
        temNulo = true;
        return;
      }
    });

    return temNulo;
  };

  const processarVenda = async (e) => {
    e.preventDefault();
    let venda = {
      cpf,
      tipoFiscal,
      identificador,
      realizadoEm,
      totalVenda: total,
      totalDescontos: descontos,
      itens,
      nomeArquivo,
      codigoPromocional,
      cnpjLoja,
      nomeLoja,
      cidadeLoja,
      estadoLoja,
    };

    const temNulo = validarCampos(venda);
    if (temNulo) {
      return;
    }

    venda.cnpjLoja = cnpjLoja.replace(/[^\d]/g, '');
    venda.cpf = venda.cpf.replace(/[^\d]/g, '');
    venda.realizadoEm = venda.realizadoEm.replace(/[^\d]/g, '');
    venda.realizadoEm = `${venda.realizadoEm.substring(
      4
    )}${venda.realizadoEm.substring(2, 4)}${venda.realizadoEm.substring(0, 2)}`;

    setEnviando(true);
    const resultado = await api.post('/api/pontuacoes/pontuar', {
      venda,
      enviarMensagem,
    });
    if (resultado) {
      toast.error(resultado.data.mensagem, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      setItens([]);
      setCpf('');
      setParceiro('');
      setTipoFiscal('');
      setIdentificador('');
      setRealizadoEm('');
      setTotal('');
      setDescontos('');
      setCodigoPromocional('');
      setMotivo('');
    }
    setEnviando(false);
    if (url) {
      historico.goBack();
    }
  };

  const validarCaractereCnpj = (e) => {
    const theEvent = e;
    let key = null;
    if (theEvent.type === 'paste') {
      key = window.event.clipboardData.getData('text/plain');
    } else {
      key = theEvent.keyCode || theEvent.which;
      key = String.fromCharCode(key);
    }
    const regex = /[0-9]|\./;
    if (!regex.test(key)) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) theEvent.preventDefault();
    }
  };

  const validaCNPJ = (e) => {
    if (!e.target.value) {
      return;
    }
    if (!validarCnpj(e.target.value)) {
      setCnpjLoja('');
      toast.error('CNPJ Inválido', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      return;
    }
    buscarCnpj(e);
  };

  const buscarCnpj = async (element) => {
    let input = element.target;
    let cnpj = input.value.replace(/[^\d]/g, '');
    if (!cnpj) return;
    const filtros = {
      cnpj,
    };
    const resultado = await api.get(
      `/api/revendas?filtros=${JSON.stringify(filtros)}`
    );
    if (resultado.data.revendas.length > 0) {
      toast.error('Revenda encontrada', {
        position: toast.POSITION.BOTTOM_CENendCidadeTER,
        autoClose: 10000,
      });

      setCodigoPromocional('');
      setCodigoPromocionalInfo({});
      setLojaCadastrada(true);
      setNomeLoja(resultado.data.revendas[0].nomeFantasia);
      setEstadoLoja(resultado.data.revendas[0].endEstado);
      setCidadeLoja(resultado.data.revendas[0].endCidade);
    }
  };

  return (
    <Container fluid>
      <div>
        <div className="d-flex flex-row align-items-center">
          <h4 className="pt-3 pb-3 f-black">Nova Venda</h4>
          {url && pdf && (
            <button
              className="btn-primario mt-3 mb-3 px-2 ml-2"
              type="submit"
              onClick={(e) => window.open(url)}
            >
              BAIXAR PDF
            </button>
          )}
        </div>
        <hr className="hr-loja mt-0 mb-5" />
      </div>
      <div className="d-flex flex-column align-items-center">
        <Row className="w-100">
          <Col
            xs={12}
            lg={colFormat}
            className="d-flex flex-column justify-content-center align-items-center"
          >
            <form className="form" onSubmit={processarVenda}>
              <label htmlFor="cpf">CPF</label>
              <InputMask
                id="cpf"
                name="cpf"
                type="text"
                placeholder="CPF"
                value={cpf}
                mask="999.999.999-99"
                onChange={(e) => setCpf(e.target.value)}
                onBlur={(e) => validarCpf(e.target.value)}
              ></InputMask>
              <label htmlFor="parceiro">Pintor</label>
              <input
                id="parceiro"
                name="parceiro"
                value={parceiro}
                placeholder="Pintor"
                disabled
              ></input>
              <Row>
                <Col className="d-flex flex-column" xs={12}>
                  <label htmlFor="cnpjLoja">CNPJ *</label>
                  <InputMask
                    id="cnpjLoja"
                    name="cnpjLoja"
                    type="text"
                    placeholder="CNPJ"
                    mask="99.999.999/9999-99"
                    value={cnpjLoja}
                    onChange={(e) => setCnpjLoja(e.target.value)}
                    onKeyPress={validarCaractereCnpj}
                    onBlur={validaCNPJ}
                  ></InputMask>
                </Col>
              </Row>
              <label className="mt-4 mb-5 f-18">Loja</label>
              <Row>
                <Col className="d-flex flex-column" xs={12}>
                  <label htmlFor="nomeLoja">Loja *</label>
                  {lojaCadastrada ? (
                    <InputMask
                      id="nomeLoja"
                      name="nomeLoja"
                      type="text"
                      placeholder="Loja"
                      value={nomeLoja}
                      onChange={(e) => setNomeLoja(e.target.value)}
                      disabled
                    ></InputMask>
                  ) : (
                    <InputMask
                      id="nomeLoja"
                      name="nomeLoja"
                      type="text"
                      placeholder="Loja"
                      value={nomeLoja}
                      onChange={(e) => setNomeLoja(e.target.value)}
                    ></InputMask>
                  )}
                </Col>
              </Row>
              <Row>
                <Col className="d-flex flex-column" xs={12} sm={6}>
                  <label htmlFor="estadoLoja">Estado *</label>
                  {lojaCadastrada ? (
                    <select
                      id="estadoLoja"
                      name="estadoLoja"
                      type="text"
                      placeholder="Estado"
                      value={estadoLoja}
                      onChange={(e) => setEstadoLoja(e.target.value)}
                      disabled
                    >
                      <option disabled value="">
                        Estado
                      </option>
                      <option value="AC">Acre</option>
                      <option value="AL">Alagoas</option>
                      <option value="AP">Amapá</option>
                      <option value="AM">Amazonas</option>
                      <option value="BA">Bahia</option>
                      <option value="CE">Ceará</option>
                      <option value="DF">Distrito Federal</option>
                      <option value="ES">Espírito Santo</option>
                      <option value="GO">Goiás</option>
                      <option value="MA">Maranhão</option>
                      <option value="MT">Mato Grosso</option>
                      <option value="MS">Mato Grosso do Sul</option>
                      <option value="MG">Minas Gerais</option>
                      <option value="PA">Pará</option>
                      <option value="PB">Paraíba</option>
                      <option value="PR">Paraná</option>
                      <option value="PE">Pernambuco</option>
                      <option value="PI">Piauí</option>
                      <option value="RJ">Rio de Janeiro</option>
                      <option value="RN">Rio Grande do Norte</option>
                      <option value="RS">Rio Grande do Sul</option>
                      <option value="RO">Rondônia</option>
                      <option value="RR">Roraima</option>
                      <option value="SC">Santa Catarina</option>
                      <option value="SP">São Paulo</option>
                      <option value="SE">Sergipe</option>
                      <option value="TO">Tocantins</option>
                    </select>
                  ) : (
                    <select
                      id="estadoLoja"
                      name="estadoLoja"
                      type="text"
                      placeholder="Estado"
                      value={estadoLoja}
                      onChange={(e) => setEstadoLoja(e.target.value)}
                    >
                      <option disabled value="">
                        Estado
                      </option>
                      <option value="AC">Acre</option>
                      <option value="AL">Alagoas</option>
                      <option value="AP">Amapá</option>
                      <option value="AM">Amazonas</option>
                      <option value="BA">Bahia</option>
                      <option value="CE">Ceará</option>
                      <option value="DF">Distrito Federal</option>
                      <option value="ES">Espírito Santo</option>
                      <option value="GO">Goiás</option>
                      <option value="MA">Maranhão</option>
                      <option value="MT">Mato Grosso</option>
                      <option value="MS">Mato Grosso do Sul</option>
                      <option value="MG">Minas Gerais</option>
                      <option value="PA">Pará</option>
                      <option value="PB">Paraíba</option>
                      <option value="PR">Paraná</option>
                      <option value="PE">Pernambuco</option>
                      <option value="PI">Piauí</option>
                      <option value="RJ">Rio de Janeiro</option>
                      <option value="RN">Rio Grande do Norte</option>
                      <option value="RS">Rio Grande do Sul</option>
                      <option value="RO">Rondônia</option>
                      <option value="RR">Roraima</option>
                      <option value="SC">Santa Catarina</option>
                      <option value="SP">São Paulo</option>
                      <option value="SE">Sergipe</option>
                      <option value="TO">Tocantins</option>
                    </select>
                  )}
                </Col>
                <Col className="d-flex flex-column" xs={12} sm={6}>
                  <label htmlFor="cidadeLoja">Cidade *</label>
                  {lojaCadastrada ? (
                    <InputMask
                      id="cidade"
                      name="cidade"
                      type="text"
                      placeholder="Cidade"
                      value={cidadeLoja}
                      disabled
                      onChange={(e) => setNomeLoja(e.target.value)}
                    ></InputMask>
                  ) : (
                    <InputMask
                      id="cidade"
                      name="cidade"
                      type="text"
                      placeholder="Cidade"
                      value={cidadeLoja}
                      onChange={(e) => setCidadeLoja(e.target.value)}
                    ></InputMask>
                  )}
                </Col>
              </Row>
              <Row>
                <Col className="d-flex flex-column" xs={12} sm={6}>
                  <label htmlFor="tipoFiscal">Tipo Fiscal</label>
                  <select
                    id="tipoFiscal"
                    name="tipoFiscal"
                    value={tipoFiscal}
                    onChange={(e) => setTipoFiscal(e.target.value)}
                    placeholder="Tipo Fiscal"
                  >
                    <option disabled value="">
                      Tipo Fiscal
                    </option>
                    <option value="N">Nota fiscal</option>
                    <option value="C">Cupom fiscal</option>
                  </select>
                </Col>
                <Col className="d-flex flex-column" xs={12} sm={6}>
                  <label htmlFor="identificador">Nº Fiscal</label>
                  <InputMask
                    id="identificador"
                    name="identificador"
                    type="text"
                    placeholder="Nº Fiscal"
                    value={identificador}
                    onChange={(e) => setIdentificador(e.target.value)}
                    onBlur={verificarIdentificador}
                  ></InputMask>
                </Col>
              </Row>
              <label htmlFor="realizadoEm">Data da Venda</label>
              <InputMask
                id="realizadoEm"
                name="realizadoEm"
                type="text"
                placeholder="Data da Venda"
                value={realizadoEm}
                onBlur={validarDataVenda}
                onChange={(e) => setRealizadoEm(e.target.value)}
                mask="99/99/9999"
              ></InputMask>
              <label className="mt-4 mb-5 f-18" htmlFor="itens">
                Itens - {`${itens.length} adicionado(s)`}
              </label>

              {isEmpty(notaItens) ? (
                <>
                  {' '}
                  <Row>
                    <SelectProduct
                      className="w-100 mx-3"
                      onChange={(data) => {
                        setCodigo(data.value);
                        const [, ...nome] = data.label.split(' - ');
                        setNome(nome.join(' - '));
                      }}
                    ></SelectProduct>
                  </Row>
                  <Row>
                    <Col className="d-flex flex-column" xs={12} sm={4}>
                      <label htmlFor="codigo">Código Produto</label>
                      <input
                        disabled
                        type="text"
                        name="codigo"
                        value={codigo}
                        placeholder="Código Produto"
                      />
                    </Col>
                    <Col className="d-flex flex-column" xs={12} sm={8}>
                      <label htmlFor="nome">Descrição Produto</label>
                      <input
                        type="text"
                        name="nome"
                        value={nome}
                        disabled
                        placeholder="Descrição Produto"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col className="d-flex flex-column" xs={12} sm={6}>
                      <label htmlFor="valorUnitario">R$ Unitário</label>
                      <input
                        type="number"
                        step="any"
                        min={0}
                        name="valorUnitario"
                        value={valorUnitario}
                        placeholder="R$ Unitário"
                        onChange={(e) => setValorUnitario(e.target.value)}
                        onBlur={calcularSubTotalItem}
                      />
                    </Col>
                    <Col className="d-flex flex-column" xs={12} sm={6}>
                      <label htmlFor="qtde">Qtde</label>
                      <input
                        type="number"
                        name="qtde"
                        value={qtde}
                        min={1}
                        placeholder="Qtde"
                        onChange={(e) => setQtde(e.target.value)}
                        onBlur={calcularSubTotalItem}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col className="d-flex flex-column" xs={12} sm={6}>
                      <label htmlFor="valorDescontoItem">R$ Desconto</label>
                      <input
                        type="number"
                        step="any"
                        name="valorDescontoItem"
                        min={0}
                        placeholder="R$ Desconto"
                        onChange={(e) => setValorDescontoItem(e.target.value)}
                        value={valorDescontoItem}
                        onBlur={calcularSubTotalItem}
                      />
                    </Col>
                    <Col className="d-flex flex-column" xs={12} sm={6}>
                      <label htmlFor="subTotalItem">R$ Subtotal</label>
                      <input
                        type="number"
                        step="any"
                        name="subTotalItem"
                        value={subTotalItem}
                        placeholder="R$ Subtotal"
                        disabled
                      />
                    </Col>
                  </Row>
                  <Row className="d-flex justify-content-center align-item-center">
                    <button className="btn-adicionar mb-3" onClick={addItem}>
                      <i className="fas fa-plus"></i>
                    </button>
                  </Row>
                </>
              ) : (
                notaItens.map((item, index) => (
                  <>
                    <Row>
                      <Col className="d-flex flex-column" xs={12} sm={4}>
                        <label htmlFor="codigo">Código Produto</label>
                        <input
                          type="text"
                          name="codigo"
                          value={codigo}
                          placeholder="Código Produto"
                          onChange={(e) => setCodigo(e.target.value)}
                          onBlur={validarCodigo}
                        />
                      </Col>
                      <Col className="d-flex flex-column" xs={12} sm={8}>
                        <label htmlFor="nome">Descrição Produto</label>
                        <input
                          type="text"
                          name="nome"
                          value={item.nome}
                          disabled
                          placeholder="Descrição Produto"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col className="d-flex flex-column" xs={12} sm={6}>
                        <label htmlFor="valorUnitario">R$ Unitário</label>
                        <input
                          type="number"
                          step="any"
                          min={0}
                          name="valorUnitario"
                          value={item.valorUnitario}
                          placeholder="R$ Unitário"
                          onChange={(e) => setValorUnitario(e.target.value)}
                          onBlur={calcularSubTotalItem}
                        />
                      </Col>
                      <Col className="d-flex flex-column" xs={12} sm={6}>
                        <label htmlFor="qtde">Qtde</label>
                        <input
                          type="number"
                          name="qtde"
                          value={item.qtde}
                          min={1}
                          placeholder="Qtde"
                          onChange={(e) => setQtde(e.target.value)}
                          onBlur={calcularSubTotalItem}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col className="d-flex flex-column" xs={12} sm={6}>
                        <label htmlFor="valorDescontoItem">R$ Desconto</label>
                        <input
                          type="number"
                          step="any"
                          name="valorDescontoItem"
                          min={0}
                          placeholder="R$ Desconto"
                          onChange={(e) => setValorDescontoItem(e.target.value)}
                          value={item.valorDesconto ?? 0}
                          onBlur={calcularSubTotalItem}
                        />
                      </Col>
                      <Col className="d-flex flex-column" xs={12} sm={6}>
                        <label htmlFor="subTotalItem">R$ Subtotal</label>
                        <input
                          type="number"
                          step="any"
                          name="subTotalItem"
                          value={item.subTotal}
                          placeholder="R$ Subtotal"
                          disabled
                        />
                      </Col>
                    </Row>
                    <Row className="d-flex justify-content-center align-item-center">
                      <button
                        className="btn-adicionar mb-3"
                        onClick={(e) => {
                          e.preventDefault();
                          addNotaItem(item);
                        }}
                      >
                        <i className="fas fa-plus"></i>
                      </button>
                    </Row>
                  </>
                ))
              )}
              <Row>
                <ul className="lista" id="produtos">
                  {itens.map((item, index) => (
                    <li className="lista-item" key={index}>
                      {`${Number(item.qtde).toLocaleString('pt-BR')}x ${
                        item.nome
                      } - ${Number(item.valorUnitario).toLocaleString('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                      })} - Subtotal: ${Number(item.subTotal).toLocaleString(
                        'pt-BR',
                        {
                          style: 'currency',
                          currency: 'BRL',
                        }
                      )} ${` (Desconto: ${Number(
                        item.valorDesconto ?? 0
                      ).toLocaleString('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                      })})`}`}
                      <button onClick={delItem} className="btn-limpo">
                        <i id={index} className="fas fa-trash f-red"></i>
                      </button>
                    </li>
                  ))}
                </ul>
              </Row>
              <Row>
                <Col className="d-flex flex-column" xs={12} sm={6}>
                  <label htmlFor="descontos">R$ Desconto Total</label>
                  <input
                    type="number"
                    step="any"
                    name="descontos"
                    min={0}
                    placeholder="R$ Desconto"
                    value={descontos}
                    disabled
                  />
                </Col>
                <Col className="d-flex flex-column" xs={12} sm={6}>
                  <label htmlFor="total">R$ Total</label>
                  <input
                    type="number"
                    step="any"
                    name="total"
                    value={total}
                    placeholder="R$ Total"
                    disabled
                  />
                </Col>
              </Row>
              {codigoPromocional && (
                <Row>
                  <Col className="d-flex flex-column" xs={12}>
                    <label htmlFor="codigoPromocional">
                      Código Promocional
                    </label>
                    <input
                      type="text"
                      name="codigoPromocional"
                      value={codigoPromocional}
                      disabled
                      className="mb-0"
                      onBlur={(e) => buscarCodigoPromocional(e.target.value)}
                    />
                    {codigoPromocionalInfo.valor && (
                      <span className="text-center f-12">
                        {codigoPromocionalInfo.operacao === 'ADICIONAR'
                          ? `Serão adicionados ${Number(
                              codigoPromocionalInfo.valor
                            ).toLocaleString('pt-BR')} pontos extras`
                          : `A pontuação total da venda será multiplicado por ${Number(
                              codigoPromocionalInfo.valor
                            ).toLocaleString('pt-BR')}`}
                      </span>
                    )}
                  </Col>
                </Row>
              )}
              <div className="d-flex justify-content-center align-items-center w-100 mb-3">
                <input
                  type="checkbox"
                  name="check"
                  id="enviarMensagem"
                  className="m-1"
                  onChange={(e) => setEnviarMensagem(e.target.checked)}
                  checked={enviarMensagem}
                />
                <label htmlFor="checkRegulamento" className="m-0 f-black f-14">
                  Enviar mensagem via WhatsApp
                </label>
              </div>
              {enviando ? (
                <button
                  disabled
                  className="btn-primario mt-3 mb-3"
                  type="submit"
                >
                  ENVIANDO...
                </button>
              ) : (
                <button className="btn-primario mt-3 mb-3" type="submit">
                  ENVIAR
                </button>
              )}
              <button
                className="btn-secundario mt-3 mb-3"
                onClick={() => setOpenModalReprovar(true)}
                type="button"
              >
                REPROVAR
              </button>
            </form>
          </Col>

          <Col xs={12} lg={6} id="container-img">
            {url && !pdf && (
              <Viewer
                noResetZoomAfterChange
                visible={true}
                container={document.getElementById('container-img')}
                images={[{ src: url, alt: 'Envio' }]}
                noNavbar
                noImgDetails
                noClose
                attribute={false}
              />
            )}
          </Col>
        </Row>
        {openModalReprovar && (
          <ModalReprovarNota
            show={openModalReprovar}
            onHide={() => setOpenModalReprovar(false)}
            nomeArquivo={nomeArquivo}
          />
        )}
      </div>
    </Container>
  );
}

export default withRouter(NovaVenda);
