/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import api from '../../../services/API';
import './styles.css';
import { Link } from 'react-router-dom';

export default function PremiosLoja(props) {
  const [premios, setPremios] = useState([]);
  const [tags, setTags] = useState([]);
  const [tagsSelecionadas, setTagsSelecionadas] = useState([]);
  const [busca, setBusca] = useState('');
  const [buscando, setBuscando] = useState(false);
  const [buscaSaldo, setBuscaSaldo] = useState(false);

  function limparFiltros() {
    document
      .querySelectorAll('li')
      .forEach((li) => li.classList.remove('active'));

    setBuscaSaldo(false);
    setTagsSelecionadas([]);
  }

  async function buscarPremios(_b) {
    setBuscando(true);

    const resultado = await api.get(
      `/api/premios${`?tipo=${
        props.tipo || ''
      }`}${`&busca=${busca || _b || ''}`}${`&tags=${tagsSelecionadas.join(
        ','
      )}`}${`&buscaSaldo=${buscaSaldo}`}&ativo=1`
    );
    if (resultado) {
      setPremios(resultado.data.premios);
    }

    setBuscando(false);
  }

  async function buscarTags() {
    const resultado = await api.get(`/api/tags`);
    if (resultado) {
      setTags(resultado.data.tags);
    }
  }

  function gerenciarTagSaldo(e) {
    if (busca) {
      setBusca('');
    }

    const targetLi = e.target;
    if (targetLi.classList.contains('active')) {
      targetLi.classList.remove('active');
      setBuscaSaldo(false);
    } else {
      targetLi.classList.add('active');
      setBuscaSaldo(true);
    }
  }

  function gerenciarTags(e) {
    if (busca) {
      setBusca('');
    }

    const tag = e.target.textContent;
    const targetLi = e.target;
    if (targetLi.classList.contains('active')) {
      targetLi.classList.remove('active');
      setTagsSelecionadas(tagsSelecionadas.filter((t) => t !== tag));
    } else {
      targetLi.classList.add('active');
      setTagsSelecionadas([...tagsSelecionadas, tag]);
    }
  }

  function handleEnter(e) {
    if (e.key === 'Enter') {
      buscaComTexto();
    }
  }

  function buscaComTexto() {
    if (tagsSelecionadas.length > 0) {
      limparFiltros();
    }

    buscarPremios();
  }

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const searchQuery = urlParams.get('search');

    if (searchQuery) {
      setBusca(searchQuery);
      console.log('searchQuery', searchQuery, busca);
      buscarPremios(searchQuery);
    } else {
      buscarPremios();
    }
  }, [tagsSelecionadas, buscaSaldo]);

  useEffect(() => {
    document.title = 'Clube Pro Pintor - Loja';
    buscarTags();
    const urlParams = new URLSearchParams(window.location.search);
    const searchQuery = urlParams.get('search');

    if (searchQuery) {
      setBusca(searchQuery);
      console.log('searchQuery', searchQuery, busca);
      buscarPremios(searchQuery);
    } else {
      buscarPremios();
    }
  }, []);

  return (
    <Container className="my-5">
      <Row>
        <Col xs={12} md={4} lg={3}>
          <div className="mt-3">
            <h5 className="mb-3">CATEGORIAS</h5>
            <hr className="hr-loja mt-0" />
          </div>
          <Row className="sticky">
            <Col>
              <ul className="list-unstyled">
                <li className="tag mb-2" onClick={gerenciarTagSaldo}>
                  {!buscaSaldo ? (
                    <i className="far fa-square mr-3"></i>
                  ) : (
                    <i className="far fa-check-square mr-3"></i>
                  )}
                  Resgates disponíveis
                </li>
                {tags.map((tag) => (
                  <li className="tag" onClick={gerenciarTags} key={tag}>
                    {tagsSelecionadas.indexOf(tag) === -1 ? (
                      <i className="far fa-square mr-3"></i>
                    ) : (
                      <i className="far fa-check-square mr-3"></i>
                    )}
                    {tag}
                  </li>
                ))}
              </ul>
              <div className="limpar-filtros">
                <button className="btn-limpo" onClick={limparFiltros}>
                  Limpar filtros
                </button>
                <i className="fas fa-trash"></i>
              </div>
            </Col>
          </Row>
        </Col>

        <Col xs={12} md={8} lg={9}>
          <div className="mt-3 align-self-start sticky-search">
            <div className="d-flex flex-row justify-content-between ">
              <h5 className="mb-3">PRÊMIOS</h5>
              <div>
                <input
                  value={busca}
                  onChange={(e) => setBusca(e.target.value)}
                  onKeyUp={handleEnter}
                  className="input-busca"
                  placeholder="O que deseja resgatar?"
                />
                <button
                  className="btn-busca mt-0"
                  type="submit"
                  onClick={buscaComTexto}
                >
                  <i className="fas fa-search"></i>
                </button>
              </div>
            </div>
            <hr className="hr-loja mt-0" />
          </div>
          {buscando && (
            <Container>
              <Row className="busca-sem-resultados">
                <Col
                  xs={1}
                  className="d-flex justify-content-center align-items-center"
                >
                  <i className="fas fa-circle-notch fa-spin"></i>
                </Col>
                <Col>
                  <span>Buscando...</span>
                </Col>
              </Row>
            </Container>
          )}
          {premios.length === 0 && !buscando && (
            <Container>
              <Row className="busca-sem-resultados">
                <Col
                  xs={1}
                  className="d-flex justify-content-center align-items-center"
                >
                  <i className="fas fa-search"></i>
                </Col>
                <Col>
                  <span>
                    Sua busca{' '}
                    {busca && (
                      <>
                        por <span className="f-blue">{busca} </span>
                      </>
                    )}
                    não encontrou resultados :(
                  </span>
                  <br></br>
                  <small>
                    Por favor, tente outra vez com termos menos específicos.
                  </small>
                </Col>
              </Row>
            </Container>
          )}
          <Row>
            {!buscando &&
              premios.map((premio) => {
                return (
                  <Col
                    key={premio.codigo}
                    id={premio.codigo}
                    name="premio"
                    xs={12}
                    md={4}
                    lg={3}
                    className="mt-5"
                  >
                    <div className="d-flex flex-column justify-content-center align-items-center text-center">
                      <Link to={`/premio/${premio.codigo}`}>
                        <img
                          alt={premio.nome}
                          className="w-100"
                          src={premio.diretorioFoto}
                        ></img>
                      </Link>

                      <span className="card-premio-nome">{premio.nome}</span>
                      <span>
                        <b>
                          {Number(premio.pontosWeb).toLocaleString('pt-BR')}{' '}
                          pontos
                        </b>
                      </span>
                      <Link to={`/premio/${premio.codigo}`}>
                        <button className="btn-resgatar mt-1">RESGATAR</button>
                      </Link>
                    </div>
                  </Col>
                );
              })}
          </Row>
          <Row>
            <Col xs={12} className="mt-5">
              <button
                className="btn-resgatar"
                onClick={(e) => window.scrollTo({ top: 0, behavior: 'smooth' })}
              >
                Voltar ao topo
              </button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}
