import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { store } from '../../store';
import { buscarDados } from '../../store/modules/parceiro/actions';

export default function RotaLoja({
  component: Component,
  rotaPrivada = false,
  ...atributos
}) {
  const { autenticadoLoja } = store.getState().autenticacao;

  autenticadoLoja && store.dispatch(buscarDados());

  if (!autenticadoLoja && rotaPrivada) {
    return <Redirect to="/" />;
  }

  if (autenticadoLoja && !rotaPrivada && atributos.path === '/') {
    return <Redirect to="/home" />;
  }

  return <Route {...atributos} component={Component} />;
}
