import React from 'react';
import { Modal, Row, Col } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { atualizarPerfil } from '../../../store/modules/parceiro/actions';
import './styles.css';

const ModalTutorial = ({ handleClose, ...props }) => {
  const dispatch = useDispatch();
  const parceiro = useSelector((state) => state.parceiro);

  const fecharModal = () => {
    const data = { verTutorial: false, nav: true };
    dispatch(atualizarPerfil(data));
    handleClose();
  };

  return (
    <Modal
      {...props}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      id="video-modal"
      onHide={fecharModal}
    >
      <Modal.Header className="modal-header" closeButton />
      <Modal.Body className="modal-body">
        <Row>
          <Row className="mb-4">
            <Col xs={12} md={12} lg={6}>
              <div className="video-yt">
                <video
                  width="100%"
                  height="315"
                  border="none"
                  controls
                  className="w-100"
                  src="/videos/modal-tutorial-cliente.mp4"
                ></video>
              </div>
            </Col>
            <Col className="container-tutorial" xs={12} md={12} lg={6}>
              <div className="tutorial-texto">
                <h2>Vídeo Tutorial</h2>
                <h3>Sou Cliente</h3>
                <p>
                  Você amigo pintor, está com dúvidas de como acessar o Clube
                  Pro Pintor e suas funcionalidades veja esse video tutorial que
                  te ajuda na navegação e descobrir todas as possibilidades do
                  site.
                </p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={12} lg={6}>
              <div className="video-yt">
                <video
                  width="100%"
                  height="315"
                  border="none"
                  controls
                  className="w-100"
                  src="/videos/modal-tutorial-lojista.mp4"
                ></video>
              </div>
            </Col>
            <Col className="container-tutorial" xs={12} md={12} lg={6}>
              <div className="tutorial-texto">
                <h2>Vídeo Tutorial</h2>
                <h3>Sou Lojista</h3>
                <p>
                  Lojista Pareiro Sherwin-Williams agora é fácil para você
                  acessar as suas informações de negócio do Clube Pro Pintor,
                  através do relatório para lojistas tem acesso a diversas
                  informações que lhe possibilitam trabalhar melhor os seus
                  pintores.
                </p>
              </div>
            </Col>
          </Row>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default ModalTutorial;
