import React from 'react';
import { Modal } from 'react-bootstrap';
import './styles.css';

export default function ModalVideoWrapper(props) {
  return (
    <Modal
      {...props}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      id="video-modal"
    >
      <Modal.Body>{props.children}</Modal.Body>
    </Modal>
  );
}
