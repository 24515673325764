import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import api from '../../../../services/API';
import { toast } from 'react-toastify';

export default function ModalModulo({ show, id, onHide, onModuloClose, data }) {
  const [alterando, setAlterando] = useState(false);
  const [modulo, setModulo] = useState({});

  useEffect(() => {
    if (data) {
      setModulo({ descricao: data.descricao, nome: data.nome });
    }
  }, [data]);

  const onCreate = async (e) => {
    e.preventDefault();

    setAlterando(true);

    const resultado = await api.post(`/api//cursos/curso/${id}/modulo`, modulo);

    if (resultado) {
      toast.success(`Modulo criado com sucesso`, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      onModuloClose();
    }

    setAlterando(false);
  };

  const onUpdate = async (e) => {
    e.preventDefault();

    setAlterando(true);

    const resultado = await api.put(`/api/cursos/modulo/${data?.id}`, modulo);

    if (resultado) {
      toast.success(`Modulo atualizado com sucesso`, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      onModuloClose();
    }

    setAlterando(false);
  };

  const onDelete = async (e) => {
    e.preventDefault();

    setAlterando(true);

    const resultado = await api.delete(`/api/cursos/modulo/${data?.id}`);

    if (resultado) {
      toast.success(`Modulo deletado com sucesso`, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      onModuloClose();
    }

    setAlterando(false);
  };

  const onChange = (e) => {
    const v = e.target.value;
    const n = e.target.name;

    setModulo({ ...modulo, [n]: v });
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="sw"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      scrollable
    >
      <Modal.Header closeButton>
        <Modal.Title>Modulo {data ? ` - ${data?.nome}` : ''}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-0">
        <form
          className="form mw-100 mt-4 d-flex justify-content-center align-items-center"
          onSubmit={data ? onUpdate : onCreate}
          id="form"
        >
          <label htmlFor="nome">Nome</label>
          <input
            id="nome"
            name="nome"
            type="text"
            placeholder="Nome"
            value={modulo?.nome}
            onChange={onChange}
          />
          <label htmlFor="descricao" onChange={onChange}>
            Descrição
          </label>
          <input
            id="descricao"
            name="descricao"
            type="text"
            placeholder="Descrição Modulo"
            value={modulo?.descricao}
            onChange={onChange}
          />

          {data && (
            <button
              form="form"
              className="btn btn-danger m-1"
              onClick={onDelete}
            >
              EXCLUIR MODULO
            </button>
          )}
        </form>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex justify-content-center align-items-center w-100">
          {alterando ? (
            <button type="submit" className="btn btn-success m-1" disabled>
              PROCESSANDO...
            </button>
          ) : (
            <button type="submit" form="form" className="btn btn-success m-1">
              {data ? 'ALTERAR' : 'CADASTRAR'}
            </button>
          )}

          <button type="button" className="btn btn-danger m-1" onClick={onHide}>
            FECHAR
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
