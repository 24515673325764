import React, { useState } from 'react';
import { Modal, Card } from 'react-bootstrap';
import moment from 'moment';
import api from '../../../services/API';
import { toast } from 'react-toastify';
import { atualizarAntifraude } from '../../../store/modules/autenticacao/actions';
import { useDispatch } from 'react-redux';

export default function ModalLiberacaoAntifraude(props) {
  const [alterando, setAlterando] = useState(false);
  const [liberado, setLiberado] = useState('');
  const dispatch = useDispatch();

  const alterarLiberacao = async () => {
    setAlterando(true);
    const resultado = await api.post('/api/antifraude/liberacao', {
      rede: props.rede,
      codProduto: props.codProduto,
      quantidade: liberado,
    });

    if (resultado) {
      props.atualizarLiberacaoTabela(liberado, props.rede, props.codProduto);
      toast.success(`Liberação salva com sucesso`, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });

      dispatch(atualizarAntifraude(resultado.data.redesAntifraude));
      setLiberado('');
      props.onHide();
    }
    setAlterando(false);
  };

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      scrollable
    >
      <Modal.Header closeButton>
        <Modal.Title>Editar Liberação</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-2">
        <form className="form mw-100 d-flex justify-content-center align-items-center">
          <input
            type="number"
            value={liberado}
            onChange={(e) => setLiberado(e.target.value)}
          ></input>
        </form>
        <div className="mt-3">
          {props.liberacoes.map((obs) => (
            <Card className="mb-3 mt-3">
              <Card.Header>
                Liberado por {obs.nome} em{' '}
                {moment(obs.criadoEm).format('DD/MM/YYYY H:m:s')}
              </Card.Header>
              <Card.Body>
                <b>Observação:</b> {obs.parametros.quantidade}
              </Card.Body>
            </Card>
          ))}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex justify-content-center align-items-center w-100">
          {alterando ? (
            <button
              type="button"
              className="btn btn-success m-1"
              onClick={alterarLiberacao}
              disabled
            >
              SALVANDO...
            </button>
          ) : (
            <button
              type="button"
              className="btn btn-success m-1"
              onClick={alterarLiberacao}
            >
              SALVAR
            </button>
          )}
          <button
            type="button"
            className="btn btn-danger m-1"
            onClick={props.onHide}
          >
            CANCELAR
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
