/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import './styles.css';
import { Form, Input } from '@rocketseat/unform';
import Footer from '../../../components/Loja/Footer';
import Header from '../../../components/Loja/Header';
import { toast } from 'react-toastify';
import api from '../../../services/API';
import historico from '../../../services/Historico';

export default function PosCadastro(props) {
  return (
    <>
      <Header auto />
      <Container className="d-flex flex-column justify-content-center">
        <Row className="d-flex flex-column justify-content-center align-items-center mt-5 mb-5">
          <Col
            xs={12}
            className="d-flex flex-column justify-content-center align-items-center"
          >
            <h3>Bem-vindo ao Programa de Fidelidade SWPRO</h3>
            <br />
            <br />
            <span>
              Agora as suas compras em produtos Sherwin-Williams valem pontos,
              que você poderá trocar por produtos incríveis.
            </span>
            <br />
            <span>É muito fácil aproveitar todas as vantagens:</span>
            <ul>
              <li>
                Acesse: <a href="login">www.clubepropintor.com.br/login</a>.
              </li>
              <li>
                Insira seu CPF e a senha que você criou na hora de efetuar seu
                cadastro.
              </li>
            </ul>
            <span>É só seguir as instruções e acumular seus pontos.</span>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
}
