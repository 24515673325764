import React, { useEffect } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import './styles.css';

export default function DragDropTable(props) {
  return (
    <table
      className={`table text-center rounded ${
        props.rowsData && props.rowsData.length > 1 && 'table-hover'
      }`}
    >
      <thead
        className=""
        style={{
          backgroundColor: '#4a76b9',
          color: '#fff',
          overflow: 'hidden',
        }}
      >
        <tr>
          {props.colunas &&
            props.colunas.length > 0 &&
            props.colunas
              .filter((coluna) => !coluna.hidden)
              .map((coluna) => <th key={coluna.dataField}>{coluna.text}</th>)}
        </tr>
      </thead>
      <DragDropContext onDragEnd={props.onDragEnd}>
        <Droppable droppableId="drag-drop-table">
          {(provided) => (
            <tbody
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={{
                width: '100%',
              }}
            >
              {props.rowsData &&
                props.rowsData.length > 1 &&
                props.rowsData.map((rowData, index) => (
                  <Draggable
                    key={rowData.id}
                    draggableId={String(rowData.id)}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <tr
                        className="text-nowrap "
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={{
                          textOverflow: 'ellipsis',
                          backgroundColor: snapshot.isDragging && 'lightblue',
                          ...provided.draggableProps.style,
                        }}
                      >
                        {props.colunas &&
                          props.colunas.length > 0 &&
                          props.colunas
                            .filter((coluna) => !coluna.hidden)
                            .map((coluna) => (
                              <td
                                key={coluna.dataField}
                                style={{
                                  maxWidth: '200px',
                                  overflowX: 'hidden',
                                  textOverflow: 'ellipsis',
                                }}
                              >
                                {coluna.formatter
                                  ? coluna.formatter(this, rowData)
                                  : coluna.dataField
                                  ? rowData[coluna.dataField]
                                  : ''}
                              </td>
                            ))}
                      </tr>
                    )}
                  </Draggable>
                ))}
              {props.rowsData && props.rowsData.length === 1 && (
                <tr>
                  {props.colunas &&
                    props.colunas.length > 0 &&
                    props.colunas
                      .filter((coluna) => !coluna.hidden)
                      .map((coluna) => (
                        <td key={coluna.dataField} style={{ width: '250px' }}>
                          {coluna.formatter
                            ? coluna.formatter(this, props.rowsData.at(0))
                            : coluna.dataField
                            ? props.rowsData.at(0)[coluna.dataField]
                            : ''}
                        </td>
                      ))}
                </tr>
              )}
              {provided.placeholder}
              {(!props.rowsData ||
                (props.rowsData && props.rowsData.length === 0)) && (
                <tr>
                  <td colSpan={props?.colunas?.length}>Sem resultados</td>
                </tr>
              )}
            </tbody>
          )}
        </Droppable>
      </DragDropContext>
    </table>
  );
}
