import React, { useState } from 'react';
import { Modal, Card } from 'react-bootstrap';
import api from '../../../services/API';
import { toast } from 'react-toastify';
import { useEffect } from 'react';
import moment from 'moment';

export default function ModalObsAntifraude(props) {
  const [alterando, setAlterando] = useState(false);
  const [obs, setObs] = useState();

  const alterarObs = async () => {
    setAlterando(true);
    const resultado = await api.post('/api/antifraude/observacao', {
      rede: props.rede,
      codProduto: props.codProduto,
      observacao: obs,
    });

    if (resultado) {
      props.atualizarObsTabela(obs, props.rede, props.codProduto);
      toast.success(`Observação salva com sucesso`, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      props.onHide();
    }
    setAlterando(false);
  };

  useEffect(() => {
    (props.observacao === '' || props.observacao) && setObs(props.observacao);
  }, [props]);

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      scrollable
    >
      <Modal.Header closeButton>
        <Modal.Title>Editar Observação</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-2">
        <form className="form mw-100 d-flex justify-content-center align-items-center">
          <textarea
            id="obs"
            className="w-100 textarea"
            value={obs}
            onChange={(e) => setObs(e.target.value)}
          />
        </form>
        <div className="mt-3">
          {props.observacoes.map((obs) => (
            <Card className="mb-3 mt-3">
              <Card.Header>
                Editado por {obs.nome} em{' '}
                {moment(obs.criadoEm).format('DD/MM/YYYY H:m:s')}
              </Card.Header>
              <Card.Body>
                <b>Observação:</b> {obs.parametros.observacao}
              </Card.Body>
            </Card>
          ))}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex justify-content-center align-items-center w-100">
          {alterando ? (
            <button
              type="button"
              className="btn btn-success m-1"
              onClick={alterarObs}
              disabled
            >
              SALVANDO...
            </button>
          ) : (
            <button
              type="button"
              className="btn btn-success m-1"
              onClick={alterarObs}
            >
              SALVAR
            </button>
          )}
          <button
            type="button"
            className="btn btn-danger m-1"
            onClick={props.onHide}
          >
            CANCELAR
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
