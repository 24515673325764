/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Container } from 'react-bootstrap';
import moment from 'moment-timezone';

import RemoteTable from '../RemoteTable/';

export default function Vouchers() {
  const columns = [
    {
      text: 'Voucher',
      dataField: 'voucher',
      sort: true,
    },
    {
      text: 'Usados',
      dataField: 'usos',
      sort: true,
    },
    {
      text: 'Operação',
      dataField: 'operacao',
      sort: true,
    },
    {
      text: 'Criado em',
      dataField: 'criadoEm',
      formatter: (celula, valor) => {
        return valor.criadoEm
          ? moment(valor.criadoEm).format('DD/MM/YYYY')
          : '';
      },
      sort: true,
    },
  ];

  return (
    <Container fluid>
      <h4 className="pt-3 pb-3 f-black">Vouchers</h4>
      <hr className="hr-loja mt-0 mb-4" />
      {/* <Row className="mb-3">
        <Col xs={12} sm={3} md={3} lg={2} className="d-flex flex-column">
          <label>Valido até</label>
          <input
            type="date"
            className="input-theme"
            value={expiraEm}
            onChange={(e) => setExpiraEm(e.target.value)}
            max="2999-12-31"
          ></input>
        </Col>
        <Col xs={12} sm={3} md={3} lg={2} className="d-flex flex-column">
          <label>Utilizado</label>
          <select
            name="utilizado"
            id="utilizado"
            className="input-theme"
            value={utilizado}
            onChange={(e) => setUtilizado(e.target.value)}
          >
            <option value={''}>Todos</option>
            <option value={1}>Sim</option>
            <option value={0}>Não</option>
          </select>
        </Col>
        <Col
          xs={12}
          sm={1}
          className="d-flex flex-column justify-content-center align-items-center d-sm-block"
        >
          <button className="btn-busca" type="submit" onClick={setarFiltros}>
            <i className="fas fa-search"></i>
          </button>
        </Col>
      </Row> */}
      <div>
        <RemoteTable
          exportar={{
            fileName: 'vouchers',
          }}
          dataId="voucher"
          url="/api/vouchers"
          colunas={columns}
          ordenacaoInicial={{ campo: 'voucher', direcao: 'desc' }}
        />
      </div>
    </Container>
  );
}
