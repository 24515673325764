import React, { useState } from 'react';
import AsyncSelect from 'react-select/async';
import api from '../../../services/API';
import { toast } from 'react-toastify';

export default function SelectProduct(props) {
  const [products, setProducts] = useState([]);

  async function fetchProducts() {
    const response = await api.get(`/api/produtos?filtros=${JSON.stringify({ ativo: 1 })}`);

    if (response?.status !== 200) {
      return toast.error('Erro ao carregar produtos', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
    }

    const data = response.data.produtos.map((produto) => ({
      value: produto.codigo,
      label: `${produto.codigo} - ${produto.nome}`,
      id: produto.id,
    }));

    setProducts(data);
    return data;
  }

  async function filterProducts(search) {
    const filtered = await products.filter((product) => {
      return search
        ? product.label.toLowerCase().includes(search?.toLowerCase())
        : products;
    });
    return filtered;
  }

  return (
    <AsyncSelect
      noOptionsMessage={() => 'Sem resultados'}
      onChange={props.onChange}
      placeholder="Selecione um Produto"
      className={`react-select-container mb-3 ${props.className ? props.className : ''}`}
      classNamePrefix="react-select"
      cacheOptions
      defaultOptions
      loadOptions={products.length === 0 ? fetchProducts : filterProducts}
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          text: 'black',
          primary25: '#005a99',
          primary: '#005a99',
        },
      })}
    />
  );
}
