/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import api from '../../../services/API';
import { Row, Col } from 'react-bootstrap';
import moment from 'moment-timezone';
import sortFunc from '../../../utils/ordernarColunaInt';
import historico from '../../../services/Historico';
import RemoteTable from '../RemoteTable/index';
import capitalize from '../../../utils/capitalize';

export default function CodigosPromocionais() {
  const [utilizado, setUtilizado] = useState('');
  const [apenasVendas] = useState(0);
  const [expiraEm, setExpiraEm] = useState('');
  const [filtros, setFiltros] = useState({
    expiraEm: '',
    utilizado: '',
    limitacao: 0,
  });

  const setarFiltros = (e) => {
    e.preventDefault();
    setFiltros({ expiraEm, utilizado, limitacao: apenasVendas });
  };

  const columns = [
    {
      text: 'Código',
      dataField: 'codigo',
      sort: true,
    },
    {
      text: 'Pintor',
      dataField: 'nome',
      sort: true,
      formatter: (celula, valor) => {
        return valor.nome ? (
          <button
            className="btn-limpo"
            onClick={(e) => {
              e.preventDefault();
              historico.push(`pintor/${valor.cpf}`);
            }}
          >
            <b>{capitalize(valor.nome)}</b>
          </button>
        ) : (
          ''
        );
      },
    },
    {
      text: 'Valor',
      dataField: 'valor',
      formatter: (celula, valor) => {
        return Number(valor.valor).toLocaleString('pt-BR');
      },
      sort: true,
      sortFunc: sortFunc,
    },
    {
      text: 'Operação',
      dataField: 'operacao',
    },
    {
      text: 'Limitação',
      dataField: 'apenasVenda',
      sort: true,
      formatter: (celula, valor) => {
        return valor ? 'Vendas' : 'Geral';
      },
      hidden: true,
    },
    {
      text: 'Criado em',
      dataField: 'criadoEm',
      formatter: (celula, valor) => {
        return valor.criadoEm
          ? moment(valor.criadoEm).format('DD/MM/YYYY')
          : '';
      },
      sort: true,
    },
    {
      text: 'Expira em',
      dataField: 'expiraEm',
      formatter: (celula, valor) => {
        return valor.expiraEm
          ? moment(valor.expiraEm).format('DD/MM/YYYY')
          : '';
      },
      sort: true,
    },
    {
      text: 'Utilizado em',
      dataField: 'utilizadoEm',
      formatter: (celula, valor) => {
        return valor.utilizadoEm
          ? moment(valor.utilizadoEm).format('DD/MM/YYYY HH:mm:ss')
          : '';
      },
      sort: true,
    },
  ];

  return (
    <Container fluid>
      <h4 className="pt-3 pb-3 f-black">Códigos Promocionais</h4>
      <hr className="hr-loja mt-0 mb-4" />
      <Row className="mb-3">
        <Col xs={12} sm={3} md={3} lg={2} className="d-flex flex-column">
          <label>Valido até</label>
          <input
            type="date"
            className="input-theme"
            value={expiraEm}
            onChange={(e) => setExpiraEm(e.target.value)}
            max="2999-12-31"
          ></input>
        </Col>
        <Col xs={12} sm={3} md={3} lg={2} className="d-flex flex-column">
          <label>Utilizado</label>
          <select
            name="utilizado"
            id="utilizado"
            className="input-theme"
            value={utilizado}
            onChange={(e) => setUtilizado(e.target.value)}
          >
            <option value={''}>Todos</option>
            <option value={1}>Sim</option>
            <option value={0}>Não</option>
          </select>
        </Col>
        <Col
          xs={12}
          sm={1}
          className="d-flex flex-column justify-content-center align-items-center d-sm-block"
        >
          <button className="btn-busca" type="submit" onClick={setarFiltros}>
            <i className="fas fa-search"></i>
          </button>
        </Col>
      </Row>
      <div>
        <RemoteTable
          exportar={{
            fileName: 'codigos-promocionais',
          }}
          dataId="id"
          url="/api/codigo-promocional"
          colunas={columns}
          filtros={filtros}
          ordenacaoInicial={{ campo: 'utilizadoEm', direcao: 'desc' }}
        />
      </div>
    </Container>
  );
}
