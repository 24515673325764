import produce from 'immer';

const estadoInicial = {
  resgatando: false,
  atualizando: false,
  nome: null,
  cpf: null,
  email: null,
  celular: null,
  telefone: null,
  sexo: null,
  dataNascimento: null,
  endRua: null,
  endNumero: null,
  endBairro: null,
  endCidade: null,
  endEstado: null,
  endCep: null,
  enderecoEntrega: {},
  endComplemento: null,
  saldoPontos: null,
  totalPontos: null,
  aceitouRegulamento: null,
  aceitouPrivacidade: null,
  cartao: null,
  idIndicacao: null,
  indicacaoLoja: null,
  idRevenda: null,
  verTutorial: null,
};

export default function parceiro(state = estadoInicial, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@autenticacaoParceiro/DESAUTENTICAR': {
        draft.resgatando = false;
        draft.atualizando = false;
        draft.nome = null;
        draft.cpf = null;
        draft.email = null;
        draft.celular = null;
        draft.telefone = null;
        draft.sexo = null;
        draft.dataNascimento = null;
        draft.endRua = null;
        draft.endNumero = null;
        draft.endBairro = null;
        draft.endCidade = null;
        draft.endEstado = null;
        draft.endCep = null;
        draft.endComplemento = null;
        draft.saldoPontos = null;
        draft.totalPontos = null;
        draft.aceitouRegulamento = null;
        draft.aceitouPrivacidade = null;
        draft.cartao = null;
        draft.idIndicacao = null;
        draft.indicacaoLoja = null;
        draft.idRevenda = null;
        draft.enderecoEntrega = {};
        draft.temCertificado = {};
        draft.verTutorial = null;
        break;
      }
      case '@autenticacaoParceiro/AUTENTICAR_SUCESSO': {
        for (const index in action.payload.parceiro) {
          draft[index] = action.payload.parceiro[index];
        }
        break;
      }
      case '@parceiro/ATUALIZAR_PERFIL': {
        draft.atualizando = true;
        break;
      }
      case '@parceiro/ATUALIZAR_PERFIL_FALHOU': {
        draft.atualizando = false;
        break;
      }
      case '@parceiro/ATUALIZAR_PERFIL_SUCESSO': {
        console.log('action.payload.', action.payload);

        for (const index in action.payload) {
          draft[index] = action.payload[index];
        }

        draft.atualizando = false;
        break;
      }
      case '@parceiro/RESGATAR_PREMIO': {
        draft.resgatando = true;
        break;
      }
      case '@parceiro/RESGATAR_PREMIO_FALHOU': {
        draft.resgatando = false;
        break;
      }
      case '@parceiro/RESGATAR_PREMIO_SUCESSO': {
        draft.resgatando = false;
        draft.saldoPontos = action.payload.saldoNovo;
        if (action.payload.cartao) {
          draft.cartao = action.payload.cartao;
        }
        break;
      }
      case '@parceiro/ACEITAR_REGULAMENTO': {
        draft.aceitouRegulamento = '1';
        break;
      }
      case '@parceiro/ACEITAR_PRIVACIDADE': {
        draft.aceitouPrivacidade = '1';
        break;
      }
      case '@parceiro/ALTERAR_STATUS_CARTAO': {
        draft.cartao.bloqueado = action.payload.status;
        draft.cartao.emDesbloqueio = action.payload.emDesbloqueio;
        if (
          action.payload.status === 1 &&
          action.payload.emDesbloqueio === false
        ) {
          draft.cartao = null;
        }
        break;
      }
      case '@parceiro/ATUALIZAR_SALDO': {
        draft.saldoPontos = action.payload.saldo;
        break;
      }
      case '@parceiro/ATUALIZAR_PERFIL_FOTO': {
        draft.foto = action.payload.foto;
        break;
      }
      default:
    }
  });
}
