/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Container, Row, Col } from 'react-bootstrap';

import CursoInscrever from './components/CursoInscrever';
import CursoEstudar from './components/CursoEstudar';

import api from '../../../services/API';
import './styles.css';

export default function Curso(props) {
  const [curso, setCurso] = useState({});
  const [cursos, setCursos] = useState([]);

  const [buscando, setBuscando] = useState(false);
  const { idCurso } = useParams();

  async function buscarCurso() {
    setBuscando(true);
    try {
      const resultado = await api.get(`/api/cursos/parceiro/curso/${idCurso}`);

      if (resultado) {
        setCurso(resultado.data);
      }
    } catch (error) {}
    setBuscando(false);
  }

  async function buscarCursos(filters = {}) {
    setBuscando(true);
    try {
      const resultado = await api.post(`/api/cursos/parceiro/curso/search/`, {
        where: {
          Curso: {
            ...filters,
            ativo: true,
          },
        },
      });

      if (resultado) {
        setCursos(resultado.data);
      }
    } catch (error) {}
    setBuscando(false);
  }

  const limitText = (t) => {
    if (typeof t !== 'string') return '';

    if (t.length > 50) {
      t = t.slice(0, 50);
      return `${t} ...`;
    }
    return t;
  };

  const onCurso = (id) => {
    window.open('/cursos/' + id, '_self');
  };

  useEffect(() => {
    document.title = 'Clube Pro Pintor - Curso';
    buscarCurso();
    buscarCursos();
  }, []);

  return (
    <Container className="mb-5">
      {!curso.inscrito && (
        <CursoInscrever curso={curso} buscarCurso={buscarCurso} />
      )}

      {curso.inscrito && (
        <CursoEstudar curso={curso} buscarCurso={buscarCurso} />
      )}

      <Row className="mt-5">
        <Col xs={12}>
          <h3>
            <strong> Outros Cursos</strong>
          </h3>
        </Col>

        {cursos.slice(0, 5).map((i) => (
          <Col className="box-curso" xs={12} sm={5} md={2}>
            <img src={i.linkCapaCurso} className="capa" alt="" />

            {/* <p className="tag-curso">
              {i.preco
                ? Number(i.preco).toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  })
                : 'GRATUITO'}
            </p> */}

            <div className="p-2">
              <h6 className="mt-1">
                <strong> {i.nome}</strong>
              </h6>
            </div>

            <div className="d-flex justify-content-start position-absolute box-buttons">
              <button
                className="btn-primario px-4"
                onClick={onCurso.bind(this, i.id)}
              >
                Acessar{' '}
              </button>
              {/* <button className="btn btn-dark m-2">Saiba Mais </button> */}
            </div>
          </Col>
        ))}
      </Row>
    </Container>
  );
}
