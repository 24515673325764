import React, { useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { Container, Row, Col } from 'react-bootstrap';
import api from '../../../services/API';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import moment from 'moment-timezone';

export default function Relatorios() {
  const columns = [
    {
      dataField: 'mes',
      text: 'Mês',
    },
    {
      dataField: 'ano',
      text: 'Ano',
    },
    {
      dataField: 'pdf',
      text: 'PDF',
      formatter: (celula, valor) => {
        return (
          <a
            target="_blank"
            style={{ cursor: 'pointer' }}
            href={
              process.env.REACT_APP_API_BASE_URL +
              `/api/relatorios/${mesSelecionado}/${anoSelecionado}/pdf`
            }
          >
            <span>
              {valor.pdf}
              <i
                style={{ fontSize: '24px', marginLeft: '8px' }}
                class="far fa-file-pdf"
              ></i>{' '}
            </span>
          </a>
        );
      },
    },
  ];

  const meses = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ];

  const processarRelatorio = async () => {
    setData([
      {
        mes: mesName,
        ano: anoSelecionado,
      },
    ]);
  };

  const [data, setData] = useState([]);
  const [anoSelecionado, setAnoSelecionado] = useState(moment().year());
  const [mesSelecionado, setMesSelecionado] = useState(moment().month());

  const mesName = meses.find((item, index) => {
    if (++index === Number(mesSelecionado)) return item;
  });

  const [buscando, setBuscando] = useState(false);

  return (
    <Container fluid>
      <h4 className="pt-3 pb-3 f-black">
        {buscando ? 'Aguarde, processando relatório ...' : 'Relatórios'}
      </h4>
      <hr className="hr-loja mt-0 mb-4" />
      <Row className="mb-3">
        <Col xs={12} sm={3} md={3} lg={2} className="d-flex flex-column">
          <label htmlFor="mesSelecionado">Mês</label>
          <select
            className="input-select mb-3"
            name="mesSelecionado"
            id="mesSelecionado"
            onChange={(e) => setMesSelecionado(e.target.value)}
            defaultValue={mesSelecionado}
          >
            {(() => {
              let mesesSelect = [];
              if (anoSelecionado === 2020) {
                for (var j = 7; j <= moment().month(); j++) {
                  mesesSelect.push(<option value={j}>{meses[j - 1]}</option>);
                }
              } else {
                for (var k = 1; k <= moment().month(); k++) {
                  mesesSelect.push(<option value={k}>{meses[k - 1]}</option>);
                }
              }
              return mesesSelect;
            })()}
          </select>
        </Col>
        <Col xs={12} sm={3} md={3} lg={2} className="d-flex flex-column">
          <label htmlFor="anoSelecionado">Ano</label>
          <select
            className="input-select mb-3"
            name="anoSelecionado"
            id="anoSelecionado"
            onChange={(e) => setAnoSelecionado(e.target.value)}
            defaultValue={anoSelecionado}
          >
            {(() => {
              let anosSelect = [];
              for (let j = 2020; j <= moment().year(); j++) {
                anosSelect.push(<option value={j}>{j}</option>);
              }
              return anosSelect;
            })()}
          </select>
        </Col>
        {/* <Col xs={12} sm={3} md={4} lg={2} className="d-flex flex-column">
          <label htmlFor="premiosEntregues">Prêmios mês atual</label>
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text">R$</span>
            </div>
            <input
              type="text"
              class="form-control"
              name="premiosEntregues"
              id="premiosEntregues"
              onChange={(e) => setPremiosEntregues(e.target.value)}
              defaultValue={premiosEntregues}
            />
          </div>
        </Col>
        <Col xs={12} sm={3} md={4} lg={2} className="d-flex flex-column">
          <label htmlFor="premiosEntregues">Prêmios mês anterior</label>
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text">R$</span>
            </div>
            <input
              type="text"
              class="form-control"
              name="premiosEntregues"
              id="premiosEntregues"
              onChange={(e) => setPremiosEntreguesAnterior(e.target.value)}
              defaultValue={premiosEntreguesAnterior}
            />
          </div>
        </Col> */}
        <Col
          xs={12}
          sm={3}
          md={3}
          lg={2}
          className="d-flex flex-column justify-content-center"
        >
          <button
            className="btn-primario-slim"
            onClick={processarRelatorio}
            disabled={buscando}
          >
            {buscando ? 'Processando ...' : 'Gerar Relatório'}
          </button>
        </Col>
      </Row>
      <div>
        {!buscando && (
          <ToolkitProvider
            keyField="pdf"
            data={data}
            columns={columns}
            bootstrap4
          >
            {(props) => (
              <BootstrapTable
                noDataIndication={() =>
                  buscando ? (
                    <span>
                      Carregando
                      <i className="fas fa-circle-notch fa-spin ml-3"></i>
                    </span>
                  ) : (
                    <span>Sem resultados</span>
                  )
                }
                classes="table-dark text-center rounded"
                wrapperClasses="rounded"
                bordered={false}
                {...props.baseProps}
              />
            )}
          </ToolkitProvider>
        )}
      </div>
    </Container>
  );
}
