/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { Player } from 'video-react';
import Form from 'react-bootstrap/Form';

import api from '../../../../services/API';

export default function CursoAvaliacao({ avaliacao, onBack, aulaId }) {
  const [respostas, setReposta] = useState([]);
  const [buscando, setBuscando] = useState(false);
  const [error, setError] = useState(null);
  const [certificado, setCertificado] = useState(null);

  const onChange = (resposta, pergunta) => {
    const res = { pergunta: pergunta, resposta: resposta };

    const temp = respostas.filter((i) => i.pergunta !== pergunta);
    setReposta([...temp, res]);
  };

  const onFinalizar = async () => {
    setBuscando(true);
    try {
      const { data } = await api.post(
        `/api/cursos/parceiro/aula/${aulaId}/concluir`,
        {
          Avaliacoes: [
            {
              id: avaliacao.id,
              respostas,
            },
          ],
        }
      );

      // console.log(data);

      if (!data.concluida) {
        setError(true);
      }
      if (data.concluida) {
        setError(null);
        setCertificado(data.certificados.curso.link);
      }
    } catch (error) {}
    setBuscando(false);
  };

  // console.log(avaliacao);
  return (
    <div className="d-flex align-items-center justify-content-center mt-4">
      {buscando && (
        <div
          style={{
            height: '100vh',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div class="spinner-grow text-secondary " s role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      )}

      {!buscando && (
        <Row className="p-2" style={{ width: '600px' }}>
          <Col xs={12} className="avalicao-box py-2 px-4">
            <h1>{avaliacao.nome}</h1>
            <p className="m-0">
              <strong>{avaliacao.descricao}</strong>
            </p>
          </Col>
          {avaliacao.perguntas.map((p, ip) => (
            <Col xs={12} className="curso-box py-2 px-4 mt-3">
              <p className="m-0">
                <strong>{p.pergunta}</strong>
              </p>

              <Form>
                {p.respostas_possiveis.map((r, ri) => (
                  <div
                    className="d-flex align-items-center mt-2"
                    key={'inline-radio'}
                  >
                    <Form.Check
                      inline
                      type={'radio'}
                      name="group1"
                      label={
                        <p className="m-0">
                          <strong>{r}</strong>
                        </p>
                      }
                      onChange={(e) => onChange(e.target.value, ip)}
                      value={ri}
                      id={`inline-radio-${ri}`}
                    />
                  </div>
                ))}
              </Form>
            </Col>
          ))}
          {error && (
            <Col className="my-3" xs={12}>
              <i
                className="fas fa-exclamation-triangle"
                style={{
                  color: 'red',
                  fontSize: '1.5rem',
                  paddingRight: '1rem',
                }}
              ></i>
              Resposta errada por favor refaça o teste.
            </Col>
          )}

          {certificado && (
            <Col className="my-3" xs={12}>
              <i
                className="fas fa-check"
                style={{
                  color: 'green',
                  fontSize: '1.5rem',
                  paddingRight: '1rem',
                }}
              ></i>
              Parabéns, vocẽ foi aprovado! Aproveite e envie eu certificado para
              a plataforma Ache seu pintor.{' '}
            </Col>
          )}

          <Col className="mt-3 p-0">
            <div className="d-flex align-items-center justify-content-between">
              {certificado ? (
                <button
                  className=" btn-inscreva-se px-3 mx-2"
                  onClick={() => window.open(certificado, '_blank')}
                >
                  <i
                    class="fa fa-file-download"
                    style={{
                      color: '#fff',
                      fontSize: '16px',
                      marginRight: '0.5rem',
                    }}
                  />
                  Baixar Certificado
                </button>
              ) : (
                <button
                  className="btn-inscreva-se px-3 mx-2"
                  onClick={onFinalizar}
                  disabled={respostas.length !== avaliacao.perguntas.length}
                >
                  Enviar
                </button>
              )}

              <a className="px-3 mt-0" role="button" onClick={onBack}>
                Voltar a aula
              </a>
            </div>
          </Col>
        </Row>
      )}
    </div>
  );
}
