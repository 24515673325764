/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Lightbox from 'react-image-lightbox';
import { Modal } from 'react-bootstrap';

export default function ModalMidia(props) {
  const [modal, setModal] = useState('');

  useEffect(() => {
    gerarModal();
  }, [props.show]);

  const fecharModal = () => {
    setModal('');
    props.onHide();
  };

  const gerarModal = () => {
    if (props.show) {
      if (props.tipo.includes('image')) {
        setModal(
          <Lightbox
            mainSrc={`${process.env.REACT_APP_API_BASE_URL}/arquivos/${props.diretorioArquivo}`}
            onCloseRequest={fecharModal}
          />
        );
      } else {
        setModal(
          <Modal
            show={props.show}
            onHide={fecharModal}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            scrollable
          >
            <video
              controls
              src={`${process.env.REACT_APP_API_BASE_URL}/arquivos/${props.diretorioArquivo}`}
            ></video>
          </Modal>
        );
      }
    }
  };

  return modal;
}
