import React from 'react';
import RotaPainel from './RotaPainel';

import Login from '../../pages/Painel/Login';
import Home from '../../pages/Painel/Home';

const rotas = [
  {
    path: '/painel',
    exact: true,
    component: Login,
    rotaPrivada: false,
  },
  {
    path: '/painel',
    component: Home,
    rotaPrivada: true,
  },
];

const rotasPainel = rotas.map((rota, index) => (
  <RotaPainel
    key={index}
    path={rota.path}
    exact={rota.exact}
    component={rota.component}
    rotaPrivada={rota.rotaPrivada}
  ></RotaPainel>
));

export default rotasPainel;
