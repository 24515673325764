import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import Regulamento from '../Regulamento/index';
import './styles.css';
import { aceitarRegulamento } from '../../../store/modules/parceiro/actions';
import { desautenticarParceiro } from '../../../store/modules/autenticacao/actions';

export default function ModalRegulamento({ handleClose, ...props }) {
  const dispatch = useDispatch();

  const [aceitou, setAceitou] = useState(false);

  const clicouAceitar = (e) => {
    e.preventDefault();
    handleClose();
    dispatch(aceitarRegulamento());
  };

  const clicouCancelar = (e) => {
    e.preventDefault();
    dispatch(desautenticarParceiro());
  };

  return (
    <Modal
      show={props.show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      scrollable
      keyboard={props.onlyView ? true : false}
      backdrop={props.onlyView ? true : 'static'}
      onHide={props.onHide}
    >
      {props.onlyView && (
        <Modal.Header
          bsPrefix={props.onlyView ? 'modal-header-extended' : 'modal-header'}
          closeButton
        ></Modal.Header>
      )}
      <Modal.Body
        bsPrefix={props.onlyView ? 'modal-body-extended' : 'modal-body'}
      >
        <Regulamento className="mt-0" />
      </Modal.Body>
      {!props.onlyView && (
        <Modal.Footer>
          <div className="w-100">
            <div className="d-flex justify-content-center align-items-center w-100 mb-2">
              <input
                type="checkbox"
                name="check"
                id="checkRegulamento"
                className="m-1"
                onChange={(e) => setAceitou(e.target.checked)}
              />
              <label htmlFor="checkRegulamento" className="m-0 f-black f-14">
                Concordo com os termos deste Regulamento.
              </label>
            </div>
            <div className="d-flex justify-content-center align-items-center w-100">
              {aceitou ? (
                <button
                  id="aceitarRegulamento"
                  type="button"
                  className="btn btn-success m-1"
                  onClick={clicouAceitar}
                >
                  ACEITAR
                </button>
              ) : (
                <button
                  id="aceitarRegulamento"
                  type="button"
                  className="btn btn-success m-1"
                  disabled
                >
                  ACEITAR
                </button>
              )}

              <button
                id="recusarRegulamento"
                type="button"
                className="btn btn-danger m-1"
                onClick={clicouCancelar}
              >
                CANCELAR
              </button>
            </div>
          </div>
        </Modal.Footer>
      )}
    </Modal>
  );
}
