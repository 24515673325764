import Cadastrar from '../../pages/Loja/Cadastrar/index';
import EsqueceuSenha from '../../pages/Loja/EsqueceuSenha/index';
import Home from '../../pages/Loja/Home';
import Login from '../../pages/Loja/Login';
import Loja from '../../pages/Loja/Loja';
import PoliticaPrivacidade from '../../pages/Loja/PoliticaPrivacidade/index';
import PosCadastro from '../../pages/Loja/PosCadastro/index';
import React from 'react';
import Regulamento from '../../components/Loja/Regulamento';
import RotaLoja from './RotaLoja';

const rotas = [
  {
    path: '/',
    exact: true,
    component: Home,
    rotaPrivada: false,
  },
  {
    path: '/cadastrar',
    exact: true,
    component: Cadastrar,
    rotaPrivada: false,
  },
  {
    path: '/login',
    exact: true,
    component: Login,
    rotaPrivada: false,
  },
  {
    path: '/esqueceu-senha',
    exact: true,
    component: EsqueceuSenha,
    rotaPrivada: false,
  },
  {
    path: '/esqueceu-senha/:uuid',
    component: EsqueceuSenha,
    rotaPrivada: false,
  },
  {
    path: '/politica-privacidade',
    exact: true,
    component: PoliticaPrivacidade,
    rotaPrivada: false,
  },
  {
    path: '/regulamento',
    exact: true,
    component: Regulamento,
    rotaPrivada: false,
  },
  {
    path: '/pos-cadastro',
    exact: true,
    component: PosCadastro,
    rotaPrivada: false,
  },
  {
    path: '/',
    component: Loja,
    rotaPrivada: true,
  },
];

const rotasLoja = rotas.map((rota, index) => (
  <RotaLoja
    key={index}
    path={rota.path}
    exact={rota.exact}
    component={rota.component}
    rotaPrivada={rota.rotaPrivada}
  ></RotaLoja>
));

export default rotasLoja;
