import React, { useEffect, useMemo, useState } from 'react';
import './styles.css';
import { Route, Switch } from 'react-router-dom';
import Footer from '../../../components/Loja/Footer';
import Header from '../../../components/Loja/Header';
import PremiosLoja from '../../../components/Loja/PremiosLoja';
import Slider from '../../../components/Loja/Slider';
import DetalhePremio from '../../../components/Loja/DetalhePremio';
import Perfil from '../../../components/Loja/Perfil';
import Pontuacoes from '../../../components/Loja/Pontuacoes';
import Resgates from '../../../components/Loja/Resgates';
import ListaPontos from '../../../components/Loja/ListaPontos';
import Contato from '../../../components/Loja/Contato';
import UploadFoto from '../../../components/Loja/UploadFoto';
import Regulamento from '../../../components/Loja/Regulamento';
import ModalRegulamento from '../../../components/Loja/ModalRegulamento';
import Cartao from '../../../components/Loja/Cartao/index';
import Midias from '../../../components/Loja/Midias/index';
import Treinamentos from '../../../components/Loja/Treinamentos/index';
import Indicacao from '../../../components/Loja/Indicacao/index';
import Cursos from '../../../components/Loja/Cursos/index';
import Curso from '../../../components/Loja/Curso/index';

import { useSelector } from 'react-redux';
import CursoAssistir from '../../../components/Loja/CursoAssistir';
import ModalTutorial from '../../../components/Loja/ModalTutorial';
import AcheSeuPintor from '../../../components/Loja/Ache Seu Pintor';
import ModalBanner from '../../../components/Loja/ModalBanner';
import ModalParceiroFoto from '../../../components/Loja/ModalParceiroFoto';
import LoggedHome from '../../../components/Loja/LoggedHome';

export default function Home() {
  const [modalIndex, setModalIndex] = useState(0);
  const modals = [
    { name: 'BANNER', condition: () => true },
    { name: 'REGULAMENTO', condition: () => !parceiro.aceitouRegulamento },
    { name: 'TUTORIAL', condition: () => parceiro.verTutorial },
    { name: 'FOTO', condition: () => parceiro.foto === null },
  ];

  const parceiro = useSelector((state) => {
    return state.parceiro;
  });

  const getNextModalIndex = (currentIndex) => {
    for (let i = currentIndex + 1; i < modals.length; i++)
      if (modals[i].condition()) return i;

    return -1;
  };

  const handleClose = () => {
    const nextModalIndex = getNextModalIndex(modalIndex);
    setModalIndex(nextModalIndex);
  };

  return (
    <>
      <Header auto />
      <main>
        <Switch>
          <Route exact path="/home" component={(props) => <LoggedHome />} />
          <Route exact path="/loja" component={(props) => <PremiosLoja />} />
          <Route
            exact
            path="/loja/credito"
            component={(props) => <PremiosLoja tipo="credito" />}
          />
          <Route
            exact
            path="/premio/:codigo"
            component={(props) => <DetalhePremio />}
          />
          <Route exact path="/indicacao" component={(props) => <Indicacao />} />
          <Route exact path="/perfil" component={(props) => <Perfil />} />
          <Route
            exact
            path="/pontuacoes"
            component={(props) => <Pontuacoes />}
          />
          <Route exact path="/resgates" component={(props) => <Resgates />} />
          <Route
            exact
            path="/enviar-nf"
            component={(props) => <UploadFoto />}
          />
          <Route
            exact
            path="/pontos-produtos"
            component={(props) => <ListaPontos />}
          />
          <Route
            exact
            path="/regulamento"
            component={(props) => <Regulamento />}
          />
          <Route
            exact
            path="/ache-seu-pintor"
            component={(props) => <AcheSeuPintor />}
          />
          <Route exact path="/cartao" component={(props) => <Cartao />} />
          <Route exact path="/contato" component={(props) => <Contato />} />
          <Route exact path="/fotos-videos" component={(props) => <Midias />} />
          <Route
            exact
            path="/treinamentos"
            component={(props) => <Treinamentos />}
          />
          <Route exact path="/cursos" component={(props) => <Cursos />} />
          <Route
            exact
            path="/cursos/:idCurso"
            component={(props) => <Curso />}
          />
          <Route
            exact
            path="/cursos/:idCurso/assistir"
            component={(props) => <CursoAssistir />}
          />
        </Switch>
      </main>
      <Footer />
      {modalIndex !== -1 && modals[modalIndex].name === 'BANNER' && (
        <ModalBanner handleClose={handleClose} />
      )}
      {modalIndex !== -1 && modals[modalIndex].name === 'REGULAMENTO' && (
        <ModalRegulamento
          show={!parceiro.aceitouRegulamento}
          handleClose={handleClose}
        />
      )}
      {modalIndex !== -1 && modals[modalIndex].name === 'TUTORIAL' && (
        <ModalTutorial show={true} handleClose={handleClose} />
      )}
      {modalIndex !== -1 && modals[modalIndex].name === 'FOTO' && (
        <ModalParceiroFoto idParceiro={parceiro.id} foto={parceiro.foto} handleClose={handleClose} />
      )}
    </>
  );
}
