import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import api from '../../../services/API';
import sortFunc from '../../../utils/ordernarColunaInt';

const ToSearch = (props) => {
  let input;
  const handleClick = () => {
    props.onSearch(input.value);
  };

  const handleEnterKey = (e) => {
    if (e.key === 'Enter') {
      props.onSearch(input.value);
    }
  };

  return (
    <div className="d-flex justify-content-center align-items-center">
      <input
        className="input-theme busca mr-1"
        ref={(n) => (input = n)}
        type="text"
        onKeyUp={handleEnterKey}
      />
      <button
        className="btn-primario-slim"
        onClick={handleClick}
        style={{ width: 40, height: 40 }}
      >
        <i className="fa fa-search px-2"></i>
      </button>
    </div>
  );
};

const columns = [
  {
    dataField: 'loja',
    text: 'Loja',
    sort: true,
    formatter: (celula, valor) => {
      return valor?.loja?.toUpperCase() || '';
    },
    style: { textAlign: 'left' },
    headerStyle: { textAlign: 'left' },
  },
  {
    dataField: 'cnpj',
    text: 'CNPJ',
    sort: true,
    formatter: (celula, valor) => {
      if (!valor.cnpj) return '';
      let cnpj = valor.cnpj.replace(/[^\d]/g, '');
      return cnpj.replace(
        /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
        '$1.$2.$3/$4-$5'
      );
    },
  },
  {
    dataField: 'localizacao',
    text: 'Cidade / UF',
    sort: true,
    formatter: (celula, valor) => {
      return valor?.localizacao || '';
    }
  },
  {
    dataField: 'totalVendido',
    text: 'Total Vendido',
    sort: true,
    sortFunc: sortFunc,
    formatter: (celula, valor) => {
      return Number(valor.totalVendido).toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      });
    },
  },
  {
    dataField: 'totalPontuado',
    text: 'Total Pontuado',
    sort: true,
    sortFunc: sortFunc,
    formatter: (celula, valor) =>
      valor.totalPontuado
        ? Number(valor.totalPontuado).toLocaleString('pt-BR')
        : 0,
  },
  {
    hidden: true,
    dataField: 'saldoPontos',
    text: 'Saldo',
    sort: true,
    sortFunc: sortFunc,
    formatter: (celula, valor) =>
      valor.saldoPontos ? Number(valor.saldoPontos).toLocaleString('pt-BR') : 0,
  },
  {
    dataField: 'pintoresQueCompram',
    text: 'Pintores que Compram',
    sort: true,
    sortFunc: sortFunc,
  },
];

const defaultSorted = [
  {
    dataField: 'totalPontuado',
    order: 'desc',
  },
];

export default function Lojas() {
  const [data, setData] = useState([]);
  const [buscando, setBuscando] = useState(false);

  useEffect(() => {
    buscarLojas();
  }, []);

  const buscarLojas = async () => {
    setBuscando(true);
    const resultado = await api.get(`/api/lojas`);
    if (resultado) {
      setData(resultado.data.lojas);
    }
    setBuscando(false);
  };

  const options = {
    sizePerPage: 10,
    showTotal: true,
    sizePerPageList: [
      {
        text: '5',
        value: 5,
      },
      {
        text: '10',
        value: 10,
      },
      {
        text: '50',
        value: 50,
      },
      {
        text: '100',
        value: 100,
      },
      {
        text: 'Todos',
        value: data.length,
      },
    ],
    paginationTotalRenderer: (from, to, size) =>
      size > 0 ? (
        <span className="react-bootstrap-table-pagination-total px-2">
          {from}-{to} de {size} resultados
        </span>
      ) : (
        <span className="react-bootstrap-table-pagination-total px-2">
          Nenhum resultado
        </span>
      ),
  };

  return (
    <Container fluid>
      <h4 className="pt-3 pb-3 f-black">Lojas</h4>
      <hr className="hr-loja mt-0 mb-4" />
      <div>
        <ToolkitProvider
          bootstrap4
          keyField="codigo"
          data={data}
          columns={columns}
          search
        >
          {(props) => (
            <div>
              <div className="d-flex flex-row-reverse mb-2">
                <ToSearch {...props.searchProps} />
              </div>
              <BootstrapTable
                noDataIndication={() =>
                  buscando ? (
                    <span>
                      Carregando
                      <i className="fas fa-circle-notch fa-spin ml-3"></i>
                    </span>
                  ) : (
                    <span>Sem resultados</span>
                  )
                }
                classes="table-dark text-center rounded"
                wrapperClasses="rounded"
                bordered={false}
                {...props.baseProps}
                pagination={paginationFactory(options)}
                defaultSorted={defaultSorted}
              />
            </div>
          )}
        </ToolkitProvider>
      </div>
    </Container>
  );
}
