import React from 'react';
import { Carousel, Container, Row, Col } from 'react-bootstrap';

import './styles.css';
import { Link } from 'react-router-dom';

function SliderAcheSeuPintor() {
  return (
    <Carousel interval={8000} className="mb-4">
      <Carousel.Item>
        <Link to="/ache-seu-pintor">
          <img
            className="d-block w-100"
            style={{ borderRadius: '10px 10px 0px 0px' }}
            src="imagens/banners/banner_ache_seu_pintor_01.jpg"
            alt="Slider"
          />
        </Link>
      </Carousel.Item>
      <Carousel.Item>
        <Link to="/ache-seu-pintor">
          <img
            className="d-block w-100"
            style={{ borderRadius: '10px 10px 0px 0px' }}
            src="imagens/banners/banner_ache_seu_pintor_02.jpg"
            alt="Slider"
          />
        </Link>
      </Carousel.Item>
    </Carousel>
  );
}

function SliderCursos() {
  return (
    <Carousel interval={8000} className="mb-4">
      <Carousel.Item>
        <Link to="/cursos">
          <img
            className="d-block w-100"
            style={{ borderRadius: '10px 10px 0px 0px' }}
            src="imagens/banners/banner_cursos_01.jpg"
            alt="Slider"
          />
        </Link>
      </Carousel.Item>
      <Carousel.Item>
        <Link to="/cursos">
          <img
            className="d-block w-100"
            style={{ borderRadius: '10px 10px 0px 0px' }}
            src="imagens/banners/banner_cursos_02.jpg"
            alt="Slider"
          />
        </Link>
      </Carousel.Item>
      <Carousel.Item>
        <Link to="/cursos">
          <img
            className="d-block w-100"
            style={{ borderRadius: '10px 10px 0px 0px' }}
            src="imagens/banners/banner_cursos_03.jpg"
            alt="Slider"
          />
        </Link>
      </Carousel.Item>
      <Carousel.Item>
        <Link to="/cursos">
          <img
            className="d-block w-100"
            style={{ borderRadius: '10px 10px 0px 0px' }}
            src="imagens/banners/banner_cursos_04.jpg"
            alt="Slider"
          />
        </Link>
      </Carousel.Item>
      <Carousel.Item>
        <Link to="/cursos">
          <img
            className="d-block w-100"
            style={{ borderRadius: '10px 10px 0px 0px' }}
            src="imagens/banners/banner_cursos_05.jpg"
            alt="Slider"
          />
        </Link>
      </Carousel.Item>
    </Carousel>
  );
}

function SliderParcela() {
  return (
    <Carousel interval={8000} className="mb-4">
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="imagens/banners/banner_parcela_01.jpg"
          alt="Slider"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="imagens/banners/banner_parcela_02.jpg"
          alt="Slider"
        />
      </Carousel.Item>
    </Carousel>
  );
}

function SliderResgate() {
  return (
    <Carousel interval={8000} className="mb-4">
      <Carousel.Item>
        <Link to="/loja">
          <img
            className="d-block w-100"
            src="imagens/banners/banner_pontos_01.jpg"
            alt="Slider"
          />
        </Link>
      </Carousel.Item>
      <Carousel.Item>
        <Link to="/loja">
          <img
            className="d-block w-100"
            src="imagens/banners/banner_pontos_02.jpg"
            alt="Slider"
          />
        </Link>
      </Carousel.Item>
    </Carousel>
  );
}

export default function LoggedHome() {
  const options = [
    {
      name: 'Resgate seu produto',
      icon: 'home-1.png',
      url: '/loja',
    },
    {
      name: 'Ache seu Pintor',
      icon: 'home-2.png',
      url: '/ache-seu-pintor',
    },
    {
      name: 'SW Pro Cursos',
      icon: 'home-4.png',
      url: '/cursos',
    },
    {
      name: 'Parcela Pintura',
      icon: 'home-3.png',
      url: 'https://parcelapintura.com.br/',
    },
  ];

  return (
    <Container className=" p-md-5 p-sm-4 p-2">
      <Row
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '2rem',
        }}
      >
        {options.map((item) => (
          <Col
            xs={11}
            sm={5}
            md={true}
            className="menu-box"
            onClick={() => {
              window.open(item.url);
            }}
            onMouseEnter={(e) => {
              e.target.style.backgroundColor = 'rgb(251 251 251)';
            }}
            onMouseLeave={(e) => {
              e.target.style.backgroundColor = '#fff';
            }}
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '1rem',
              borderRadius: '10px',
              width: '100%',
              boxShadow: '0px 0px 5px 2px rgba(0, 0, 0, 0.15)',
              marginBottom: '1rem',
              cursor: 'pointer',
            }}
          >
            <img src={'imagens/home/' + item.icon} alt={item.name} />
            <p>{item.name}</p>
          </Col>
        ))}
      </Row>

      <Row
        className="mb-5"
        style={{
          borderBottom: '2px solid #4a76b9',
          paddingBottom: '1rem',
          boxShadow: '0px 0px 5px 2px rgba(0, 0, 0, 0.15)',
          borderRadius: '10px',
        }}
      >
        <SliderAcheSeuPintor />
        <div
          className="d-flex justify-content-between align-content-center flex-column flex-md-row"
          style={{
            width: '100%',
            padding: '1rem',
          }}
        >
          <div>
            <h2 className="fw-bold">ACHE SEU PINTOR</h2>
            <p>Mostre seu trabalho e encontre mais clientes perto de você</p>
          </div>
          <button
            className="btn-primario"
            onClick={() => {
              window.location.href = '/ache-seu-pintor';
            }}
          >
            ACESSAR
          </button>
        </div>
      </Row>
      <Row
        className="mb-5"
        style={{
          borderBottom: '2px solid #4a76b9',
          paddingBottom: '1rem',
          boxShadow: '0px 0px 5px 2px rgba(0, 0, 0, 0.15)',
          borderRadius: '10px',
        }}
      >
        <SliderCursos />
        <div
          className="d-flex justify-content-between align-content-center flex-column flex-md-row"
          style={{
            width: '100%',
            padding: '1rem',
          }}
        >
          <div>
            <h2 className="fw-bold">SW Pro Cursos</h2>
            <p>
              Cursos de profissionalização, empreendedorismo, marketing e
              estratégia.
            </p>
          </div>
          <button
            className="btn-primario"
            onClick={() => {
              window.location.href = '/cursos';
            }}
          >
            ACESSAR
          </button>
        </div>
      </Row>
      <Row
        className="mb-5"
        style={{
          borderBottom: '2px solid #4a76b9',
          paddingBottom: '1rem',
          boxShadow: '0px 0px 5px 2px rgba(0, 0, 0, 0.15)',
          borderRadius: '10px',
        }}
      >
        <SliderParcela />

        <div
          className="d-flex justify-content-between align-content-center flex-column flex-md-row"
          style={{
            width: '100%',
            padding: '1rem',
          }}
        >
          <div>
            <h2 className="fw-bolder">PARCELA PINTURA</h2>
            <span style={{ marginBottom: 'none !important' }}>
              Facilidade de negócio para profissionais da área de pintura e seus
              consumidores
            </span>
          </div>

          <button
            className="btn-primario"
            onClick={() => {
              const element = document.getElementById('link-parcela');
              element.click();
            }}
          >
            ACESSAR
          </button>
          <a
            hidden
            id="link-parcela"
            target="_blank"
            href="http://www.parcelapintura.com.br/"
            style={{ width: '100%' }}
          />
        </div>
      </Row>
      <Row
        className="mb-5"
        style={{
          borderBottom: '2px solid #4a76b9',
          paddingBottom: '1rem',
          boxShadow: '0px 0px 5px 2px rgba(0, 0, 0, 0.15)',
          borderRadius: '10px',
        }}
      >
        <SliderResgate />
        <div
          className="d-flex justify-content-between align-content-center flex-column flex-md-row"
          style={{ width: '100%', padding: '1rem' }}
        >
          <div>
            <h2 className="fw-bold">Resgate seus Pontos</h2>
            <p>Resgate seus pontos e troque por produtos incríveis</p>
          </div>
          <div className="d-flex flex-md-row flex-column" style={{ gap: 4 }}>
            <button
              className="btn-primario"
              onClick={() => {
                window.location.href = '/loja';
              }}
            >
              RESGATAR
            </button>
            <button
              className="btn-primario"
              onClick={() => {
                window.location.href = '/enviar-nf';
              }}
            >
              Enviar Nota Fiscal
            </button>
          </div>
        </div>
      </Row>
    </Container>
  );
}
