/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import api from '../../../services/API';
import { toast } from 'react-toastify';
import './styles.css';
import { atualizarSaldo } from '../../../store/modules/parceiro/actions';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import moment from 'moment-timezone';

const columns = [
  {
    text: 'Código Promocional',
    dataField: 'codigoPromocional',
    formatter: (celula, valor) => {
      if (valor) {
        return valor.operacao === 'ADICIONAR'
          ? `${valor.codigo} +${Number(valor.valor).toLocaleString('pt-BR')}`
          : `${valor.codigo} x${Number(valor.valor).toLocaleString('pt-BR')}`;
      } else {
        return '';
      }
    },
    sort: true,
  },
  {
    text: 'Utilizado em',
    dataField: 'utilizadoEm',
    formatter: (celula, valor) => {
      return valor.utilizadoEm
        ? moment(valor.utilizadoEm).format('DD/MM/YYYY HH:mm:ss')
        : '';
    },
    sort: true,
  },
];

export default function Perfil() {
  const [buscando, setBuscando] = useState(false);
  const [data, setData] = useState(false);
  const dispatch = useDispatch();

  const parceiro = useSelector((state) => {
    return state.parceiro;
  });

  const validarCodigo = async () => {
    if (codigoPromocional) {
      const resultado = await api.post(`/api/codigo-promocional/validar`, {
        codigo: codigoPromocional,
      });

      if (resultado && resultado.data && resultado.data.score) {
        toast.success(`Código de treinamento resgatado com sucesso!`, {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 10000,
        });
        dispatch(atualizarSaldo(Number(resultado.data.score)));
      }
    }
  };

  useEffect(() => {
    buscarCodigos();
  }, []);

  const buscarCodigos = async () => {
    setBuscando(true);
    const filtros = JSON.stringify({
      treinamento: true,
      cpf: parceiro.cpf,
    });
    const resultado = await api.get(
      `/api/codigo-promocional?filtros=${filtros}`
    );
    if (resultado) {
      setData(resultado.data.codigosPromocionais);
    }
    setBuscando(false);
  };
  const [codigoPromocional, setCodigoPromocional] = useState('');

  const [cpf] = useState(parceiro.cpf);

  return (
    <Container className="mt-5 mb-5">
      <div className="mt-5">
        <h5>TREINAMENTOS</h5>
        <hr className="hr-loja mt-0" />
      </div>
      <Row>
        <Col xs={12} className="d-flex flex-column mb-3">
          <label htmlFor="codigoPromocional">
            Insira seu código de treinamento
          </label>
          <div className="d-flex flex-row align-items-center">
            <input
              className="input-theme"
              type="text"
              id="codigoPromocional"
              name="codigoPromocional"
              value={codigoPromocional}
              onChange={(e) => setCodigoPromocional(e.target.value)}
              style={{ maxWidth: '330px', width: '100%' }}
            />
            <button
              className="btn-busca m-0 ml-2"
              type="submit"
              onClick={validarCodigo}
            >
              <i className="fas fa-arrow-right"></i>
            </button>
          </div>
        </Col>
      </Row>
      <div>
        <BootstrapTable
          bootstrap4
          noDataIndication={() =>
            buscando ? (
              <span>
                Carregando
                <i className="fas fa-circle-notch fa-spin ml-3"></i>
              </span>
            ) : (
              <span>Sem resultados</span>
            )
          }
          className="tabela-fundo-claro"
          keyField="id"
          data={data}
          columns={columns}
          pagination={paginationFactory()}
          headerClasses="tabela-header-claro"
          bodyClasses="tabela-body-claro"
        />
      </div>
    </Container>
  );
}
