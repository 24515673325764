/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Container } from 'react-bootstrap';

import { Row, Col } from 'react-bootstrap';
import { Player } from 'video-react';

import api from '../../../services/API';
import './styles.css';
import CursoAvaliacao from './components/CursoAvaliacao';
import { isEmpty } from 'lodash';

export default function CursoAssistir() {
  const [curso, setCurso] = useState({});
  const [aula, setAula] = useState(null);
  const [url, setUrl] = useState(null);
  const [avaliacao, setAvaliacao] = useState(null);

  const [buscando, setBuscando] = useState(true);
  const { idCurso } = useParams();

  async function buscarCurso() {
    setBuscando(true);
    try {
      const resultado = await api.get(`/api/cursos/parceiro/curso/${idCurso}`);

      if (resultado) {
        setCurso(resultado.data);
        setAula(resultado?.data?.Modulos[0]?.Aulas[0] || {});
      }
    } catch (error) {}
    setBuscando(false);
  }

  useEffect(() => {
    document.title = 'Clube Pro Pintor - Curso';

    buscarCurso();
  }, []);

  useEffect(() => {
    if (aula) {
      setBuscando(true);
      setUrl(null);
      setTimeout(() => {
        setUrl(aula?.linkVideo);
        setBuscando(false);
      }, 500);
    }
  }, [aula]);

  const onAula = (a) => {
    setAula(a);
  };

  const onFinalizar = async () => {
    setBuscando(true);
    try {
      const resultado = await api.post(
        `/api/cursos/parceiro/aula/${aula?.id}/concluir`,
        {}
      );

      if (resultado) {
        buscarCurso();
      }
    } catch (error) {}
    setBuscando(false);
  };

  const onAvaliacao = (e) => {
    setAvaliacao(e[0]);
  };

  const onClearAvaliacao = () => {
    setAvaliacao(null);
    buscarCurso();
  };

  const limitText = (t) => {
    if (typeof t !== 'string') return '';

    if (t.length > 50) {
      t = t.slice(0, 50);
      return `${t} ...`;
    }
    return t;
  };

  return (
    <Container className="mb-5 ">
      {buscando && (
        <div
          style={{
            height: '100vh',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div class="spinner-grow text-secondary " s role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      )}
      {!buscando && !avaliacao && (
        <>
          <Row className="p-2">
            <Col
              xs={12}
              md={8}
              className="align-items-center justify-content-center mt-4"
            >
              {url && (
                <Player
                  fluid={false}
                  width={'100%'}
                  id={url}
                  height={500}
                  src={url}
                />
              )}
              {!url && (
                <div
                  style={{ height: '500px' }}
                  className="d-flex align-items-center justify-content-center "
                >
                  <h3 className="mt-3">
                    <strong>
                      Aula indisponível, por favor termine a anterior
                    </strong>
                  </h3>
                </div>
              )}
              <div className="d-flex justify-content-between mt-3">
                <h3>
                  <strong>{aula?.nome}</strong>
                </h3>
                <a
                  className="px-3 mt-0"
                  role="button"
                  onClick={() => window.open(`/cursos`, '_self')}
                >
                  voltar ao painel de cursos{' '}
                </a>
              </div>

              <div className="mt-2 d-flex align-items-center">
                {!curso?.CertificadoConclusaoCurso && (
                  <>
                    {!isEmpty(aula.Avaliacoes) ? (
                      <button
                        className="btn-primario px-3 mt-0"
                        onClick={() => onAvaliacao(aula.Avaliacoes)}
                      >
                        Fazer Avaliação{' '}
                      </button>
                    ) : (
                      <button
                        className="btn-primario px-3 mt-0"
                        onClick={onFinalizar}
                      >
                        Finalizar Aula{' '}
                      </button>
                    )}
                  </>
                )}
                {curso?.CertificadoConclusaoCurso && (
                  <>
                    <button
                      className=" btn-inscreva-se px-3 mx-2"
                      onClick={() =>
                        window.open(
                          curso?.CertificadoConclusaoCurso?.link,
                          '_blank'
                        )
                      }
                    >
                      <i
                        class="fa fa-file-download"
                        style={{
                          color: '#fff',
                          fontSize: '16px',
                          marginRight: '0.5rem',
                        }}
                      />
                      Baixar Certificado
                    </button>
                  </>
                )}
              </div>
              <div xs={12} className="mt-4">
                <hr className="hr-curso mt-0 mb-2" />{' '}
              </div>
              <div>
                <h3 className="mt-4">
                  <strong> Descrição da Aula</strong>
                </h3>
                <p>{aula?.descricao}</p>
              </div>
              <div xs={12}>
                <h3 className="mt-4">
                  <strong> Materiais de apoio</strong>
                </h3>
                <div className="d-flex ">
                  <ul className="mx-4">
                    {aula?.Materiais.map((ma) => (
                      <li
                        className="mx-1"
                        style={{ textAlign: 'start', fontSize: '18px' }}
                        role="button"
                        onClick={() => window.open(ma.link, '_blank')}
                      >
                        {ma.nome}

                        <i
                          class="fa fa-file-download"
                          style={{
                            color: '#212529',
                            fontSize: '18px',
                            marginBottom: '10px',
                            marginLeft: '0.5rem',
                          }}
                        ></i>
                        {/* <br />
                      {ma.descricao} */}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </Col>
            <Col xs={12} md={4} className="mt-4 p-2">
              {curso?.Modulos?.map((i) => (
                <div className="mt-2">
                  <p>
                    <strong>{i.nome}</strong>
                  </p>

                  {i.Aulas.map((a) => (
                    <Row
                      role="button"
                      onClick={onAula.bind(this, a)}
                      className="mt-3 ml-2"
                    >
                      <Col xs={12} md={3}>
                        <img
                          src={curso?.linkCapaCurso}
                          style={{
                            width: '80px',
                            height: '80px',
                            borderRadius: '1rem',
                            curso: 'pointer',
                          }}
                          alt=""
                        />
                      </Col>
                      <Col xs={12} md={9}>
                        <p className="m-0">
                          <strong>
                            {a.concluida && (
                              <>
                                <i
                                  class="far fa-check-circle"
                                  style={{ color: '#00aa3e' }}
                                >
                                  {' '}
                                  concluido
                                </i>
                                <br />
                              </>
                            )}
                            {a.nome}
                          </strong>
                        </p>
                        <p className="m-1">{limitText(a.descricao)}</p>
                      </Col>
                    </Row>
                  ))}
                </div>
              ))}
            </Col>{' '}
          </Row>
        </>
      )}

      {!buscando && avaliacao && (
        <CursoAvaliacao
          avaliacao={avaliacao}
          onBack={onClearAvaliacao}
          aulaId={aula.id}
        />
      )}
    </Container>
  );
}
