/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { Player } from 'video-react';

import api from '../../../../services/API';

export default function CursoEstudar({ curso, buscarCurso }) {
  const [buscando, setBuscando] = useState(false);
  const { idCurso } = useParams();

  async function desinscrever() {
    setBuscando(true);
    try {
      const resultado = await api.post(
        `/api//cursos/parceiro/curso/${idCurso}/desinscrever`
      );

      if (resultado) {
        buscarCurso();
      }
    } catch (error) {}
    setBuscando(false);
  }

  async function onAssistir() {
    window.open(`/cursos/${idCurso}/assistir`, '_self');
  }

  return (
    <Row className="p-2">
      <Col
        xs={12}
        md={6}
        className="d-flex align-items-start justify-content-start mt-4"
      >
        {/* <Player
          fluid={false}
          width={600}
          height={350}
          poster={curso?.linkCapaCurso}
          src={curso?.linkVideo}
        /> */}

        <img
          style={{
            maxWidth: '100%',
            maxHeight: 350,
            // height: '100%',
          }}
          src={curso?.linkCapaCurso}
          alt=""
        />
      </Col>
      <Col xs={12} md={6}>
        <div className="d-flex align-items-center mt-4">
          <div
            className="d-flex justify-content-center"
            style={{ width: '40px' }}
          >
            <i
              className="fa fa-regular fa-clock "
              style={{ fontSize: '38px' }}
            />{' '}
          </div>

          <div className="ml-3">
            <h5 className="m-0">
              <strong>Carga Horaria</strong>
            </h5>
            <p className="m-0">{curso?.cargaHoraria} minutos</p>
          </div>
        </div>
        {/* <div className="d-flex align-items-center mt-4">
          <div
            className="d-flex justify-content-center"
            style={{ width: '40px' }}
          >
            <i className="fa  fa-dollar-sign" style={{ fontSize: '38px' }} />{' '}
          </div>

          <div className="ml-3">
            <h5 className="m-0">
              <strong>Investimento</strong>
            </h5>
            <p className="m-0">
              {!curso?.preco || curso?.preco === 0 ? 'Gratuito' : curso?.preco}
            </p>
          </div>
        </div> */}
        <div className="d-flex align-items-center mt-4">
          <div
            className="d-flex justify-content-center"
            style={{ width: '40px' }}
          >
            <i
              className="fa fa-chalkboard-teacher"
              style={{ fontSize: '38px' }}
            />{' '}
          </div>

          <div className="ml-3">
            <h5 className="m-0">
              <strong>Módulos</strong>
            </h5>
            <p className="m-0">{curso?.Modulos?.length} módulos</p>
          </div>
        </div>
        {curso?.certificado && (
          <div className="d-flex align-items-center mt-4">
            <div
              className="d-flex justify-content-center"
              style={{ width: '40px' }}
            >
              <i className="fa  fa-trophy" style={{ fontSize: '38px' }} />{' '}
            </div>

            <div className="ml-3">
              <h5 className="m-0">
                <strong>Possui Certificado</strong>
              </h5>
            </div>
          </div>
        )}
      </Col>{' '}
      <Col xs={12} className="mt-4">
        <h3 className="mt-4" style={{ textTransform: 'uppercase' }}>
          <strong>{curso?.nome}</strong>
        </h3>
      </Col>
      <Col xs={12} className="d-flex align-items-center mt-3 ">
        <Row style={{ gap: '0.3rem' }}>
          <Col xs={12} sm={12} md className="d-flex align-items-center ">
            <button className="btn-primario px-4 mt-0" onClick={onAssistir}>
              Assistir Aula
            </button>
          </Col>
          {curso?.CertificadoConclusaoCurso && (
            <Col xs={12} sm={12} md className="d-flex align-items-center ">
              <button
                className=" btn-inscreva-se "
                onClick={() =>
                  window.open(curso?.CertificadoConclusaoCurso?.link, '_blank')
                }
              >
                <i
                  class="fa fa-file-download"
                  style={{
                    color: '#fff',
                    fontSize: '16px',
                    marginRight: '0.5rem',
                  }}
                />
                Baixar Certificado
              </button>
            </Col>
          )}
          <Col xs={12} sm>
            <button className=" btn-inscreva-se " onClick={desinscrever}>
              Cancelar curso{' '}
            </button>
          </Col>
        </Row>
      </Col>{' '}
      <Col xs={6} className="mt-4">
        <hr className="hr-curso mt-0 mb-2" />{' '}
      </Col>
      <Col xs={12}>
        <h3 className="mt-4">
          <strong> Descrição do curso</strong>
        </h3>
        <p>{curso?.descricao}</p>
      </Col>
    </Row>
  );
}
