/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Container } from 'react-bootstrap';
import api from '../../../services/API';
import './styles.css';
import sortFunc from '../../../utils/ordernarColunaInt';
import moment from 'moment-timezone';
import { useSelector } from 'react-redux';
import ModalMidia from '../ModalMidia';

const formatarPontos = (celula, valor) => {
  return Number(valor.totalPontos).toLocaleString('pt-BR');
};

const formatarDataValidade = (celula, valor) => {
  return moment(valor.venceEm).format('DD/MM/YYYY HH:mm:ss');
};

const formatarData = (celula, valor) => {
  return moment(valor.pontuadoEm).format('DD/MM/YYYY HH:mm:ss');
};

const formatarDataEnvio = (celular, valor) => {
  return moment(valor.dataEnvio).format('DD/MM/YYYY HH:mm:ss');
};

const columns = [
  {
    dataField: 'totalPontos',
    text: 'Pontos',
    formatter: formatarPontos,
    sortFunc: sortFunc,
    sort: true,
  },
  {
    dataField: 'identificador',
    text: 'Número Nota / Cupom',
    sort: true,
  },
  {
    dataField: 'pontuadoEm',
    text: 'Data da Pontuação',
    formatter: formatarData,
    sort: true,
  },
  {
    dataField: 'validadePontos',
    text: 'Vencimento da Pontuação',
    formatter: formatarDataValidade,
  },
  {
    text: 'Código Promocional',
    dataField: 'codigo',
    formatter: (celula, valor) => {
      if (Number(valor.pontosDobrados) === 1) return 'REVENDAMASTER';
      return valor.codigo;
    },
    sort: true,
  },
];

const columnsTreinamentos = [
  {
    dataField: 'valor',
    text: 'Pontos',
    sortFunc: sortFunc,
    sort: true,
  },
  {
    text: 'Data da Pontuação',
    dataField: 'utilizadoEm',
    formatter: (celula, valor) => {
      return valor.utilizadoEm
        ? moment(valor.utilizadoEm).format('DD/MM/YYYY HH:mm:ss')
        : '';
    },
    sort: true,
  },
  {
    text: 'Código Promocional',
    dataField: 'codigoPromocional',
    formatter: (celula, valor) => {
      if (valor) {
        return valor.operacao === 'ADICIONAR'
          ? `${valor.codigo} +${Number(valor.valor).toLocaleString('pt-BR')}`
          : `${valor.codigo} x${Number(valor.valor).toLocaleString('pt-BR')}`;
      } else {
        return '';
      }
    },
    sort: true,
  },
];

const defaultSorted = [
  {
    dataField: 'pontuadoEm',
    order: 'desc',
  },
];

export default function Pontuacoes() {
  const [buscando, setBuscando] = useState(false);
  const [arquivo, setArquivo] = useState([]);
  const [tipo, setTipo] = useState('');
  const [mostrarModalFoto, setMostrarModalFoto] = useState(false);
  const parceiro = useSelector((state) => {
    return state.parceiro;
  });

  const columnsNaoProcessadas = [
    {
      dataField: 'dataEnvio',
      text: 'Data de Envio',
      formatter: formatarDataEnvio,
      sortFunc: sortFunc,
      sort: true,
    },
    {
      dataField: 'status',
      text: 'Status',
      sort: true,
    },
    {
      dataField: 'reprovadoMotivo',
      text: 'Observação',
      sort: true,
    },
    {
      text: 'Foto',
      dataField: 'dirFoto',
      formatter: (celula, valor) =>
        valor.diretorioArquivo ? (
          <button
            className="btn-limpo f-12 p-1"
            onClick={() => {
              visualizarModalFoto(valor.diretorioArquivo, valor.tipo);
            }}
          >
            <i className="fas fa-image"></i>
          </button>
        ) : (
          <button className="btn-limpo f-12 p-1" disabled>
            SEM FOTO
          </button>
        ),
    },
  ];

  const visualizarModalFoto = (dirFoto, tipo) => {
    if (dirFoto.endsWith('.pdf')) {
      window.open(`${process.env.REACT_APP_API_BASE_URL}/arquivos/${dirFoto}`);
    } else {
      setArquivo(dirFoto);
      setTipo(tipo);
      setMostrarModalFoto(true);
    }
  };

  useEffect(() => {
    buscarPontuacoes();
    buscarCodigos();
    buscarNaoProcessadas();
  }, []);

  const buscarPontuacoes = async () => {
    setBuscando(true);
    const resultado = await api.get('/api/pontuacoes');
    if (resultado) {
      setData(resultado.data.pontuacoes);
    }
    setBuscando(false);
  };

  const buscarNaoProcessadas = async () => {
    setBuscando(true);
    const resultado = await api.get('/api/pontuacoes/nao-processadas');

    if (resultado) {
      setDataNaoProcessadas(resultado.data.naoProcessadas);
    }
    setBuscando(false);
  };

  const [data, setData] = useState([]);
  const [dataTreinamentos, setDataTreinamentos] = useState([]);
  const [dataNaoProcessadas, setDataNaoProcessadas] = useState([]);

  const buscarCodigos = async () => {
    const filtros = JSON.stringify({
      treinamento: true,
      cpf: parceiro.cpf,
    });
    const resultado = await api.get(
      `/api/codigo-promocional?filtros=${filtros}`
    );
    if (resultado) {
      setDataTreinamentos(resultado.data.codigosPromocionais);
    }
  };

  return (
    <Container className="mt-5 mb-5">
      <div>
        <h5>PONTUAÇÕES</h5>
        <hr className="hr-loja mt-0" />
      </div>
      <div>
        <BootstrapTable
          bootstrap4
          noDataIndication={() =>
            buscando ? (
              <span>
                Carregando
                <i className="fas fa-circle-notch fa-spin ml-3"></i>
              </span>
            ) : (
              <span>Sem resultados</span>
            )
          }
          className="tabela-fundo-claro"
          keyField="id"
          data={data}
          columns={columns}
          pagination={paginationFactory()}
          defaultSorted={defaultSorted}
          headerClasses="tabela-header-claro"
          bodyClasses="tabela-body-claro"
        />
      </div>
      <div>
        <h5>NOTAS NÃO PROCESSADAS</h5>
        <hr className="hr-loja mt-0" />
      </div>
      <div>
        <BootstrapTable
          bootstrap4
          noDataIndication={() =>
            buscando ? (
              <span>
                Carregando
                <i className="fas fa-circle-notch fa-spin ml-3"></i>
              </span>
            ) : (
              <span>Sem resultados</span>
            )
          }
          className="tabela-fundo-claro"
          keyField="id"
          data={dataNaoProcessadas}
          columns={columnsNaoProcessadas}
          pagination={paginationFactory()}
          defaultSorted={defaultSorted}
          headerClasses="tabela-header-claro"
          bodyClasses="tabela-body-claro"
        />
      </div>
      <div className="mt-5">
        <h5>TREINAMENTOS</h5>
        <hr className="hr-loja mt-0" />
      </div>
      <BootstrapTable
        bootstrap4
        noDataIndication={() =>
          buscando ? (
            <span>
              Carregando
              <i className="fas fa-circle-notch fa-spin ml-3"></i>
            </span>
          ) : (
            <span>Sem resultados</span>
          )
        }
        className="tabela-fundo-claro"
        keyField="id"
        data={dataTreinamentos}
        columns={columnsTreinamentos}
        pagination={paginationFactory()}
        headerClasses="tabela-header-claro"
        bodyClasses="tabela-body-claro"
      />

      <ModalMidia
        show={mostrarModalFoto}
        onHide={() => setMostrarModalFoto(false)}
        diretorioArquivo={arquivo}
        tipo={tipo}
      />
    </Container>
  );
}
