import React, { useState, useEffect } from 'react';
import api from '../../../services/API';
import { Container } from 'react-bootstrap';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import historico from '../../../services/Historico';
import sortFunc from '../../../utils/ordernarColunaInt';
import moment from 'moment-timezone';
import RemoteTable from '../RemoteTable/index';
import capitalize from '../../../utils/capitalize';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';

export default function Indicacoes() {
  const columns = [
    {
      text: 'Pintor Indicador',
      dataField: 'Pintor Indicador',
      formatter: (celula, valor) => {
        if (valor) {
          return valor.nomeIndicador;
        }
      },
      sort: true,
    },
    {
      text: 'CPF Pintor Indicador',
      dataField: 'CPF Pintor Indicador',
      formatter: (celula, valor) => {
        return valor.cpfIndicador.replace(
          /(\d{3})(\d{3})(\d{3})(\d{2})/,
          '$1.$2.$3-$4'
        );
      },
      sort: false,
    },
    {
      text: 'Código de Indicação',
      dataField: 'Código de Indicação',
      formatter: (celula, valor) => {
        return valor.idIndicacaoIndicador;
      },
      sort: false,
    },
    {
      text: 'Pintor Indicado',
      dataField: 'Pintor Indicado',
      formatter: (celula, valor) => {
        if (valor) {
          return valor.nomeIndicado;
        }
      },
      sort: true,
    },
    {
      text: 'CPF Pintor Indicado',
      dataField: 'CPF Pintor Indicado',
      formatter: (celula, valor) => {
        return valor.cpfIndicado.replace(
          /(\d{3})(\d{3})(\d{3})(\d{2})/,
          '$1.$2.$3-$4'
        );
      },
      sort: false,
    },
    {
      text: 'Indicação Válida',
      dataField: 'Indicação Válida',
      formatter: (celula, valor) => {
        return (valor.validado && 'Sim') || 'Não';
      },
      sort: true,
    },
  ];

  const [buscando, setBuscando] = useState(false);
  const [data, setData] = useState([]);
  const [dataFiltrado, setDataFiltrado] = useState([]);
  const [filtro, setFiltro] = useState('');

  const getIndicacoes = async () => {
    setBuscando(true);
    // Pode ser um GET com query
    const resultado = await api.post(
      `/api/indique-ganhe/indicacoes/painel`,
      {}
    );

    // console.log(resultado && resultado.data);

    setBuscando(false);
    if (resultado && resultado.data) {
      setData(resultado.data);
      setDataFiltrado(resultado.data);
    }
    return;
  };

  const filtrar = async () => {
    setBuscando(true);
    //const filtro = "539";
    if (data && data.length) {
      setDataFiltrado([]);
      let filtrado = [];
      for (let index = 0; index < data.length; index++) {
        if (
          data[index].cpfIndicado.includes(filtro) ||
          data[index].cpfIndicador.includes(filtro)
        ) {
          filtrado.push(data[index]);
        }
      }
      setDataFiltrado(filtrado);
    }
    setBuscando(false);
  };

  const handleEnterKey = (e) => {
    if (e.key === 'Enter') {
      filtrar();
    }
  };

  useEffect(() => {
    getIndicacoes();
  }, []);

  return (
    <Container fluid>
      <h4 className="pt-3 pb-3 f-black">Indicações</h4>
      <hr className="hr-loja mt-0 mb-4" />
      <div className="d-flex flex-row-reverse justify-content-right align-items-center mb-2">
        <div className="d-flex justify-content-center align-items-center">
          <input
            className="input-theme busca mr-1"
            //ref={(n) => (input = n)}
            type="text"
            onChange={(e) => setFiltro(e.target.value)}
            onKeyUp={handleEnterKey}
          />
          <button
            className="btn-primario-slim"
            onClick={filtrar}
            style={{ width: 40, height: 40 }}
          >
            <i className="fa fa-search px-2"></i>
          </button>
        </div>
      </div>
      <div>
        <BootstrapTable
          bootstrap4
          noDataIndication={() =>
            buscando ? (
              <span>
                Carregando
                <i className="fas fa-circle-notch fa-spin ml-3"></i>
              </span>
            ) : (
              <span>Sem resultados</span>
            )
          }
          className="tabela-fundo-claro"
          keyField="id"
          data={dataFiltrado}
          columns={columns}
          pagination={paginationFactory()}
          headerClasses="tabela-header-claro"
          bodyClasses="tabela-body-claro"
        />
      </div>
    </Container>
  );
}
