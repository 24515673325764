import React, { useEffect, useState } from 'react';
import { Container, Row } from 'react-bootstrap';
import './styles.css';
import { useDispatch, useSelector } from 'react-redux';
import { autenticarParceiro } from '../../../store/modules/autenticacao/actions';
import Footer from '../../../components/Loja/Footer';
import Header from '../../../components/Loja/Header';
import { Link } from 'react-router-dom';
import InputMask from 'react-input-mask';

import FacebookLogin from 'react-facebook-login';
import { autenticarParceiroSocial } from '../../../store/modules/autenticacao/actions';
import { GoogleOAuthProvider } from '@react-oauth/google';
import GoogleLogin from '../../../components/Loja/GoogleLogin';


export default function Login() {
  const dispatch = useDispatch();
  const [cpf, setCpf] = useState();
  const [senha, setSenha] = useState();
  const enviando = useSelector((state) => state.autenticacao.autenticandoLoja);

  useEffect(() => {
    document.title = 'Clube Pro Pintor - Login';
  }, []);

  const responseFacebook = (e) => {
    console.log(`responseFacebook`, e);
    dispatch(autenticarParceiroSocial('facebookToken', e.accessToken));
  }

  const responseGoogle = (e) => {
    console.log(`googleAuthentication`, e, {index: 'googleOauth',social_token: e.credential});
    dispatch(autenticarParceiroSocial('googleOauth',e.credential));
  }

  const responseGoogleError = (e) => {
    console.log(`googleAuthentication`, e);
  }

  const solicitarAutenticacao = (e) => {
    e.preventDefault();
    const cpfLimpo = cpf.replace(/[^\d]/g, '');
    dispatch(autenticarParceiro(cpfLimpo, senha));
  };

  const validarCaractereCpf = (e) => {
    const theEvent = e;
    let key = null;
    if (theEvent.type === 'paste') {
      key = window.event.clipboardData.getData('text/plain');
    } else {
      key = theEvent.keyCode || theEvent.which;
      key = String.fromCharCode(key);
    }
    const regex = /[0-9]/;
    if (!regex.test(key)) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) theEvent.preventDefault();
    }
  };

  return (
    <>
      <Header auto />
      <Container className="d-flex flex-column justify-content-center">
        <Row className="justify-content-center mt-5 mb-5">
          <form
            onSubmit={solicitarAutenticacao}
            className="form-login form-login-loja"
          >
            <div style={{paddingBottom: '14px'}}>
              <h5>FAÇA SEU LOGIN</h5>
              <label>Entre com seus dados e acesse sua conta</label>
            </div>

            {/* Facebook Login Button
            <FacebookLogin
              appId="571176977891327"
              fields="name,email,picture"
              icon="fa-facebook"
              textButton=" Login com Facebook"
              cssClass="btn-primario btn-primario2"
              callback={responseFacebook}
            />
            */}

            {/* Google Login Button 
            <div id="googlebtn" style={{paddingTop: '16px', width: '100%'}}>
              <GoogleOAuthProvider clientId="425931863053-i3tf8n6mrujvl5slcs7pr4ql48po0eg8.apps.googleusercontent.com">
                <GoogleLogin></GoogleLogin>
              </GoogleOAuthProvider>
            </div>

            <p style={{paddingBottom: '16px', paddingTop: '16px', marginBottom: '0', textAlign: 'center'}}>ou</p>
            */}


            <label htmlFor="cpf">CPF (Digite apenas números)</label>
            <InputMask
              id="cpf"
              name="cpf"
              type="text"
              placeholder="CPF"
              value={cpf}
              mask="999.999.999-99"
              onChange={(e) => setCpf(e.target.value)}
              onKeyPress={validarCaractereCpf}
              autoComplete="nofill"
            ></InputMask>
            <label htmlFor="senha">Senha</label>
            <input
              name="senha"
              type="password"
              placeholder="Senha"
              value={senha}
              onChange={(e) => setSenha(e.target.value)}
              autoComplete="nofill"
            ></input>
            {enviando ? (
              <button disabled className="btn-primario" type="submit">
                VALIDANDO...
              </button>
            ) : (
              <button className="btn-primario" type="submit">
                ENTRAR
              </button>
            )}
            <Link
              className="mb-3 mt-3 text-center link-clean"
              to="/esqueceu-senha"
            >
              Esqueceu sua senha?
            </Link>

          </form>

        </Row>
      </Container>
      <Footer />
    </>
  );
}
