import React from 'react';
import RotaRelatorio from './RotaRelatorio';

import Login from '../../pages/Relatorio/Login';
import Home from '../../pages/Relatorio/Home';

const rotas = [
  {
    path: '/relatorio',
    exact: true,
    component: Login,
    rotaPrivada: false,
  },
  {
    path: '/relatorio',
    component: Home,
    rotaPrivada: true,
  },
];

const rotasRelatorio = rotas.map((rota, index) => (
  <RotaRelatorio
    key={index}
    path={rota.path}
    exact={rota.exact}
    component={rota.component}
    rotaPrivada={rota.rotaPrivada}
  ></RotaRelatorio>
));

export default rotasRelatorio;
