export function atualizarPerfil(data) {
  return {
    type: '@parceiro/ATUALIZAR_PERFIL',
    payload: data,
  };
}

export function atualizarPerfilSucesso(data) {
  return {
    type: '@parceiro/ATUALIZAR_PERFIL_SUCESSO',
    payload: data,
  };
}

export function atualizarPerfilFalhou() {
  return {
    type: '@parceiro/ATUALIZAR_PERFIL_FALHOU',
  };
}

export function resgatarPremio(data) {
  return {
    type: '@parceiro/RESGATAR_PREMIO',
    payload: data,
  };
}
export function getParceiro(data) {
  return {
    type: '@parceiro/GET_PARCEIRO',
    payload: data,
  };
}

export function resgatarPremioSucesso(data) {
  return {
    type: '@parceiro/RESGATAR_PREMIO_SUCESSO',
    payload: data,
  };
}

export function resgatarPremioFalhou() {
  return {
    type: '@parceiro/RESGATAR_PREMIO_FALHOU',
  };
}

export function aceitarRegulamento() {
  return {
    type: '@parceiro/ACEITAR_REGULAMENTO',
  };
}

export function aceitarPrivacidade() {
  return {
    type: '@parceiro/ACEITAR_PRIVACIDADE',
  };
}

export function alterarStatusCartao(status, emDesbloqueio) {
  return {
    type: '@parceiro/ALTERAR_STATUS_CARTAO',
    payload: { status, emDesbloqueio },
  };
}

export function buscarDados() {
  return {
    type: '@parceiro/BUSCAR_DADOS',
  };
}

export function atualizarSaldo(saldo) {
  return {
    type: '@parceiro/ATUALIZAR_SALDO',
    payload: { saldo },
  };
}

export function atualizarPerfilFoto(data) {
  return {
    type: '@parceiro/ATUALIZAR_PERFIL_FOTO',
    payload: data,
  };
}
