import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';

import api from '../../../../services/API';

export default function AddCurso({ onClose }) {
  const [buscando, setBuscando] = useState(false);

  const onSubmit = async (e) => {
    e.preventDefault();
    setBuscando(true);

    const formData = new FormData(e.target);

    for (var pair of formData.entries()) {
      if (pair[0] === 'certificado') {
        formData.set('certificado', '1');
      }
      if (pair[0] === 'avaliacao') {
        formData.set('avaliacao', '1');
      }
    }

    const { data: res } = await api.post(`/api/cursos/curso/`, formData);

    if (res) {
      toast.success('Curso cadastrado com sucesso.');
      onClose();
    }

    setBuscando(false);
  };

  return (
    <div className="d-flex flex-column align-items-center">
      <form className="form" onSubmit={onSubmit}>
        <Row>
          <Col className="d-flex flex-column" xs={12}>
            <label htmlFor="nome">Nome</label>
            <input id="nome" name="nome" type="text" placeholder="Nome"></input>
          </Col>
          <Col className="d-flex flex-column" xs={12} sm={6}>
            <label htmlFor="categoria">Categoria</label>
            <input
              id="categoria"
              name="categoria"
              type="text"
              placeholder="Categoria"
            ></input>
          </Col>{' '}
          <Col className="d-flex flex-column" xs={12} sm={6}>
            <label htmlFor="cargaHoraria">Carga Horaria</label>
            <input
              id="cargaHoraria"
              name="cargaHoraria"
              type="number"
              step="any"
              placeholder="Carga Horaria"
            ></input>
          </Col>
          <Col className="d-flex flex-column" xs={12} sm={6}>
            <label htmlFor="linkVideo">Link Video</label>
            <input
              id="linkVideo"
              name="linkVideo"
              type="file"
              placeholder="Link Video"
            ></input>
          </Col>
          <Col className="d-flex flex-column" xs={12} sm={6}>
            <label htmlFor="linkCapaCurso">Link Capa</label>
            <input
              type="file"
              id="linkCapaCurso"
              name="linkCapaCurso"
              placeholder="Link Capa"
            />
          </Col>
          <Col className="d-flex flex-column" xs={12}>
            <label htmlFor="descricao">Descrição</label>
            <input
              id="descricao"
              name="descricao"
              type="text"
              placeholder="Descrição"
            ></input>
          </Col>
          <Col className="d-flex flex-column" xs={12} sm={6}>
            <label htmlFor="cargaHoraria">Preço</label>
            <input
              id="preco"
              name="preco"
              type="number"
              step="any"
              placeholder="Preço"
            ></input>
          </Col>
          <Col className="d-flex flex-column" xs={12} sm={6}>
            <div class="custom-control custom-switch mt-4">
              <input
                type="checkbox"
                class="custom-control-input"
                id="certificado"
                name="certificado"
              />
              <label class="custom-control-label" for="certificado">
                Certificados{' '}
              </label>
            </div>{' '}
            <div class="custom-control custom-switch">
              <input
                type="checkbox"
                class="custom-control-input"
                id="avaliacao"
                name="avaliacao"
              />
              <label class="custom-control-label" for="avaliacao">
                Avaliações{' '}
              </label>
            </div>
          </Col>
        </Row>

        {buscando ? (
          <button disabled className="btn-primario" type="submit">
            CADASTRANDO...
          </button>
        ) : (
          <button className="btn-primario" type="submit">
            CADASTRAR
          </button>
        )}
      </form>
    </div>
  );
}
