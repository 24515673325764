import React, { useState } from 'react';
import { Modal, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { desautenticarParceiro } from '../../../store/modules/autenticacao/actions';
import { atualizarPerfilFoto } from '../../../store/modules/parceiro/actions';

const ModalParceiroFoto = ({ foto, idParceiro, handleClose, ...props }) => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(!foto);
  const [arquivo, setArquivo] = useState(null);
  const [status, setStatus] = useState('Procurar...');
  const [enviando, setEnviando] = useState(false);

  const handleHide = () => {
    handleClose();
    setShowModal(false);
    dispatch(desautenticarParceiro());
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(atualizarPerfilFoto({ arquivo, idParceiro, setShowModal }));
  };

  return (
    <Modal
      {...props}
      show={showModal}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      id="modal-parceiro-foto"
      onHide={handleHide}
    >
      <Modal.Header closeButton>
        <Modal.Title>Envie uma foto sua</Modal.Title>
      </Modal.Header>
      <Modal.Body
        className="modal-body"
        style={{ maxHeight: '80vh', overflowY: 'auto' }}
      >
        <p>
          Para que possamos estar mais próximos e sua conta mais segura,
          adicione uma foto de perfil no seu cadastro. Este item é obrigatório
          para você continuar usando o Clube Pro Pintor.
        </p>
        <form onSubmit={handleSubmit} className="form">
          <input
            type="file"
            id="arquivo"
            name="arquivo"
            accept="image/*"
            style={{ display: 'none' }}
            onChange={(e) => {
              setArquivo(e.target.files);
              e.target.files.length > 0
                ? setStatus(`Foto selecionada`)
                : setStatus('Procurar...');
            }}
          />
          {arquivo && arquivo.length > 0 && (
            <img
              style={{ marginBottom: 16 }}
              src={URL.createObjectURL(arquivo[0])}
            />
          )}
          <input
            id="botao"
            type="button"
            value={status}
            onClick={(e) => {
              document.getElementById('arquivo').click();
            }}
          />
          <Row className="d-flex justify-content-center align-items-center">
            {enviando ? (
              <button disabled className="btn-enviar" type="submit">
                ENVIANDO...
              </button>
            ) : (
              <button className="btn-enviar" type="submit">
                ENVIAR
              </button>
            )}
          </Row>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default ModalParceiroFoto;
