import React, { useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import api from '../../../services/API';
import historico from '../../../services/Historico';
import ModalEncerrarPontuacao from '../ModalEncerrarPontuacao';
import moment from 'moment-timezone';
import RemoteTable from '../RemoteTable/index';
import capitalize from '../../../utils/capitalize';

export default function PontuacoesPendente() {
  const [mostrarModalEncerrarPontuacao, setMostrarModalEncerrarPontuacao] =
    useState(false);
  const [pontuacao, setPontuacao] = useState('');

  const columns = [
    {
      text: 'Pintor',
      dataField: 'nome',
      formatter: (celula, valor) => {
        return capitalize(valor.nome);
      },
      sort: true,
    },
    {
      text: 'CPF',
      dataField: 'cpf',
      formatter: (celula, valor) => {
        let cpf = valor.cpf.replace(/[^\d]/g, '');
        return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
      },
    },
    {
      text: 'Data do Envio',
      dataField: 'criadoEm',
      formatter: (celula, valor) => {
        return moment(valor.criadoEm).format('DD/MM/YYYY HH:mm:ss');
      },
      sort: true,
    },
    {
      text: 'Processar Pontuação',
      dataField: '',
      formatter: (celula, valor) => {
        return (
          <button
            className="btn-limpo"
            onClick={(e) =>
              processarPontuacao(
                valor.cpf,
                valor.diretorioArquivo,
                valor.codigoPromocional,
                valor.cpfCnpj,
                valor.loja,
                valor.uf,
                valor.cidade,
                valor.dataEmissao,
                valor.chave,
                valor['VendasNotum.idNota']
              )
            }
          >
            <i className="fas fa-check f-green"></i>
          </button>
        );
      },
    },
    {
      text: 'Encerrar Pontuação',
      dataField: 'id',
      formatter: (celula, valor) => {
        return (
          <button
            onClick={() => {
              visualizarModalEncerrarPontuacao(valor.id);
            }}
            className="btn-limpo"
          >
            <i className="fas fa-times f-red"></i>
          </button>
        );
      },
    },
  ];

  const processarPontuacao = (
    cpf,
    diretorioArquivo,
    codigoPromocional,
    cpfCnpj,
    loja,
    uf,
    cidade,
    dataEmissao,
    chave,
    idFoto
  ) => {
    historico.push('/painel/processar/nova-venda', {
      cpf,
      diretorioArquivo,
      codigoPromocional,
      cpfCnpj,
      loja,
      uf,
      cidade,
      dataEmissao,
      chave,
      tipoFiscal: 'N',
      idFoto,
    });
  };

  const visualizarModalEncerrarPontuacao = (id) => {
    setPontuacao(id);
    setMostrarModalEncerrarPontuacao(true);
  };

  const fecharModalEncerrarPontuacao = () => {
    setMostrarModalEncerrarPontuacao(false);
  };

  return (
    <Container fluid>
      <h4 className="pt-3 pb-3 f-black">Pontuações Pendentes</h4>
      <hr className="hr-loja mt-0 mb-4" />
      <div>
        <RemoteTable
          dataId="id"
          url="/api/pontuacoes/pendentes"
          exportar={{
            fileName: 'pontuacoes',
          }}
          colunas={columns}
          ordenacaoInicial={{ campo: 'criadoEm', direcao: 'desc' }}
        />
      </div>
      <ModalEncerrarPontuacao
        show={mostrarModalEncerrarPontuacao}
        onHide={fecharModalEncerrarPontuacao}
        pontuacao={pontuacao}
      />
    </Container>
  );
}
