import React, { useState, useEffect } from 'react';
import './styles.css';
import { Row, Col, Container, Table } from 'react-bootstrap';
import api from '../../../services/API';
import capitalize from '../../../utils/capitalize';

export default function Dashboard() {
  const [parceirosCadastrados, setParceirosCadastrados] = useState(0);
  const [lojasPontuadas, setLojasPontuadas] = useState(0);
  const [revendasCadastradas, setRevendasCadastradas] = useState(0);
  const [parceirosPontuados, setParceirosPontuados] = useState(0);
  const [revendasPontuadas, setRevendasPontuadas] = useState(0);
  const [topParceirosPontuados, setTopParceirosPontuados] = useState([]);
  const [topRevendasPontuadas, setTopRevendasPontuadas] = useState([]);
  const [topRevendasQueIndicam, setTopRevendasQueIndicam] = useState([]);
  const [topProdutosVendidos, setTopProdutosVendidos] = useState([]);
  const [qtdParceirosIndicados, setQtdParceirosIndicados] = useState([]);
  const [qtdParceirosIndicadosValidados, setQtdParceirosIndicadosValidados] = useState([]);
  const [buscando, setBuscando] = useState(false);

  const buscarDados = async () => {
    setBuscando(true);
    const resultado = await api.get(`/api/dashboard`);
    if (resultado) {
      setParceirosCadastrados(resultado.data.parceirosCadastrados);
      setRevendasCadastradas(resultado.data.revendasCadastradas);
      setParceirosPontuados(resultado.data.parceirosPontuados);
      setRevendasPontuadas(resultado.data.revendasPontuadas);
      setTopParceirosPontuados(resultado.data.topParceirosPontuados);
      setTopRevendasPontuadas(resultado.data.topRevendasPontuadas);
      setTopRevendasQueIndicam(resultado.data.topRevendasQueIndicam);
      setTopProdutosVendidos(resultado.data.topProdutosVendidos);
      setLojasPontuadas(resultado.data.lojasPontuadas);
      setQtdParceirosIndicados(resultado.data.qtdParceirosIndicados);
      setQtdParceirosIndicadosValidados(resultado.data.qtdParceirosIndicadosValidados);
    }
    setBuscando(false);
  };

  useEffect(() => {
    buscarDados();
  }, []);

  return (
    <Container fluid>
      <h4 className="pt-3 pb-3 f-black">Dashboard</h4>
      <hr className="hr-loja mt-0 mb-4" />
      <Row className="pb-2">
        <Col className="pb-1" xs={12} sm={6} lg={3}>
          <div className="card-dash">
            <span className="card-dash-titulo">Pintores Cadastrados</span>
            <span className="card-dash-subtitulo">
              {buscando ? (
                <i className="fas fa-circle-notch fa-spin"></i>
              ) : (
                Number(parceirosCadastrados).toLocaleString('pt-BR')
              )}
            </span>
            <div className="card-dash-footer"></div>
          </div>
        </Col>
        <Col className="pb-1" xs={12} sm={6} lg={3}>
          <div className="card-dash">
            <span className="card-dash-titulo">Pintores Pontuados</span>
            <span className="card-dash-subtitulo">
              {buscando ? (
                <i className="fas fa-circle-notch fa-spin"></i>
              ) : (
                Number(parceirosPontuados).toLocaleString('pt-BR')
              )}
            </span>
            <div className="card-dash-footer"></div>
          </div>
        </Col>
        <Col className="pb-1" xs={12} sm={6} lg={3}>
          <div className="card-dash">
            <span className="card-dash-titulo">Revendas Pontuadas</span>
            <span className="card-dash-subtitulo">
              {' '}
              {buscando ? (
                <i className="fas fa-circle-notch fa-spin"></i>
              ) : (
                Number(revendasPontuadas).toLocaleString('pt-BR')
              )}
            </span>
            <span className="card-dash-info">
              Revendas Cadastradas:{` `}
              {buscando ? (
                <i className="fas fa-circle-notch fa-spin"></i>
              ) : (
                Number(revendasCadastradas).toLocaleString('pt-BR')
              )}
            </span>
            <div className="card-dash-footer"></div>
          </div>
        </Col>
        <Col className="pb-1" xs={12} sm={6} lg={3}>
          <div className="card-dash">
            <span className="card-dash-titulo">Lojas Pontuadas</span>
            <span className="card-dash-subtitulo">
              {buscando ? (
                <i className="fas fa-circle-notch fa-spin"></i>
              ) : (
                Number(lojasPontuadas).toLocaleString('pt-BR')
              )}
            </span>
            <div className="card-dash-footer"></div>
          </div>
        </Col>
      </Row>
      {/*  
      <Row className="pb-5">
        <Col className="pb-1" xs={12} sm={6} lg={3}>
          <div className="card-dash">
            <span className="card-dash-titulo">Pintores Indicados</span>
            <span className="card-dash-subtitulo">
              {' '}
              {buscando ? (
                <i className="fas fa-circle-notch fa-spin"></i>
              ) : (
                Number(qtdParceirosIndicados).toLocaleString('pt-BR')
              )}
            </span>
            <span className="card-dash-info">
              Pintores Validados:{` `}
              {buscando ? (
                <i className="fas fa-circle-notch fa-spin"></i>
              ) : (
                Number(qtdParceirosIndicadosValidados).toLocaleString('pt-BR')
              )}
            </span>
            <div className="card-dash-footer"></div>
          </div>
        </Col>
      </Row>*/}
      <Row>
        <Col xs={12} xl={6} className="mb-4">
          <div>
            <h5>Top 20 Pintores Pontuados</h5>
          </div>
          <Table className="dash-table" responsive variant="dark">
            <thead className="dash-table-header">
              <tr>
                <th>Pintor</th>

                <th>Acumulado</th>
                <th>Saldo</th>
                <th>R$ Vendido</th>
              </tr>
            </thead>
            <tbody className="dash-table-body">
              {buscando && (
                <tr>
                  <td colSpan={4} className="text-center">
                    Carregando
                    <i className="fas fa-circle-notch fa-spin ml-3"></i>
                  </td>
                </tr>
              )}
              {topParceirosPontuados.map((parceiro) => (
                <tr key={parceiro.nome}>
                  <td>{capitalize(parceiro.nome)}</td>

                  <td>
                    {Number(parceiro.totalPontos).toLocaleString('pt-BR')}
                  </td>
                  <td>
                    {Number(parceiro.saldoPontos).toLocaleString('pt-BR')}
                  </td>
                  <td>
                    {Number(parceiro.totalVendido)
                      .toLocaleString('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                      })
                      .toString()
                      .replace(/\u00a0/g, ' ')}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
        <Col xs={12} xl={6} className="mb-4">
          <div>
            <h5>Top 20 Cadastros por Revenda</h5>
          </div>

          <Table className="dash-table" responsive variant="dark">
            <thead className="dash-table-header">
              <tr>
                <th>Revenda</th>
                <th>Pintores Indicados</th>
              </tr>
            </thead>
            <tbody className="dash-table-body">
              {buscando && (
                <tr>
                  <td colSpan={4} className="text-center">
                    Carregando
                    <i className="fas fa-circle-notch fa-spin ml-3"></i>
                  </td>
                </tr>
              )}
              {topRevendasQueIndicam.map((revenda) => (
                <tr key={revenda.loja}>
                  <td>{capitalize(revenda.revenda)}</td>
                  <td>{Number(revenda.total).toLocaleString('pt-BR')}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
        <Col xs={12} xl={6} className="mb-4">
          <div>
            <h5>Top 20 Revendas Pontuadas</h5>
          </div>
          <Table className="dash-table" responsive variant="dark">
            <thead className="dash-table-header">
              <tr>
                <th>Loja</th>
                <th>Acumulado</th>
                <th>R$ Vendido</th>
              </tr>
            </thead>
            <tbody className="dash-table-body">
              {buscando && (
                <tr>
                  <td colSpan={4} className="text-center">
                    Carregando
                    <i className="fas fa-circle-notch fa-spin ml-3"></i>
                  </td>
                </tr>
              )}
              {topRevendasPontuadas.map((revenda) => (
                <tr key={revenda.loja}>
                  <td>{capitalize(revenda.loja)}</td>
                  <td>{Number(revenda.totalPontos).toLocaleString('pt-BR')}</td>
                  <td>
                    {Number(revenda.totalVendido)
                      .toLocaleString('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                      })
                      .toString()
                      .replace(/\u00a0/g, ' ')}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
        <Col xs={12} xl={6} className="mb-4">
          <div>
            <h5>Top 20 Produtos Pontuados</h5>
          </div>
          <Table className="dash-table" responsive variant="dark">
            <thead className="dash-table-header">
              <tr>
                <th>Produto</th>
                <th>Acumulado</th>
                <th>Quantidade</th>
                <th>R$ Vendido</th>
              </tr>
            </thead>
            <tbody className="dash-table-body">
              {buscando && (
                <tr>
                  <td colSpan={4} className="text-center">
                    Carregando
                    <i className="fas fa-circle-notch fa-spin ml-3"></i>
                  </td>
                </tr>
              )}
              {topProdutosVendidos.map((revenda) => (
                <tr key={revenda.produto}>
                  <td>{capitalize(revenda.produto)}</td>

                  <td>
                    {Number(revenda.totalPontuado).toLocaleString('pt-BR')}
                  </td>
                  <td>{Number(revenda.qtde).toLocaleString('pt-BR')}</td>
                  <td>
                    {Number(revenda.totalVendido)
                      .toLocaleString('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                      })
                      .toString()
                      .replace(/\u00a0/g, ' ')}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  );
}
