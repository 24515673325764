import React, { useEffect, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';

import { Container } from 'react-bootstrap';
import api from '../../../services/API';
import AddCurso from './Components/AddCurso';
import EditCurso from './Components/EditCurso';

import './styles.css';
const ToSearch = (props) => {
  let input;

  const handleClick = () => props.onSearch(input.value);
  const handleEnterKey = (e) => {
    if (e.key === 'Enter') {
      props.onSearch(input.value);
    }
  };

  return (
    <div className="d-flex justify-content-center align-items-center">
      <input
        className="input-theme busca mr-1"
        ref={(n) => (input = n)}
        type="text"
        onKeyUp={handleEnterKey}
      />
      <button
        className="btn-primario-slim"
        onClick={handleClick}
        style={{ width: 40, height: 40 }}
      >
        <i className="fa fa-search px-2"></i>
      </button>
    </div>
  );
};

const defaultSorted = [
  {
    dataField: 'curso',
    order: 'asc',
  },
];

export default function Cursos() {
  const [data, setData] = useState([]);
  const [buscando, setBuscando] = useState(false);

  const [add, setAdd] = useState(false);
  const [edit, setEdit] = useState({ open: false, data: null });

  const onAdd = () => {
    setAdd(!add);
  };

  const onBack = () => {
    buscarLojas();
    setEdit({ open: false, data: null });
    setAdd(false);
  };

  const onEdit = (data) => {
    setEdit({ open: true, data });
  };

  const columns = [
    {
      dataField: 'nome',
      text: 'Curso',
      sort: true,
    },
    {
      dataField: 'cargaHoraria',
      text: 'Carga Hóraria',
      formatter: (celula, valor) =>
        valor.cargaHoraria ? valor.cargaHoraria + ' Horas' : '-',
      sort: true,
    },
    {
      dataField: 'preco',
      text: 'Investimento',
      formatter: (celula, valor) => {
        return valor.preco
          ? Number(valor.preco).toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            })
          : 'Gratuito';
      },
      sort: true,
    },
    {
      dataField: 'editar',
      text: '',
      sort: true,
      sort: true,
      formatter: (celula, valor) => (
        <button
          className="btn-limpo f-12 p-1"
          onClick={() => {
            onEdit(valor);
          }}
        >
          <i className="fas fa-edit"></i>
        </button>
      ),
    },
  ];

  useEffect(() => {
    buscarLojas();
  }, []);

  const buscarLojas = async () => {
    setBuscando(true);

    try {
      const { data } = await api.post(`/api/cursos/curso/search/0/10`, {
        where: {
          Curso: {
            ativo: true,
          },
        },
      });
      setData(data);
    } catch (error) {
      console.log(error);
    }

    setBuscando(false);
  };

  const options = {
    sizePerPage: 10,
    showTotal: true,
    sizePerPageList: [
      {
        text: '5',
        value: 5,
      },
      {
        text: '10',
        value: 10,
      },
      {
        text: '50',
        value: 50,
      },
      {
        text: '100',
        value: 100,
      },
      {
        text: 'Todos',
        value: data.length,
      },
    ],
    paginationTotalRenderer: (from, to, size) =>
      size > 0 ? (
        <span className="react-bootstrap-table-pagination-total px-2">
          {from}-{to} de {size} resultados
        </span>
      ) : (
        <span className="react-bootstrap-table-pagination-total px-2">
          Nenhum resultado
        </span>
      ),
  };

  const onCloseAdd = () => {
    onAdd();
    buscarLojas();
  };

  return (
    <Container fluid>
      <div className="d-flex justify-content-between align-items-center">
        <h4 className="pt-3 pb-3 f-black">Cursos</h4>
        <button
          className="btn-primario-slim p-2"
          onClick={add || edit?.open ? onBack : onAdd}
        >
          {add || edit?.open ? 'VOLTAR' : <i className="fa fa-plus px-2"></i>}
        </button>
      </div>
      <hr className="hr-loja mt-0 mb-4" />

      {!add && !edit?.open && (
        <div>
          <ToolkitProvider
            bootstrap4
            keyField="codigo"
            data={data}
            columns={columns}
            search
          >
            {(props) => (
              <div>
                <div className="d-flex flex-row-reverse mb-2">
                  <ToSearch {...props.searchProps} />
                </div>
                <BootstrapTable
                  noDataIndication={() =>
                    buscando ? (
                      <span>
                        Carregando
                        <i className="fas fa-circle-notch fa-spin ml-3"></i>
                      </span>
                    ) : (
                      <span>Sem resultados</span>
                    )
                  }
                  classes="table-dark text-center rounded"
                  wrapperClasses="rounded"
                  bordered={false}
                  {...props.baseProps}
                  pagination={paginationFactory(options)}
                  defaultSorted={defaultSorted}
                />
              </div>
            )}
          </ToolkitProvider>
        </div>
      )}

      {add && <AddCurso onClose={onCloseAdd} />}
      {edit?.open && <EditCurso onClose={onBack} data={edit?.data} />}
    </Container>
  );
}
