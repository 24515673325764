import React, { useState, useEffect } from 'react';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import api from '../../../services/API';
import { toast } from 'react-toastify';
import './styles.css';
import useComponentVisible from '../OutsideAlerter/index';

const ExportCSV = (props) => {
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);

  const handleDownload = (type) => {
    toast.success('Seu download começará em breve, aguarde.', {
      position: toast.POSITION.BOTTOM_CENTER,
      autoClose: 5000,
    });
    props.options.searchData({
      download: type,
      fileName: props.options.fileName,
    });
    setIsComponentVisible(false);
  };

  return (
    <div className="dropdown">
      <button
        id="export-button"
        className="btn-primario-slim"
        style={{ width: 40, height: 40 }}
        onClick={(e) => setIsComponentVisible(true)}
      >
        <i className="fa fa-file-download px-2"></i>
      </button>

      <div ref={ref}>
        {isComponentVisible && (
          <div id="export-menu" className="btn-primario-slim">
            <button onClick={(e) => handleDownload('todos')}>Todos</button>
            <button onClick={(e) => handleDownload('atual')}>
              Página atual
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

const ToSearch = (props) => {
  let input;

  const handleClick = () => {
    props.onSearch(input.value);
  };

  const handleEnterKey = (e) => {
    if (e.key === 'Enter') {
      props.onSearch(input.value);
    }
  };

  return (
    <div className="d-flex justify-content-center align-items-center">
      <input
        className="input-theme busca mr-1"
        ref={(n) => (input = n)}
        type="text"
        onKeyUp={handleEnterKey}
      />
      <button
        className="btn-primario-slim"
        onClick={handleClick}
        style={{ width: 40, height: 40 }}
      >
        <i className="fa fa-search px-2"></i>
      </button>
    </div>
  );
};

export default function RemoteTable({ search = true, ...props }) {
  const [total, setTotal] = useState(1);
  const [data, setData] = useState([]);
  const [ordenacao, setOrdenacao] = useState([
    {
      dataField: props.ordenacaoInicial.campo,
      order: props.ordenacaoInicial.direcao,
    },
  ]);
  const [busca, setBusca] = useState('');
  const [limite, setLimite] = useState(10);
  const [pagina, setPagina] = useState(1);
  const [estaCarregando, setEstaCarregando] = useState(false);

  useEffect(() => {
    estaCarregando && setData([]);
  }, [estaCarregando]);

  useEffect(() => {
    searchData();
  }, [ordenacao, busca, limite, pagina, props.filtros]);

  const onTableChange = (type, newState) => {
    switch (type) {
      case 'sort':
        if (
          ordenacao[0].dataField !== newState.sortField ||
          ordenacao[0].order !== newState.sortOrder
        ) {
          setOrdenacao([
            {
              dataField: newState.sortField,
              order: newState.sortOrder,
            },
          ]);
        }

        break;
      case 'search':
        if (busca !== newState.searchText) {
          if (pagina > 1) {
            setPagina(1);
          }
          setBusca(newState.searchText);
        }

        break;
      case 'pagination':
        limite !== newState.sizePerPage && setLimite(newState.sizePerPage);
        pagina !== newState.page && setPagina(newState.page);
        break;
      default:
        break;
    }
  };

  const searchData = async (options = {}) => {
    try {
      if (!options.download) {
        setEstaCarregando(true);
      }
      const ordernar = {
        campo: ordenacao[0].dataField,
        direcao: ordenacao[0].order,
      };
      const result = await api.get(
        `${props.url}?${
          Object.keys(options).length > 0
            ? `&download=${options.download}&fileName=${options.fileName}`
            : ''
        }&pagina=${pagina}&ordenacao=${JSON.stringify(
          ordernar
        )}&buscar=${busca}&limite=${limite}&filtros=${JSON.stringify(
          props.filtros ? props.filtros : {}
        )}`
      );

      if (result && !options.download) {
        setData(result.data.rows);
        setTotal(Number(result.data.count));
      } else {
        window.open(result.data.fileLink, '_blank');
      }

      if (!options.download) {
        setEstaCarregando(false);
      }
    } catch (error) {
      toast.error(error.message, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      setEstaCarregando(false);
    }
  };

  const paginationOptions = {
    page: pagina,
    sizePerPage: limite,
    totalSize: total,
    showTotal: true,
    sizePerPageList: [
      {
        text: '5',
        value: 5,
      },
      {
        text: '10',
        value: 10,
      },
      {
        text: '50',
        value: 50,
      },
      {
        text: '100',
        value: 100,
      },
      {
        text: 'Todos',
        value: total,
      },
    ],
    paginationTotalRenderer: (from, to, size) =>
      size > 0 ? (
        <span className="react-bootstrap-table-pagination-total px-2">
          {from}-{to} de {size} resultados
        </span>
      ) : (
        <span className="react-bootstrap-table-pagination-total px-2">
          Nenhum resultado
        </span>
      ),
  };

  return (
    <ToolkitProvider
      bootstrap4
      remote
      keyField={props.dataId}
      data={data}
      columns={props.colunas}
      search
    >
      {(toolkitProps) => (
        <div>
          <div className="d-flex flex-row-reverse justify-content-right align-items-center mb-2">
            {props.exportar && (
              <ExportCSV
                {...toolkitProps.csvProps}
                options={{
                  fileName: props.exportar?.fileName || 'Relatorio.csv',
                  searchData,
                }}
              />
            )}
            <div className="mr-2">
              {search && <ToSearch {...toolkitProps.searchProps} />}
            </div>
          </div>
          <BootstrapTable
            remote
            loading={estaCarregando}
            onTableChange={onTableChange}
            classes="table-dark text-center rounded"
            wrapperClasses="rounded"
            bordered={false}
            pagination={paginationFactory(paginationOptions)}
            defaultSorted={ordenacao}
            noDataIndication={() =>
              estaCarregando ? (
                <span>
                  Carregando
                  <i className="fas fa-circle-notch fa-spin ml-3"></i>
                </span>
              ) : (
                <span>Sem resultados</span>
              )
            }
            {...toolkitProps.baseProps}
          />
        </div>
      )}
    </ToolkitProvider>
  );
}
