import React, { useState, useEffect } from 'react';
import { Modal, Row, Col } from 'react-bootstrap';
import api from '../../../../services/API';
import { toast } from 'react-toastify';

export default function ModalMateriais({
  show,
  id,
  onHide,
  onAulaClose,
  idModulo,
  data,
  idAula,
}) {
  const [alterando, setAlterando] = useState(false);

  const [material, setMaterial] = useState(false);

  useEffect(() => {
    if (data) {
      setMaterial({ descricao: data.descricao, nome: data.nome });
    }
  }, [data]);
  // console.log(material);
  const onCreate = async (e) => {
    e.preventDefault();

    setAlterando(true);

    const formData = new FormData(e.target);

    const resultado = await api.post(
      `/api//cursos/curso/${id}/modulo/${idModulo}/aula/${idAula}/material`,
      formData
    );

    if (resultado?.data) {
      toast.success(`Material criada com sucesso`, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      onAulaClose();
    }
    setAlterando(false);
  };

  const onUpdate = async (e) => {
    e.preventDefault();

    setAlterando(true);

    const formData = new FormData(e.target);

    const resultado = await api.put(
      `/api/cursos/material/${data.id}`,
      formData
    );

    if (resultado?.data) {
      toast.success(`Material atualizada com sucesso`, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      onAulaClose();
    }
    setAlterando(false);
  };

  const onDelete = async (e) => {
    e.preventDefault();

    setAlterando(true);

    const resultado = await api.delete(`/api/cursos/material/${data.id}`);

    if (resultado) {
      toast.success(`Material deletado com sucesso`, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      onAulaClose();
    }

    setAlterando(false);
  };

  const onChange = (e) => {
    const v = e.target.value;
    const n = e.target.name;

    setMaterial({ ...material, [n]: v });
  };

  // console.log(data);

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="sw"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      scrollable
    >
      <Modal.Header closeButton>
        <Modal.Title>Material {data ? ` - ${data?.nome}` : ''}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-0">
        <form
          className="form p-4 d-flex justify-content-center align-items-center"
          id="form"
          onSubmit={data ? onUpdate : onCreate}
        >
          <Row>
            <Col className="d-flex flex-column" xs={12}>
              <label htmlFor="nome">Nome</label>
              <input
                id="nome"
                name="nome"
                type="text"
                placeholder="Nome"
                value={material.nome}
                onChange={onChange}
              />
            </Col>
            <Col className="d-flex flex-column" xs={12}>
              <label htmlFor="descricao">Descrição</label>
              <input
                id="descricao"
                name="descricao"
                type="text"
                placeholder="Descrição"
                value={material.descricao}
                onChange={onChange}
              />
            </Col>
            <Col className="d-flex flex-column" xs={12}>
              <label htmlFor="link">Material</label>
              <input id="link" name="link" type="file" placeholder="Material" />
            </Col>
          </Row>{' '}
          {data && (
            <button
              form="form"
              className="btn btn-danger m-1"
              onClick={onDelete}
            >
              EXCLUIR MATERIAL
            </button>
          )}
        </form>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex justify-content-center align-items-center w-100">
          {alterando ? (
            <button type="submit" className="btn btn-success m-1" disabled>
              ALTERANDO...
            </button>
          ) : (
            <button type="submit" form="form" className="btn btn-success m-1">
              {data ? 'ALTERAR' : 'CADASTRAR'}
            </button>
          )}

          <button type="button" className="btn btn-danger m-1" onClick={onHide}>
            CANCELAR
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
