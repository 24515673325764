import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './styles.css';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { desautenticarParceiro } from '../../../store/modules/autenticacao/actions';
import Nav from '../../../components/Loja/Nav';
import NavMobile from '../../../components/Loja/NavMobile';
import ModalVideoWrapper from '../ModalVideoWrapper';

export default function Header(props) {
  const dispatch = useDispatch();
  const parceiro = useSelector((state) => {
    return state.parceiro;
  });

  const [estadoModal, setEstadoModal] = useState(false);
  const [pesquisa, setPesquisa] = useState('');

  const autenticado = useSelector(
    (state) => state.autenticacao.autenticadoLoja
  );

  const sair = () => {
    dispatch(desautenticarParceiro());
  };

  const abrirModalVideoTutorial = () => {
    setEstadoModal(true);
  };

  const getFirstName = () => {
    const arr = parceiro.nome.split(' ');
    return arr[0];
  };

  const doSearch = (event) => {
    if (event.key == 'Enter') {
      window.location.href =
        `/loja?search=` + encodeURIComponent(pesquisa || '');
    }
  };

  const logout = () => {
    localStorage.clear();
    sessionStorage.clear();
    window.location.href = '/';
  };

  return (
    <header className={props.auto ? 'header-home-auto' : 'header-home'}>
      <Container className="d-flex flex-column justify-content-center align-items-center text-center h-100 mt-2">
        {!autenticado ? (
          <Row className="w-100 mt-1 mb-2">
            <Col
              xs={12}
              sm={4}
              className="d-flex align-items-center justify-content-center"
            >
              <Link to="/loja" className="d-flex flex-column">
                {/* <img
                  style={{ width: '140px', marginLeft: '-30px' }}
                  src="/imagens/logo-fundo-claro.png"
                  alt="Logo Clube Pro Pintor"
                ></img> */}
                {/* <span className="f-10">Clube de Fidelidade</span> */}
                <img
                  className="w-100 nav-logo m-auto"
                  src="/imagens/logo-dark.png"
                  alt="Logo Clube Pro Pintor"
                ></img>
                <div className="d-flex flex-column justify-content-center align-items-center">
                  <span className="f-10">Administrado e Desenvolvido por</span>
                  <div className="d-flex flex-row justify-content-center align-items-center">
                    <img
                      className="nav-brand m-auto"
                      src="/imagens/logo-smart.svg"
                      alt="Logo Smart"
                    ></img>
                    <span className="f-10 text-nowrap">
                      Clube de Fidelidade
                    </span>
                  </div>
                </div>
              </Link>
            </Col>
            <Col xs={12} sm={8} className="header-acoes">
              <Link to="login" className="link-colored mr-3">
                <i className="fas fa-user mr-1"></i> ENTRAR
              </Link>
              <Link to="cadastrar" className="border-colored mr-3">
                <i className="fas fa-user-edit mr-1"></i> CADASTRE-SE
              </Link>
              <Link to="relatorio" className="border-colored mr-3">
                <i className="fas fa-store mr-1"></i> LOJISTA
              </Link>
              <button
                onClick={abrirModalVideoTutorial}
                to="cadastrar"
                className="border-colored p-1"
              >
                <i className="fas fa-video mr-1"></i> TUTORIAL
              </button>
            </Col>
          </Row>
        ) : (
          <>
            <Row className="d-flex d-md-none w-100">
              <NavMobile />
            </Row>
            <Row className="w-100 my-3">
              <Col
                xs={12}
                md={2}
                className="d-flex align-items-center justify-content-center"
              >
                <Link to="/home" className="d-none d-md-flex flex-column">
                  {/*<span className="f-10">Clube de Fidelidade</span>*/}
                  <img
                    className="w-100 nav-logo m-auto"
                    src="/imagens/logo-dark.png"
                    alt="Logo Clube Pro Pintor"
                  ></img>

                  <div className="d-none d-md-flex flex-column justify-content-center align-items-center">
                    <span className="f-10">
                      Administrado e Desenvolvido por
                    </span>
                    <div className="d-flex flex-row justify-content-center align-items-center">
                      <img
                        className="nav-brand m-auto"
                        src="/imagens/logo-smart.svg"
                        alt="Logo Smart"
                      ></img>
                      <span className="f-10 text-nowrap">
                        Clube de Fidelidade
                      </span>
                    </div>
                  </div>
                </Link>
              </Col>
              <Col xs={12} md={10}>
                <Row>
                  <Col
                    xs={12}
                    md={7}
                    className="my-2 d-none d-md-block text-left"
                  >
                    {/*<div style={{paddingTop: '0', paddingBottom: '16px', color: '#fff'}}>*/}

                    {/*  Olá, <b style={{color: '#6feffa'}}>{getFirstName()}!</b>*/}
                    {/*  <span className="pl-2">*/}
                    {/*    Seu saldo atual é de{' '}*/}
                    {/*      <b style={{color: '#6feffa'}}>*/}
                    {/*      {Number(parceiro.saldoPontos).toLocaleString('pt-BR')} pontos*/}
                    {/*    </b>*/}
                    {/*  </span>*/}

                    {/*<table className="w-100">*/}
                    {/*  <tbody>*/}

                    {/*  <tr>*/}
                    {/*    <td style={{width: '50px', fontSize: '22px', color: '#fff'}}>*/}
                    {/*      <i className="fas fa-search"/></td>*/}
                    {/*    <td>*/}
                    {/*      <input*/}
                    {/*        type={'text'}*/}
                    {/*        onChange={(e) => setPesquisa(e.target.value)}*/}
                    {/*        onKeyPress={doSearch}*/}
                    {/*        value={pesquisa} className="form-control" style={{borderRadius: '16px'}}/>*/}
                    {/*    </td>*/}
                    {/*  </tr>*/}
                    {/*  </tbody>*/}
                    {/*</table>*/}
                    {/*</div>*/}
                  </Col>
                  <Col xs={12} md={5} className="my-2">
                    <div className="text-right">
                      <span>
                        Olá,{' '}
                        <b style={{ color: '#6feffa' }}>{getFirstName()}!</b>{' '}
                        <br />
                        <span className="pl-2">
                          Seu saldo atual é de{' '}
                          <b style={{ color: '#6feffa' }}>
                            {Number(parceiro.saldoPontos).toLocaleString(
                              'pt-BR'
                            )}{' '}
                            pontos
                          </b>
                        </span>{' '}
                        <br />
                        <div className="d-block d-sm-block d-md-none">
                          <a href="javascript:void(0)" onClick={logout}>
                            Sair{' '}
                            <i
                              style={{ color: '#6feffa' }}
                              className="fa fa-sign-out-alt"
                            ></i>
                          </a>
                        </div>
                      </span>
                    </div>

                    <div
                      className="d-none d-md-block"
                      style={{ paddingTop: '12px' }}
                    >
                      <table className="w-100">
                        <tbody>
                          <tr>
                            <td className="text-right">
                              <Link className="nav-indicacao" to="/indicacao">
                                INDIQUE E GANHE
                              </Link>
                            </td>
                            <td
                              className="text-right"
                              style={{ color: '#fff', width: '85px' }}
                            >
                              <a href="javascript:void(0)" onClick={logout}>
                                Sair{' '}
                                <i
                                  style={{ color: '#6feffa' }}
                                  className="fa fa-sign-out-alt"
                                ></i>
                              </a>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div
                      className="d-block d-sm-block d-md-none"
                      style={{ paddingTop: '16px', paddingBottom: '52px' }}
                    >
                      <Link
                        className="btn-block nav-indicacao"
                        to="/indicacao"
                        style={{ padding: '6px 8px', borderRadius: '6px' }}
                      >
                        INDIQUE E GANHE
                      </Link>
                    </div>
                  </Col>
                  {/*<Col xs={4} md={1} className="my-2 mt-2">*/}
                  {/*  <button onClick={sair} className="nav-sair">*/}
                  {/*    <i className="fas fa-sign-out-alt mr-1" /> Sair*/}
                  {/*  </button>*/}
                  {/*</Col>*/}
                </Row>
                <div className="d-none d-md-flex w-100">
                  <div style={{ marginLeft: '-15px' }}>
                    <Nav />
                  </div>
                </div>
              </Col>
            </Row>
          </>
        )}
      </Container>
      <ModalVideoWrapper
        show={estadoModal}
        onHide={() => setEstadoModal(false)}
      >
        <video
          autoPlay
          autoStart
          controls
          className="w-100"
          src="/videos/tutorial.mp4"
        ></video>
      </ModalVideoWrapper>
    </header>
  );
}
