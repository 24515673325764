import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import { toast } from 'react-toastify';
import api from '../../../services/API';

export default function AtualizarRelatorio() {
  const [atualizando, setAtualizando] = useState(false);

  const atualizarRelatorio = async (e) => {
    e.preventDefault();
    setAtualizando(true);
    const resultado = await api.get('/api/relatorio/gerar');

    if (resultado) {
      toast.success(
        `Relatório período ${resultado.data.periodo} atualizado com sucesso`,
        {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 10000,
        }
      );
    }
    setAtualizando(false);
  };

  return (
    <Container fluid>
      <div>
        <h4 className="pt-3 pb-3 f-black">Atualizar Relatório</h4>
        <hr className="hr-loja mt-0 mb-5" />
      </div>
      <div className="d-flex flex-column align-items-center">
        <form className="form" onSubmit={atualizarRelatorio}>
          {atualizando ? (
            <button disabled className="btn-primario" type="submit">
              ATUALIZANDO...
            </button>
          ) : (
            <button className="btn-primario" type="submit">
              ATUALIZAR RELATÓRIO
            </button>
          )}
        </form>
      </div>
    </Container>
  );
}
