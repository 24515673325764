import React from 'react';
import { Container, Row } from 'react-bootstrap';
import Header from '../../../components/Loja/Header/index';
import Footer from '../../../components/Loja/Footer/index';

export default function PoliticaPrivacidade() {
  return (
    <>
      <Header auto />
      <Container className="mt-5 mb-5">
        <Row className="justify-content-center mt-5">
          <img
            className="w-100 form-logo"
            src="/imagens/logo-fundo-claro.png"
            alt="Logo Clube Pro Pintor"
          ></img>
        </Row>
        <div className="text-justify">
          <div className="mt-5 mb-5">
            <h4 className="f-black text-center font-weight-bold mb-4">
              POLÍTICA DE PRIVACIDADE CLUBE PRO PINTOR – Smart Tecnologia em
              Vendas
            </h4>
            <span>
              Por favor, leia esta Política de Privacidade (“Política”)
              cuidadosamente para entender nossas políticas e práticas sobre
              seus Dados Pessoais (“Dados”) e como os trataremos. Esta Política
              se aplica a indivíduos que se cadastram no Clube Pro Pintor
              (“você”). Esta Política explica como seus Dados Pessoais são
              coletados, usados e divulgados pela Smart (“Realizadora”). Ela
              também informa como você pode acessar e atualizar seus Dados
              Pessoais e tomar certas decisões sobre como seus Dados Pessoais
              são utilizados.
            </span>
          </div>
          <br />
          <div>
            <ol>
              <li className="font-weight-bold f-18 mb-3">
                <span>Como coletamos informações pessoais</span>
                <li className="mt-2">
                  <p className="font-weight-normal f-16">
                    Quando você se cadastra no Clube Pro Pintor, coletamos Dados
                    sobre você, para que você crie um login e senha (“Acesso a
                    Loja Clube Pro Pintor”).
                  </p>
                  <p className="font-weight-normal f-16">
                    Durante o processo de cadastro, nós solicitamos Dados como
                    seu nome completo, endereço de e-mail, data de nascimento,
                    gênero, CPF, endereço, telefone fixo e/ou celular.
                  </p>
                </li>
              </li>
              <li className="font-weight-bold f-18 mb-3">
                <span>Uso das informações pessoais</span>
                <li className="mt-2">
                  <p className="font-weight-normal f-16">
                    Os dados coletados serão utilizados com a finalidade de
                    administração e operação do Clube Pro Pintor, incluindo o
                    uso para os fins de:
                  </p>
                  <ul className="font-weight-normal f-16 ml-5">
                    <li>
                      cadastro junto ao Clube Pro Pintor e contato com os
                      membros cadastrados;
                    </li>
                    <li>acúmulo de PONTOS;</li>
                    <li>entrega de premiações;</li>
                    <li>otimização do catálogo de prêmios;</li>
                    <li>
                      desenvolvimento de inteligência de mercado, parâmetros e
                      perfil de consumo, bem como outras atividades associadas
                      ao bom funcionamento e desenvolvimento do PROGRAMA;
                    </li>
                    <li>
                      relatórios, estudos ou indicadores de mercado e sua
                      respectiva disponibilização única e exclusivamente a
                      Sherwin-Williams®.
                    </li>
                  </ul>
                </li>
              </li>
              <li className="font-weight-bold f-18 mb-3">
                <span>Compartilhamento de informações pessoais</span>
                <li className="mt-2">
                  <p className="font-weight-normal f-16">
                    A Smart não divulga, em hipótese alguma, as suas informações
                    pessoais a terceiro. Compartilhamos os seus dados pessoais
                    única e exclusivamente a Sherwin-Williams®.
                  </p>
                </li>
              </li>
              <li className="font-weight-bold f-18 mb-3">
                <span>Preservação de informações pessoais</span>
                <li className="mt-2">
                  <p className="font-weight-normal f-16">
                    Não mantemos as suas informações pessoais por mais tempo do
                    que o necessário para os objetivos para os quais são
                    processadas. Se existirem limitações técnicas que impedirem
                    a exclusão ou anonimização, protegemos as informações
                    pessoais e limitamos o uso ativo dessas informações.
                  </p>
                </li>
              </li>
              <li className="font-weight-bold f-18 mb-3">
                <span>Suas escolhas</span>
                <li className="mt-2">
                  <p className="font-weight-normal f-16">
                    Dados Pessoais: Será possível acessar e revisar estas
                    informações pessoais utilizadas na hora do cadastro a
                    qualquer momento no menu “Minha Conta” Após o login.
                  </p>
                  <p className="font-weight-normal f-16">
                    Exclusão de Dados Pessoais: Se desejar cancelar sua
                    participação no programa Clube Pro Pintor, você pode enviar
                    um e-mail para{' '}
                    <a href="malito:contato@clubepropintor.com.br">
                      contato@clubepropintor.com.br
                    </a>{' '}
                    fazendo a solicitação.
                  </p>
                </li>
              </li>
              <li className="font-weight-bold f-18 mb-3">
                <span>Onde armazenamos seus dados</span>
                <li className="mt-2">
                  <p className="font-weight-normal f-16">
                    Os dados são armazenados em bancos de dados exclusivos do
                    Programa, em ambiente totalmente seguro.
                  </p>
                </li>
              </li>
              <li className="font-weight-bold f-18 mb-3">
                <span>Alterações a esta Política</span>
                <li className="mt-2">
                  <p className="font-weight-normal f-16">
                    Se houver alterações a forma que tratamos seus Dados
                    Pessoais, vamos atualizar esta Política. Nos reservamos o
                    direito de fazer alterações às nossas práticas e a esta
                    Política a qualquer tempo.
                  </p>
                </li>
              </li>
              <li className="font-weight-bold f-18 mb-3">
                <span>Contato</span>
                <li className="mt-2">
                  <p className="font-weight-normal f-16">
                    Para dúvidas ou reclamações sobre o nosso uso das suas
                    informações pessoais ou sobre a nossa Política de
                    privacidade, entre em contato conosco pelo e-mail{' '}
                    <a href="malito:contato@clubepropintor.com.br">
                      contato@clubepropintor.com.br
                    </a>
                  </p>
                </li>
              </li>
            </ol>
          </div>
        </div>
      </Container>
      <Footer />
    </>
  );
}
