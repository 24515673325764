/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import api from '../../../services/API';
import { toast } from 'react-toastify';
import './styles.css';
import { atualizarSaldo } from '../../../store/modules/parceiro/actions';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import moment from 'moment-timezone';

const columns = [
  {
    text: 'Nome',
    dataField: 'Nome',
    formatter: (celula, valor) => {
      if (valor) {
        return valor.nome;
      }
    },
    sort: true,
  },
  {
    text: 'CPF',
    dataField: 'CPF',
    formatter: (celula, valor) => {
      return valor.cpf;
    },
    sort: false,
  },
  {
    text: 'Celular',
    dataField: 'Celular',
    formatter: (celula, valor) => {
      return valor.celular;
    },
    sort: false,
  },
  {
    text: 'Indicação Válida',
    dataField: 'Indicação Válida',
    formatter: (celula, valor) => {
      return (valor.validado && 'Sim') || 'Não';
    },
    sort: true,
  },
];

export default function Perfil() {
  const [buscando, setBuscando] = useState(false);
  const [data, setData] = useState([]);

  const parceiro = useSelector((state) => {
    return state.parceiro;
  });

  const getIndicacoes = async () => {
    if (parceiro.idIndicacao) {
      setBuscando(true);

      const resultado = await api.post(`/api/indique-ganhe/indicacoes`, {
        idIndicacao: parceiro.idIndicacao,
      });

      // console.log(resultado && resultado.data);

      setBuscando(false);
      if (resultado && resultado.data) {
        setData(resultado.data);
        let validados = 0;
        for (let index = 0; index < resultado.data.length; index++) {
          if (resultado.data[index].validado) {
            validados += 1;
          }
          setIndicacoesValidas(validados);
        }
      }
      return;
    }
  };

  //dispatch(atualizarSaldo(Number(resultado.data.score)));

  useEffect(() => {
    getIndicacoes();
  }, []);

  const [indicacoesValidas, setIndicacoesValidas] = useState(0);
  //const [pontosAdquiridos, setPontosAdquiridos] = useState(0);
  //const [CPFIndicacao, setCodigoPromocional] = useState('');
  //const [celularIndicacao, setCodigoPromocional] = useState('');

  //const [cpf] = useState(parceiro.cpf);

  return (
    <Container className="mt-5 mb-5">
      <div className="mt-5">
        <h5>INDIQUE E GANHE</h5>
        <hr className="hr-loja mt-0" />
      </div>

      <h5 className="ml-4">
        Seu código de indicação é <b>{parceiro.idIndicacao}</b>. Informe este
        código aos pintores que deseja indicar.
        <br />A indicação será efetivada após a validação da participação feita
        com o envio da primeira nota ou cupom fiscal do pintor indicado.
      </h5>
      <div className="mt-5">
        <h5>MEUS INDICADOS</h5>
        <hr className="hr-loja mt-0" />
      </div>
      <div>
        <BootstrapTable
          bootstrap4
          noDataIndication={() =>
            buscando ? (
              <span>
                Carregando
                <i className="fas fa-circle-notch fa-spin ml-3"></i>
              </span>
            ) : (
              <span>Sem resultados</span>
            )
          }
          className="tabela-fundo-claro"
          keyField="id"
          data={data}
          columns={columns}
          pagination={paginationFactory()}
          headerClasses="tabela-header-claro"
          bodyClasses="tabela-body-claro"
        />
        <h5>
          Você tem <b>{indicacoesValidas} indicações válidas</b> e acumulou{' '}
          <b>{indicacoesValidas * 100} pontos</b> com suas indicações.
        </h5>
      </div>
    </Container>
  );
}
