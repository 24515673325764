/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Container, Row, Col } from 'react-bootstrap';
import api from '../../../services/API';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import moment from 'moment-timezone';
import sortFunc from '../../../utils/ordernarColunaInt';
const ToSearch = (props) => {
  let input;
  const handleClick = () => {
    props.onSearch(input.value);
  };

  const handleEnterKey = (e) => {
    if (e.key === 'Enter') {
      props.onSearch(input.value);
    }
  };

  return (
    <div className="d-flex justify-content-center align-items-center">
      <input
        className="input-theme busca mr-1"
        ref={(n) => (input = n)}
        type="text"
        onKeyUp={handleEnterKey}
      />
      <button
        className="btn-primario-slim"
        onClick={handleClick}
        style={{ width: 40, height: 40 }}
      >
        <i className="fa fa-search px-2"></i>
      </button>
    </div>
  );
};

export default function Historico() {
  const columns = [
    {
      dataField: 'rede',
      text: 'Rede',
      sort: true,
    },
    {
      dataField: 'janeiro',
      text: 'Janeiro',
      sort: true,
      formatter: (celula, valor) => {
        return Number(valor.janeiro).toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        });
      },
      sortFunc: sortFunc,
    },
    {
      dataField: 'fevereiro',
      text: 'Fevereiro',
      sort: true,
      formatter: (celula, valor) => {
        return Number(valor.fevereiro).toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        });
      },
      sortFunc: sortFunc,
    },
    {
      dataField: 'marco',
      text: 'Março',
      sort: true,
      formatter: (celula, valor) => {
        return Number(valor.marco).toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        });
      },
      sortFunc: sortFunc,
    },
    {
      dataField: 'abril',
      text: 'Abril',
      sort: true,
      formatter: (celula, valor) => {
        return Number(valor.abril).toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        });
      },
      sortFunc: sortFunc,
    },
    {
      dataField: 'maio',
      text: 'Maio',
      sort: true,
      formatter: (celula, valor) => {
        return Number(valor.maio).toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        });
      },
      sortFunc: sortFunc,
    },
    {
      dataField: 'junho',
      text: 'Junho',
      sort: true,
      formatter: (celula, valor) => {
        return Number(valor.junho).toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        });
      },
      sortFunc: sortFunc,
    },
    {
      dataField: 'julho',
      text: 'Julho',
      sort: true,
      formatter: (celula, valor) => {
        return Number(valor.julho).toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        });
      },
      sortFunc: sortFunc,
    },
    {
      dataField: 'agosto',
      text: 'Agosto',
      sort: true,
      formatter: (celula, valor) => {
        return Number(valor.agosto).toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        });
      },
      sortFunc: sortFunc,
    },
    {
      dataField: 'setembro',
      text: 'Setembro',
      sort: true,
      formatter: (celula, valor) => {
        return Number(valor.outubro).toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        });
      },
      sortFunc: sortFunc,
    },
    {
      dataField: 'outubro',
      text: 'Outubro',
      sort: true,
      formatter: (celula, valor) => {
        return Number(valor.outubro).toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        });
      },
      sortFunc: sortFunc,
    },
    {
      dataField: 'novembro',
      text: 'Novembro',
      sort: true,
      formatter: (celula, valor) => {
        return Number(valor.novembro).toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        });
      },
      sortFunc: sortFunc,
    },
    {
      dataField: 'dezembro',
      text: 'Dezembro',
      sort: true,
      formatter: (celula, valor) => {
        return Number(valor.dezembro).toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        });
      },
      sortFunc: sortFunc,
    },
  ];

  useEffect(() => {
    buscarRedes();
    buscarPrimeiraData();
  }, []);

  const buscarRedes = async () => {
    const resultado = await api.get('/api/redes?ativo=1');
    if (resultado) {
      setRedesSelect(resultado.data.redes);
    }
  };

  const buscarHistorico = async () => {
    setBuscando(true);
    const resultado = await api.get(
      `/historico?rede=${redeSelecionada}&ano=${anoSelecionado}`
    );
    if (resultado) {
      setData(resultado.data.historico);
    }
    setBuscando(false);
  };

  const [data, setData] = useState([]);
  const [anoSelecionado, setAnoSelecionado] = useState(
    Number(new Date().getFullYear())
  );
  const [anos, setAnos] = useState([]);
  const [redeSelecionada, setRedeSelecionada] = useState('');
  const [redesSelect, setRedesSelect] = useState([]);
  const [buscando, setBuscando] = useState(false);

  const buscarPrimeiraData = async () => {
    const resultado = await api.get('/api/historico/primeiraData');
    if (resultado && resultado.data.primeiraData) {
      const anos = [];
      for (
        let i = Number(moment(resultado.data.primeiraData).format('YYYY'));
        i <= Number(moment().format('YYYY'));
        i += 1
      ) {
        anos.push(Number(i));
      }
      setAnos(anos);
    } else {
      anos.push(Number(moment().format('YYYY')));
      setAnos(anos);
    }
  };

  const options = {
    sizePerPage: 10,
    showTotal: true,
    sizePerPageList: [
      {
        text: '5',
        value: 5,
      },
      {
        text: '10',
        value: 10,
      },
      {
        text: '50',
        value: 50,
      },
      {
        text: '100',
        value: 100,
      },
      {
        text: 'Todos',
        value: data.length,
      },
    ],
    paginationTotalRenderer: (from, to, size) =>
      size > 0 ? (
        <span className="react-bootstrap-table-pagination-total px-2">
          {from}-{to} de {size} resultados
        </span>
      ) : (
        <span className="react-bootstrap-table-pagination-total px-2">
          Nenhum resultado
        </span>
      ),
  };

  return (
    <Container fluid>
      <h4 className="pt-3 pb-3 f-black">
        {buscando ? 'Buscando...' : 'Histórico'}
      </h4>
      <hr className="hr-loja mt-0 mb-4" />
      <Row className="mb-3">
        <Col xs={12} sm={6} md={4} lg={3} className="d-flex flex-column">
          <label htmlFor="redeSelecionada">Selecione uma Rede</label>
          <select
            className="input-select mb-3"
            name="redeSelecionada"
            id="redeSelecionada"
            onChange={(e) => setRedeSelecionada(e.target.value)}
            defaultValue=""
            placeholder=""
          >
            <option value="">Todas</option>
            {redesSelect.map((rede) => (
              <option value={rede.nome} key={rede.nome}>
                {rede.nome}
              </option>
            ))}
          </select>
        </Col>
        <Col xs={12} sm={3} md={3} lg={2} className="d-flex flex-column">
          <label htmlFor="anoSelecionado">Ano</label>
          <select
            className="input-select mb-3"
            name="anoSelecionado"
            id="anoSelecionado"
            onChange={(e) => setAnoSelecionado(e.target.value)}
            value={anoSelecionado}
          >
            {anos.map((ano) => (
              <option value={ano} key={ano}>
                {ano}
              </option>
            ))}
          </select>
        </Col>
        <Col
          xs={12}
          sm={1}
          className="d-flex flex-column justify-content-center align-items-center d-sm-block"
        >
          <button className="btn-busca" type="submit" onClick={buscarHistorico}>
            <i className="fas fa-search"></i>
          </button>
        </Col>
      </Row>
      <div>
        {!buscando && (
          <ToolkitProvider
            bootstrap4
            keyField="rede"
            data={data}
            columns={columns}
            search
          >
            {(props) => (
              <div>
                <div className="d-flex flex-row-reverse mb-2">
                  <ToSearch {...props.searchProps} />
                </div>

                <BootstrapTable
                  noDataIndication={() =>
                    buscando ? (
                      <span>
                        Carregando
                        <i className="fas fa-circle-notch fa-spin ml-3"></i>
                      </span>
                    ) : (
                      <span>Sem resultados</span>
                    )
                  }
                  classes="table-dark text-center rounded"
                  wrapperClasses="rounded"
                  bordered={false}
                  pagination={paginationFactory(options)}
                  {...props.baseProps}
                />
              </div>
            )}
          </ToolkitProvider>
        )}
      </div>
    </Container>
  );
}
