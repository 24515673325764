import React, { useEffect, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Container } from 'react-bootstrap';
import api from '../../../services/API';
import sortFunc from '../../../utils/ordernarColunaInt';
import './styles.css';

const columns = [
  {
    dataField: 'codigo',
    text: 'Código',
  },
  {
    dataField: 'nome',
    text: 'Produto',
    style: { textAlign: 'left' },
  },
  {
    dataField: 'valorPontos',
    text: 'Pontos',
    formatter: (celula, valor) => {
      return Number(valor.valorPontos).toLocaleString('pt-BR');
    },
    sort: true,
    sortFunc: sortFunc,
  },
];

const defaultSorted = [
  {
    dataField: 'valorPontos',
    order: 'desc',
  },
];

export default function ListaPontos() {
  const [buscando, setBuscando] = useState(false);

  useEffect(() => {
    buscarProdutos();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const buscarProdutos = async () => {
    setBuscando(true);
    const payload = {
      ativo: 1
    };

    const resultado = await api.get(`/api/produtos?filtros=${JSON.stringify(payload)}`);
    if (resultado) {
      setData(resultado.data.produtos);
    }
    setBuscando(false);
  };

  const [data, setData] = useState([]);

  return (
    <Container className="mt-5 mb-5">
      <div>
        <h5>PONTOS POR PRODUTO</h5>
        <hr className="hr-loja mt-0" />
      </div>
      <div>
        <BootstrapTable
          noDataIndication={() =>
            buscando ? (
              <span>
                Carregando
                <i className="fas fa-circle-notch fa-spin ml-3"></i>
              </span>
            ) : (
              <span>Sem resultados</span>
            )
          }
          className="tabela-fundo-claro"
          keyField="codigo"
          data={data}
          columns={columns}
          defaultSorted={defaultSorted}
          pagination={paginationFactory({ sizePerPage: 50 })}
          headerClasses="tabela-header-claro"
          bodyClasses="tabela-body-claro"
          classes="tabela-width"
          wrapperClasses="d-flex justify-content-center align-items-center"
          bootstrap4
        />
      </div>
    </Container>
  );
}
