import React, { useEffect, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Container } from 'react-bootstrap';
import api from '../../../services/API';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import sortFunc from '../../../utils/ordernarColunaInt';
const ToSearch = (props) => {
  let input;
  const handleClick = () => {
    props.onSearch(input.value);
  };

  const handleEnterKey = (e) => {
    if (e.key === 'Enter') {
      props.onSearch(input.value);
    }
  };

  return (
    <div className="d-flex justify-content-center align-items-center">
      <input
        className="input-theme busca mr-1"
        ref={(n) => (input = n)}
        type="text"
        onKeyUp={handleEnterKey}
      />
      <button
        className="btn-primario-slim"
        onClick={handleClick}
        style={{ width: 40, height: 40 }}
      >
        <i className="fa fa-search px-2"></i>
      </button>
    </div>
  );
};

const columns = [
  {
    dataField: 'codigo',
    text: 'Código',
    sort: true,
    sortFunc: sortFunc,
  },
  {
    dataField: 'nome',
    text: 'Produto',
    sort: true,
    style: { textAlign: 'left' },
    headerStyle: { textAlign: 'left' },
  },
  {
    dataField: 'valorPontos',
    text: 'Pontos',
    formatter: (celula, valor) => {
      return Number(valor.valorPontos).toLocaleString('pt-BR');
    },
    sort: true,
    sortFunc: sortFunc,
  },
];

const defaultSorted = [
  {
    dataField: 'valorPontos',
    order: 'desc',
  },
];

export default function ProdutosSW() {
  const [buscando, setBuscando] = useState(false);

  useEffect(() => {
    buscarProdutos();
  }, []);

  const buscarProdutos = async () => {
    setBuscando(true);
    const resultado = await api.get('/api/produtos');
    if (resultado) {
      setData(resultado.data.produtos);
    }
    setBuscando(false);
  };

  const [data, setData] = useState([]);

  const options = {
    sizePerPage: 10,
    showTotal: true,
    sizePerPageList: [
      {
        text: '5',
        value: 5,
      },
      {
        text: '10',
        value: 10,
      },
      {
        text: '50',
        value: 50,
      },
      {
        text: '100',
        value: 100,
      },
      {
        text: 'Todos',
        value: data.length,
      },
    ],
    paginationTotalRenderer: (from, to, size) =>
      size > 0 ? (
        <span className="react-bootstrap-table-pagination-total px-2">
          {from}-{to} de {size} resultados
        </span>
      ) : (
        <span className="react-bootstrap-table-pagination-total px-2">
          Nenhum resultado
        </span>
      ),
  };

  return (
    <Container fluid>
      <h4 className="pt-3 pb-3 f-black">Produtos Sherwin-Williams</h4>
      <hr className="hr-loja mt-0 mb-4" />
      <div>
        <ToolkitProvider
          bootstrap4
          keyField="codigo"
          data={data}
          columns={columns}
          search
        >
          {(props) => (
            <div>
              <div className="d-flex flex-row-reverse mb-2">
                <ToSearch {...props.searchProps} />
              </div>
              <BootstrapTable
                defaultSorted={defaultSorted}
                noDataIndication={() =>
                  buscando ? (
                    <span>
                      Carregando
                      <i className="fas fa-circle-notch fa-spin ml-3"></i>
                    </span>
                  ) : (
                    <span>Sem resultados</span>
                  )
                }
                classes="table-dark text-center rounded"
                wrapperClasses="rounded"
                bordered={false}
                keyField="codigo"
                pagination={paginationFactory(options)}
                {...props.baseProps}
              />
            </div>
          )}
        </ToolkitProvider>
      </div>
    </Container>
  );
}
