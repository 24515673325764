export function atualizarPerfil(nome, senhaAntiga, senha) {
  return {
    type: '@usuario/ATUALIZAR_PERFIL',
    payload: { nome, senhaAntiga, senha },
  };
}

export function atualizarPerfilSucesso(nome, senha) {
  return {
    type: '@usuario/ATUALIZAR_PERFIL_SUCESSO',
    payload: { nome, senha },
  };
}

export function atualizarPerfilFalhou() {
  return {
    type: '@usuario/ATUALIZAR_PERFIL_FALHOU',
  };
}
