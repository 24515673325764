import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import ModalMidia from '../ModalMidia';
import moment from 'moment-timezone';
import RemoteTable from '../RemoteTable/index';
import capitalize from '../../../utils/capitalize';

export default function PontuacoesEncerradas() {
  const [tipo, setTipo] = useState('');
  const [mostrarModalFoto, setMostrarModalFoto] = useState(false);
  const [dataInicial, setDataInicial] = useState(
    moment().subtract(7, 'days').toISOString().substr(0, 10)
  );
  const [dataFinal, setDataFinal] = useState(
    moment().toISOString().substr(0, 10)
  );
  const [arquivo, setArquivo] = useState([]);

  const [filtros, setFiltros] = useState({
    dataFinal: moment().toISOString().substr(0, 10),
    dataInicial: moment().subtract(7, 'days').toISOString().substr(0, 10),
  });

  const setarFiltros = (e) => {
    e.preventDefault();
    setFiltros({
      dataInicial,
      dataFinal,
    });
  };

  const columns = [
    {
      text: 'Pintor',
      dataField: 'nome',
      sort: true,
      formatter: (celula, valor) => {
        return capitalize(valor.nome);
      },
      style: { textAlign: 'left' },
      headerStyle: { textAlign: 'left' },
    },
    {
      text: 'CPF',
      dataField: 'cpf',
      formatter: (celula, valor) => {
        let cpf = valor.cpf.replace(/[^\d]/g, '');
        return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
      },
    },
    {
      text: 'Data do Envio',
      dataField: 'criadoEm',
      formatter: (celula, valor) => {
        return moment(valor.criadoEm).format('DD/MM/YYYY HH:mm:ss');
      },
      sort: true,
    },
    {
      text: 'Data do Cancelamento',
      dataField: 'atualizadoEm',
      formatter: (celula, valor) => {
        return moment(valor.atualizadoEm).format('DD/MM/YYYY HH:mm:ss');
      },
      sort: true,
    },
    {
      text: 'Motivo do Cancelamento',
      dataField: 'reprovadoMotivo',
      sort: true,
    },
    {
      text: 'Foto',
      dataField: 'dirFoto',
      formatter: (celula, valor) =>
        valor.diretorioArquivo ? (
          <button
            className="btn-limpo f-12 p-1"
            onClick={() => {
              visualizarModalFoto(valor.diretorioArquivo, valor.tipo);
            }}
          >
            <i className="fas fa-image"></i>
          </button>
        ) : (
          <button className="btn-limpo f-12 p-1" disabled>
            SEM FOTO
          </button>
        ),
    },
  ];

  const visualizarModalFoto = (dirFoto, tipo) => {
    if (dirFoto.endsWith('.pdf')) {
      window.open(`${process.env.REACT_APP_API_BASE_URL}/arquivos/${dirFoto}`);
    } else {
      setArquivo(dirFoto);
      setTipo(tipo);
      setMostrarModalFoto(true);
    }
  };

  const fecharModalFoto = () => {
    setMostrarModalFoto(false);
  };

  return (
    <Container fluid>
      <h4 className="pt-3 pb-3 f-black">Não Pontuável</h4>
      <hr className="hr-loja mt-0 mb-4" />
      <Row className="mb-3">
        <Col xs={12} sm={3} md={3} lg={2} className="d-flex flex-column">
          <label>De</label>
          <input
            type="date"
            className="input-theme"
            value={dataInicial}
            onChange={(e) => setDataInicial(e.target.value)}
            max="2999-12-31"
          ></input>
        </Col>
        <Col xs={12} sm={3} md={3} lg={2} className="d-flex flex-column">
          <label>Até</label>
          <input
            type="date"
            className="input-theme"
            onChange={(e) => setDataFinal(e.target.value)}
            value={dataFinal}
            max="2999-12-31"
          ></input>
        </Col>
        <Col
          xs={12}
          sm={1}
          className="d-flex flex-column justify-content-center align-items-center d-sm-block"
        >
          <button className="btn-busca" type="submit" onClick={setarFiltros}>
            <i className="fas fa-search"></i>
          </button>
        </Col>
      </Row>
      <div>
        <RemoteTable
          dataId="id"
          url={`/api/pontuacoes/encerradas`}
          exportar={{
            fileName: 'pontuacoes',
          }}
          colunas={columns}
          filtros={filtros}
          ordenacaoInicial={{ campo: 'criadoEm', direcao: 'desc' }}
        />
      </div>
      <ModalMidia
        show={mostrarModalFoto}
        onHide={fecharModalFoto}
        diretorioArquivo={arquivo}
        tipo={tipo}
      />
    </Container>
  );
}
