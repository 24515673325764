import React, { useEffect, useState } from 'react';
import { Container, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import api from '../../../services/API';
import RemoteTable from '../RemoteTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';

export default function ValorPonto() {
  const [step, setStep] = useState('INPUT');
  const [showModal, setShowModal] = useState(false);
  const [enviando, setEnviando] = useState(false);
  const [data, setData] = useState({
    id: null,
    valorPonto: null,
    triggerLimite: null,
    tipo: '',
  });

  const resetForm = () => {
    setStep('INPUT');
    setData({
      id: null,
      valorPonto: null,
      triggerLimite: null,
      tipo: '',
    });
  };

  const updateValorPonto = async (e) => {
    e.preventDefault();
    setEnviando(true);
    try {
      await api.put(`/api/valor_ponto/${data.id}`, {
        valorPonto: data.valorPonto,
        triggerLimite: data.triggerLimite,
      });

      toast.success('Valor do ponto atualizado com sucesso.', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
    } catch (erro) {
      toast.error('Erro ao atualizar valor do ponto.', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
    } finally {
      setEnviando(false);
      setShowModal(false);
      window.location.reload();
    }
    resetForm();
  };

  const columns = [
    {
      dataField: 'id',
      text: 'id',
      sort: true,
      hidden: true,
    },
    {
      dataField: 'tipo',
      text: 'Tipo',
      sort: true,
      formatter: (celula, valor) =>
        valor.tipo.charAt(0).toUpperCase() + valor.tipo.slice(1),
    },
    {
      dataField: 'valorPonto',
      text: 'Valor do Ponto',
      sort: true,
      formatter: (celula, valor) =>
        Intl.NumberFormat('pt-BR', {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 3,
        }).format(valor.valorPonto),
    },
    {
      dataField: 'triggerLimite',
      text: 'Limite',
      sort: true,
      formatter: (celula, valor) =>
        Intl.NumberFormat('pt-BR', {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 3,
        }).format(valor.triggerLimite),
    },
    {
      dataField: 'edit',
      text: 'Alterar',
      formatter: (celula, valor) => {
        return (
          <button
            className="bg-transparent border-0"
            type="button"
            onClick={() => {
              setData({
                id: valor.id,
                valorPonto: valor.valorPonto,
                triggerLimite: valor.triggerLimite,
                tipo: valor.tipo,
              });
              setShowModal(true);
            }}
          >
            <FontAwesomeIcon icon={faEdit} />
          </button>
        );
      },
    },
  ];

  return (
    <Container fluid>
      <div>
        <h4 className="pt-3 pb-3 f-black">Valor do Ponto</h4>
        <hr className="hr-loja mt-0 mb-5" />
      </div>
      <div>
        <RemoteTable
          dataId="id"
          url="/api/valor_ponto"
          colunas={columns}
          ordenacaoInicial={{ campo: 'valorPonto', direcao: 'desc' }}
          search={false}
        />
      </div>
      <div className="d-flex flex-column align-items-center"></div>
      <Modal
        show={showModal}
        onHide={() => {
          setShowModal(false);
          setStep('INPUT');
        }}
        centered
      >
        <Modal.Header closeButton>
          Alterando valor para:
          <strong style={{ marginLeft: 8 }}>{data.tipo.toUpperCase()}</strong>
        </Modal.Header>
        <Modal.Body>
          {step === 'INPUT' && (
            <form className="form" onSubmit={(e) => e.preventDefault()}>
              <label htmlFor="titulo">Valor do Ponto</label>
              <input
                id="valorPonto"
                name="valorPonto"
                type="number"
                placeholder="Valor do Ponto"
                onChange={(e) => {
                  e.persist();
                  setData((prev) => ({
                    ...prev,
                    valorPonto: e?.target?.value,
                  }));
                }}
                value={Number(data.valorPonto)}
              ></input>
              <label htmlFor="triggerLimite">Limite</label>
              <input
                id="triggerLimite"
                name="triggerLimite"
                type="number"
                placeholder="Limite"
                onChange={(e) => {
                  e.persist();
                  setData((prev) => ({
                    ...prev,
                    triggerLimite: e?.target?.value,
                  }));
                }}
                value={Number(data.triggerLimite)}
              ></input>
              {enviando ? (
                <button disabled className="btn-primario">
                  ATUALIZANDO...
                </button>
              ) : (
                <button
                  className="btn-primario"
                  type="submit"
                  onClick={() => setStep('CONFIRMAR')}
                >
                  ATUALIZAR
                </button>
              )}
            </form>
          )}
          {step === 'CONFIRMAR' && (
            <div
              style={{
                minHeight: '230px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <div>
                Tem certeza que deseja atualizar o valor do ponto para:{' '}
                <strong>
                  {Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                    minimumFractionDigits: 3,
                  }).format(data.valorPonto)}
                </strong>{' '}
                e limite para:{' '}
                <strong>
                  {Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                    minimumFractionDigits: 3,
                  }).format(data.triggerLimite)}
                </strong>
                ?
              </div>
              <div
                style={{
                  justifyContent: 'space-between',
                  display: 'flex',
                  marginTop: 32,
                }}
              >
                <button
                  className="btn-secundario"
                  onClick={() => {
                    setShowModal(false);
                    setStep('INPUT');
                  }}
                  style={{ paddingRight: 16, paddingLeft: 16 }}
                >
                  CANCELAR
                </button>
                <button
                  className="btn-primario"
                  onClick={updateValorPonto}
                  style={{ paddingRight: 16, paddingLeft: 16 }}
                >
                  CONFIRMAR
                </button>
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </Container>
  );
}
