import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import historico from '../../../services/Historico';
import sortFunc from '../../../utils/ordernarColunaInt';
import moment from 'moment-timezone';
import RemoteTable from '../RemoteTable/index';
import capitalize from '../../../utils/capitalize';
import './styles.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import api from '../../../services/API';

export default function Empresas() {
  const [showModal, setShowModal] = useState(false);
  const [showModalInativar, setShowModalInativar] = useState(false);
  const [cpfPintor, setCpfPintor] = useState('');
  const [nome, setNome] = useState('');
  const [novoEmail, setNovoEmail] = useState('');
  const [atividadePintor, setAtividadePintor] = useState(0);
  const [enviando, setEnviando] = useState(false);

  const atualizarSenha = async (e) => {
    e.preventDefault();

    if (!novoEmail) {
      toast.error('Informe o novo e-mail primeiro', {
        position: toast.POSITION.BOTTOM_CENTER,
      });
      return;
    }

    setEnviando(true);
    try {
      const response = await api.put('/api/parceiros/atualizar-email', {
        cpf: cpfPintor,
        email: novoEmail,
      });

      if (!response) return setEnviando(false);

      toast.success('E-mail atualizado com sucesso!', {
        position: toast.POSITION.BOTTOM_CENTER,
      });
      setEnviando(false);
      setShowModal(false);
    } catch (error) {
      toast.error(error.message, {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    }
  };

  const mudarAtividadePintor = async (e) => {
    e.preventDefault();

    if (!cpfPintor) {
      toast.error('CPF não encontrado.', {
        position: toast.POSITION.BOTTOM_CENTER,
      });
      return;
    }

    setEnviando(true);
    const response = await api
      .put('api/parceiros/atualizar-atividade', {
        cpf: cpfPintor,
        atividade: atividadePintor,
      })
      .catch((error) => {
        setEnviando(false);
        toast.error(error.message, {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      });

    if (!response) return setEnviando(false);

    toast.success('Atividade atualizada com sucesso!', {
      position: toast.POSITION.BOTTOM_CENTER,
    });
    setEnviando(false);
    setShowModalInativar(false);
    window.location.reload();
  };

  const columns = [
    {
      dataField: 'nome',
      text: 'Pintor',
      sort: true,
      style: { textAlign: 'left' },
      headerStyle: { textAlign: 'left' },
      formatter: (celula, valor) => {
        return (
          <button
            className="btn-limpo"
            onClick={(e) => {
              e.preventDefault();
              historico.push(`pintor/${valor.cpf}`);
            }}
          >
            <b>{capitalize(valor.nome)}</b>
          </button>
        );
      },
    },
    {
      dataField: 'endCidade',
      style: { textAlign: 'left' },
      headerStyle: { textAlign: 'left' },
      text: 'Cidade / UF',
      sort: true,
      formatter: (celula, valor) => {
        return `${valor.endCidade} / ${valor.endEstado}`;
      },
    },
    {
      text: 'Loja Favorita',
      style: { textAlign: 'left' },
      headerStyle: { textAlign: 'left' },
      dataField: 'lojaPreferida',
      formatter: (celula, valor) => {
        const str = `${
          valor.lojaPreferida ? capitalize(valor.lojaPreferida) : ''
        }, ${
          valor.cidadeLojaPreferida ? capitalize(valor.cidadeLojaPreferida) : ''
        } / ${
          valor.estadoLojaPreferida ? capitalize(valor.estadoLojaPreferida) : ''
        }`;
        return capitalize(valor.lojaPreferida) && str.match(/(.*[a-z]){1}/i)
          ? str
          : '';
      },
      sort: true,
    },
    {
      text: 'Indicação',
      style: { textAlign: 'left' },
      headerStyle: { textAlign: 'left' },
      dataField: 'indicacaoLoja',
      formatter: (celula, valor) => {
        return valor.indicacaoLoja ? capitalize(valor.indicacaoLoja) : '';
      },
      sort: true,
    },
    {
      dataField: 'cpf',
      text: 'CPF',
      formatter: (celula, valor) => {
        let cpf = valor.cpf.replace(/[^\d]/g, '');
        return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
      },
    },
    {
      dataField: 'celular',
      text: 'Celular',
      formatter: (celula, valor) => {
        return `(${valor.celular.substring(0, 2)}) ${valor.celular.substring(
          2,
          7
        )}-${valor.celular.substring(7)}`;
      },
    },
    {
      dataField: 'totalPontos',
      text: 'Pontuação Total',
      formatter: (celula, valor) => {
        return Number(valor.totalPontos).toLocaleString('pt-BR');
      },
      sort: true,
      sortFunc: sortFunc,
    },
    {
      dataField: 'saldoPontos',
      text: 'Saldo de Pontos',
      formatter: (celula, valor) => {
        return Number(valor.saldoPontos).toLocaleString('pt-BR');
      },
      sort: true,
      sortFunc: sortFunc,
    },
    {
      text: 'Última Pontuação',
      dataField: 'ultimaPontuacao',
      formatter: (celula, valor) => {
        return valor.ultimaPontuacao && valor.ultimaPontuacao !== 0
          ? moment(valor.ultimaPontuacao).format('DD/MM/YYYY HH:mm:ss')
          : '';
      },
      sort: true,
    },
    {
      text: 'Dias sem Acessar',
      dataField: 'diasSemAcessar',
      formatter: (celula, valor) => {
        return valor.diasSemAcessar
          ? Number(valor.diasSemAcessar).toLocaleString('pt-BR')
          : '';
      },
      sort: true,
    },
    {
      text: 'Dias sem Pontuar',
      dataField: 'diasSemPontuar',
      formatter: (celula, valor) => {
        return valor.diasSemPontuar
          ? Number(valor.diasSemPontuar).toLocaleString('pt-BR')
          : '';
      },
      sort: true,
    },
    {
      text: 'Aceitou Regulamento',
      dataField: 'aceitouRegulamento',
      formatter: (celula, valor) => {
        return valor.aceitouRegulamento ? 'Sim' : 'Não';
      },
      sort: true,
    },
    {
      text: 'Alterar E-mail',
      dataField: 'aceitouRegulamento',
      formatter: (celula, valor) => {
        return (
          <button
            className="bg-transparent border-0"
            type="button"
            onClick={() => {
              setCpfPintor(valor.cpf);
              setNome(valor.nome);
              setShowModal(true);
            }}
          >
            <FontAwesomeIcon icon={faEdit} />
          </button>
        );
      },
      sort: true,
    },
    {
      text: 'Alterar Status',
      dataField: 'ativo',
      formatter: (celula, valor) => {
        return (
          <button
            className="bg-transparent border-0"
            type="button"
            onClick={() => {
              setCpfPintor(valor.cpf);
              setNome(valor.nome);
              setAtividadePintor(valor.ativo);
              setShowModalInativar(true);
            }}
          >
            <label class="switch">
              {valor.ativo === 1 ? (
                <input type="checkbox" checked disabled />
              ) : (
                <input type="checkbox" disabled />
              )}
              <span class="slider round"></span>
            </label>
          </button>
        );
      },
      sort: false,
    },
    {
      text: 'Status',
      dataField: 'ativo',
      formatter: (celula, valor) => {
        return valor.ativo === 1 ? 'Ativo' : 'Inativo';
      },
      sort: true,
    },
  ];

  return (
    <Container fluid>
      <h4 className="pt-3 pb-3 f-black">Empresas</h4>
      <hr className="hr-loja mt-0 mb-4" />
      <div>
        <RemoteTable
          exportar={{
            fileName: 'Empresas',
          }}
          dataId="cpf"
          url="/api/parceiros/empresas"
          colunas={columns}
          ordenacaoInicial={{ campo: 'saldoPontos', direcao: 'desc' }}
        />
      </div>
      {showModal && (
        <div id="modal">
          <div className="conteudoModal">
            <form onSubmit={atualizarSenha}>
              <label htmlFor="email">
                Insira o novo e-mail de: <br />
                <span>{nome}</span>
              </label>
              <input
                type="text"
                name="email"
                id="email"
                value={novoEmail}
                onChange={(e) => setNovoEmail(e.target.value)}
              />
              <div className="botoes">
                {!enviando ? (
                  <button type="submit">Alterar</button>
                ) : (
                  <button type="button" disabled>
                    Enviando...
                  </button>
                )}
                <button type="button" onClick={() => setShowModal(false)}>
                  Cancelar
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
      {showModalInativar && (
        <div id="modal">
          <div className="conteudoModal">
            <form onSubmit={mudarAtividadePintor}>
              {atividadePintor === 1 ? (
                <span className="textoInativar">
                  Tem certeza que deseja inativar o usuário?
                  <br />
                  <span>{nome}</span>
                </span>
              ) : (
                <span className="textoInativar">
                  Tem certeza que deseja ativar o usuário?
                  <br />
                  <span>{nome}</span>
                </span>
              )}
              <div className="botoes">
                {!enviando ? (
                  <button type="submit">
                    {atividadePintor === 1 ? 'Inativar' : 'Ativar'}
                  </button>
                ) : (
                  <button type="button" disabled>
                    Enviando...
                  </button>
                )}
                <button
                  type="button"
                  onClick={() => setShowModalInativar(false)}
                >
                  Cancelar
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </Container>
  );
}
