/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Container, Row, Col } from 'react-bootstrap';
import api from '../../../services/API';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import moment from 'moment-timezone';
import sortFunc from '../../../utils/ordernarColunaInt';

const ToSearch = (props) => {
  let input;
  const handleClick = () => {
    props.onSearch(input.value);
  };

  const handleEnterKey = (e) => {
    if (e.key === 'Enter') {
      props.onSearch(input.value);
    }
  };

  return (
    <div className="d-flex justify-content-center align-items-center">
      <input
        className="input-theme busca mr-1"
        ref={(n) => (input = n)}
        type="text"
        onKeyUp={handleEnterKey}
      />
      <button
        className="btn-primario-slim"
        onClick={handleClick}
        style={{ width: 40, height: 40 }}
      >
        <i className="fa fa-search px-2"></i>
      </button>
    </div>
  );
};

const meses = [
  'Janeiro',
  'Fevereiro',
  'Março',
  'Abril',
  'Maio',
  'Junho',
  'Julho',
  'Agosto',
  'Setembro',
  'Outubro',
  'Novembro',
  'Dezembro',
];

export default function ProdutosVendidos() {
  const columns = [
    {
      dataField: 'id',
      text: 'ID',
      sort: true,
      hidden: true,
      formatter: (celula, valor) => {
        return `${valor.revenda}${valor.codProduto}`;
      },
    },
    {
      dataField: 'revenda',
      text: 'Revenda',
      sort: true,
    },
    {
      dataField: 'mes',
      text: 'Mês',
      sort: true,
      formatter: (celula, valor) => {
        return meses[valor.mes - 1];
      },
    },
    {
      dataField: 'codProduto',
      text: 'Cód. Sherwin-Williams',
      sort: true,
    },
    {
      dataField: 'produto',
      text: 'Produto Vendido',
      sort: true,
    },
    {
      dataField: 'quantidade',
      text: 'Qtd.',
      sort: true,
      formatter: (celula, valor) => {
        return Number(valor.quantidade).toLocaleString('pt-BR');
      },
      sortFunc: sortFunc,
    },
    {
      dataField: 'valorUnitarioMedio',
      text: 'Valor Unitário Médio',
      sort: true,
      formatter: (celula, valor) => {
        return Number(valor.valorUnitarioMedio).toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        });
      },
      sortFunc: sortFunc,
    },
    {
      dataField: 'valorTotal',
      text: 'Valor Total',
      sort: true,
      formatter: (celula, valor) => {
        return Number(valor.valorTotal).toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        });
      },
      sortFunc: sortFunc,
    },
    {
      dataField: 'totalPontuado',
      text: 'Total Pontuado',
      sort: true,
      formatter: (celula, valor) => {
        return Number(valor.totalPontuado).toLocaleString('pt-BR');
      },
      sortFunc: sortFunc,
    },
  ];

  useEffect(() => {
    buscarRevendas();
    buscarPrimeiraData();
  }, []);

  const buscarRevendas = async () => {
    const resultado = await api.get('/api/revendas?ativo=1');
    if (resultado && resultado.data.revendas) {
      setRevendasSelect(resultado.data.revendas);
    }
  };

  const buscarHistorico = async () => {
    setBuscando(true);
    const resultado = await api.get(
      `/api/produtos_vendidos?revenda=${revendaSelecionada}&ano=${anoSelecionado}`
    );
    if (resultado && resultado.data.produtosVendidos) {
      setData(resultado.data.produtosVendidos);
    }
    setBuscando(false);
  };

  const [data, setData] = useState([]);
  const [anoSelecionado, setAnoSelecionado] = useState(
    Number(new Date().getFullYear())
  );
  const [anos, setAnos] = useState([]);
  const [revendaSelecionada, setRevendaSelecionada] = useState('');
  const [revendasSelect, setRevendasSelect] = useState([]);
  const [buscando, setBuscando] = useState(false);

  const buscarPrimeiraData = async () => {
    const resultado = await api.get('/api/historico/primeiraData');
    if (resultado && resultado.data.primeiraData) {
      const anos = [];
      for (
        let i = Number(moment(resultado.data.primeiraData).format('YYYY'));
        i <= Number(moment().format('YYYY'));
        i += 1
      ) {
        anos.push(Number(i));
      }
      setAnos(anos);
    } else {
      anos.push(Number(moment().format('YYYY')));
      setAnos(anos);
    }
  };

  const options = {
    sizePerPage: 10,
    showTotal: true,
    sizePerPageList: [
      {
        text: '5',
        value: 5,
      },
      {
        text: '10',
        value: 10,
      },
      {
        text: '50',
        value: 50,
      },
      {
        text: '100',
        value: 100,
      },
      {
        text: 'Todos',
        value: data.length,
      },
    ],
    paginationTotalRenderer: (from, to, size) =>
      size > 0 ? (
        <span className="react-bootstrap-table-pagination-total px-2">
          {from}-{to} de {size} resultados
        </span>
      ) : (
        <span className="react-bootstrap-table-pagination-total px-2">
          Nenhum resultado
        </span>
      ),
  };

  return (
    <Container fluid>
      <h4 className="pt-3 pb-3 f-black">
        {buscando ? 'Buscando...' : 'Produtos Vendidos'}
      </h4>
      <hr className="hr-loja mt-0 mb-4" />
      <Row className="mb-3">
        <Col xs={12} sm={6} md={4} lg={3} className="d-flex flex-column">
          <label htmlFor="revendaSelecionada">Selecione uma Revenda</label>
          <select
            className="input-select mb-3"
            name="revendaSelecionada"
            id="revendaSelecionada"
            onChange={(e) => setRevendaSelecionada(e.target.value)}
            defaultValue=""
            placeholder=""
          >
            <option value="">Todas</option>
            {revendasSelect.map((revenda) => (
              <option value={revenda.cnpj} key={revenda.cnpj}>
                {revenda.nomeFantasia}
              </option>
            ))}
          </select>
        </Col>
        <Col xs={12} sm={3} md={3} lg={2} className="d-flex flex-column">
          <label htmlFor="anoSelecionado">Ano</label>
          <select
            className="input-select mb-3"
            name="anoSelecionado"
            id="anoSelecionado"
            onChange={(e) => setAnoSelecionado(e.target.value)}
            value={anoSelecionado}
          >
            {anos.map((ano) => (
              <option value={ano} key={ano}>
                {ano}
              </option>
            ))}
          </select>
        </Col>
        <Col
          xs={12}
          sm={1}
          className="d-flex flex-column justify-content-center align-items-center d-sm-block"
        >
          <button className="btn-busca" type="submit" onClick={buscarHistorico}>
            <i className="fas fa-search"></i>
          </button>
        </Col>
      </Row>
      <div>
        {!buscando && (
          <ToolkitProvider
            bootstrap4
            keyField="id"
            data={data}
            columns={columns}
            search
          >
            {(props) => (
              <div>
                <div className="d-flex flex-row-reverse mb-2">
                  <ToSearch {...props.searchProps} />
                </div>

                <BootstrapTable
                  noDataIndication={() =>
                    buscando ? (
                      <span>
                        Carregando
                        <i className="fas fa-circle-notch fa-spin ml-3"></i>
                      </span>
                    ) : (
                      <span>Sem resultados</span>
                    )
                  }
                  classes="table-dark text-center rounded"
                  wrapperClasses="rounded"
                  bordered={false}
                  pagination={paginationFactory(options)}
                  {...props.baseProps}
                />
              </div>
            )}
          </ToolkitProvider>
        )}
      </div>
    </Container>
  );
}
