import { all } from 'redux-saga/effects';

import autenticacao from './autenticacao/sagas';
import usuario from './usuario/sagas';
import parceiro from './parceiro/sagas';
import usuarioRelatorio from './usuarioRelatorio/sagas';

export default function* rootSaga() {
  return yield all([autenticacao, usuario, parceiro, usuarioRelatorio]);
}
