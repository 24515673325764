import produce from 'immer';

const estadoInicial = {
  nome: null,
  cnpj: null,
  email: null,
  permissao: 0,
  atualizando: false,
};

export default function usuarioRelatorio(state = estadoInicial, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@autenticacaoUsuario/DESAUTENTICAR': {
        draft.nome = null;
        draft.cnpj = null;
        draft.email = null;
        draft.permissao = 0;
        draft.atualizando = false;
        break;
      }
      case '@autenticacaoUsuario/AUTENTICAR_SUCESSO': {
        draft.nome = action.payload.usuario.nome;
        draft.cnpj = action.payload.usuario.cnpj;
        draft.email = action.payload.usuario.email;
        draft.permissao = action.payload.usuario.permissao;
        break;
      }
      case '@usuarioRelatorio/ATUALIZAR_PERFIL': {
        draft.atualizando = true;
        break;
      }
      case '@usuarioRelatorio/ATUALIZAR_PERFIL_FALHOU': {
        draft.atualizando = false;
        break;
      }
      case '@usuarioRelatorio/ATUALIZAR_PERFIL_SUCESSO': {
        draft.nome = action.payload.nome;
        draft.senha = action.payload.senha;
        draft.atualizando = false;
        break;
      }
      default:
    }
  });
}
