import { combineReducers } from 'redux';

import autenticacao from './autenticacao/reducer';
import usuario from './usuario/reducer';
import usuarioRelatorio from './usuarioRelatorio/reducer';
import parceiro from './parceiro/reducer';

export default combineReducers({
  autenticacao,
  usuario,
  usuarioRelatorio,
  parceiro,
});
