const capitalize = (str) =>
  str
    ? str
        .toLowerCase()
        .split(' ')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ')
        .split('-')
        .map((word) => `${word.charAt(0).toUpperCase() + word.slice(1)}`)
        .join('-')
    : '';

export default capitalize;
