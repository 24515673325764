import React, { useEffect, useState } from 'react';
import { Container, Row } from 'react-bootstrap';
import './styles.css';
import { useDispatch, useSelector } from 'react-redux';
import { autenticarUsuarioRelatorio } from '../../../store/modules/autenticacao/actions';
import InputMask from 'react-input-mask';

export default function Login() {
  const dispatch = useDispatch();
  const enviando = useSelector(
    (state) => state.autenticacao.autenticandoPainelRelatorio
  );

  const [cnpj, setCnpj] = useState('');
  const [senha, setSenha] = useState('');

  useEffect(() => {
    document.title = 'Clube Pro Pintor - Relatório';
  }, []);

  const solicitarAutenticacao = (e) => {
    e.preventDefault();
    const cnpjLimpo = cnpj.replace(/[^\d]/g, '');
    dispatch(autenticarUsuarioRelatorio(cnpjLimpo, senha));
  };

  const validarCaractereCnpj = (e) => {
    const theEvent = e;
    let key = null;
    if (theEvent.type === 'paste') {
      key = window.event.clipboardData.getData('text/plain');
    } else {
      key = theEvent.keyCode || theEvent.which;
      key = String.fromCharCode(key);
    }
    const regex = /[0-9]|\./;
    if (!regex.test(key)) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) theEvent.preventDefault();
    }
  };

  return (
    <div className="d-flex flex-column justify-content-center w-100 h-100 bg-black">
      <Container fluid>
        <Row className="justify-content-center">
          <img
            className="w-100 form-login-logo"
            src="/imagens/logo-fundo-escuro.png"
            alt="Logo Clube Pro Pintor"
          ></img>
        </Row>
        <Row className="justify-content-center p-5">
          <form className="form-login" onSubmit={solicitarAutenticacao}>
            <h5>FAÇA SEU LOGIN</h5>
            <label>Entre com seus dados e acesse sua conta</label>
            <label htmlFor="cnpj">CNPJ</label>
            <InputMask
              name="cnpj"
              type="text"
              placeholder="CNPJ"
              value={cnpj}
              mask="99.999.999/9999-99"
              onChange={(e) => setCnpj(e.target.value)}
              onKeyPress={validarCaractereCnpj}
              autoComplete="nofill"
            />
            <label htmlFor="senha">Senha</label>
            <input
              name="senha"
              type="password"
              placeholder="Senha"
              value={senha}
              onChange={(e) => setSenha(e.target.value)}
              autoComplete="nofill"
            ></input>
            {enviando ? (
              <button disabled className="btn-primario" type="submit">
                VALIDANDO...
              </button>
            ) : (
              <button className="btn-primario" type="submit">
                ENTRAR
              </button>
            )}
          </form>
        </Row>
      </Container>
    </div>
  );
}
