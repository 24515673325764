import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { toast } from 'react-toastify';
import api from '../../../services/API';
import InputMask from 'react-input-mask';
import SelectCidades from '../SelectCidades/index';
import validarCnpj from '../../../utils/validarCnpj';

export default function CadastrarRevenda() {
  const [enviando, setEnviando] = useState(false);

  const [cnpj, setCnpj] = useState('');
  const [nomeFantasia, setNomeFantasia] = useState('');
  const [nomeExibicao, setNomeExibicao] = useState('');
  const [endComplemento, setComplemento] = useState('');
  const [endBairro, setBairro] = useState('');
  const [endCidade, setCidade] = useState('');
  const [endEstado, setEstado] = useState('');

  const validarCampos = (data) => {
    const entries = Object.entries(data);
    let temNulo = false;
    entries.forEach((param) => {
      if (temNulo) return;
      if (
        param[1] === '' &&
        param[0] !== 'endComplemento' &&
        param[0] !== 'endCidade'
      ) {
        const elemento = document.getElementsByName(param[0])[0];
        toast.error(`${elemento.placeholder} obrigatório`, {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 10000,
        });
        temNulo = true;
        return;
      }
    });

    return temNulo;
  };

  const zerarForm = () => {
    setCnpj('');
    setNomeFantasia('');
    setNomeExibicao('');
    setComplemento('');
    setBairro('');
    setCidade('');
    setEstado('');
  };

  const buscarCnpj = async (element) => {
    let input = element.target;
    let cnpj = input.value.replace(/[^\d]/g, '');
    if (!cnpj) return;
    const filtros = {
      cnpj,
    };
    const resultado = await api.get(
      `/api/revendas?filtros=${JSON.stringify(filtros)}`
    );
    if (resultado.data.revendas.length > 0) {
      toast.error('CNPJ já cadastrado', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      setCnpj('');
    }
  };

  const cadastrarRevenda = async (e) => {
    e.preventDefault();
    const data = {
      cnpj: cnpj.replace(/[^\d]/g, ''),
      nomeFantasia,
      nomeInput: nomeExibicao,
      endComplemento,
      endBairro,
      endCidade,
      endEstado,
    };

    const temNulo = validarCampos(data);
    if (temNulo) {
      return;
    }

    setEnviando(true);
    const resultado = await api.post('/api/revendas', data);

    if (resultado) {
      toast.success('Revenda cadastrada com sucesso', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      zerarForm();
    }
    setEnviando(false);
  };

  const validaCNPJ = (e) => {
    if (!e.target.value) {
      return;
    }
    if (!validarCnpj(e.target.value)) {
      setCnpj('');
      toast.error('CNPJ Inválido', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      return;
    }
    buscarCnpj(e);
  };

  return (
    <Container fluid>
      <div>
        <h4 className="pt-3 pb-3 f-black">Cadastrar Revenda</h4>
        <hr className="hr-loja mt-0 mb-5" />
      </div>
      <div className="d-flex flex-column align-items-center">
        <form className="form" onSubmit={cadastrarRevenda}>
          <Row>
            <Col className="d-flex flex-column" xs={12}>
              <label htmlFor="cnpj">CNPJ</label>
              <InputMask
                id="cnpj"
                name="cnpj"
                type="text"
                placeholder="CNPJ"
                mask="99.999.999/9999-99"
                value={cnpj}
                onChange={(e) => setCnpj(e.target.value)}
                onBlur={validaCNPJ}
              ></InputMask>
            </Col>
          </Row>

          <label htmlFor="nomeFantasia">Nome Fantasia</label>
          <input
            id="nomeFantasia"
            name="nomeFantasia"
            type="text"
            placeholder="Nome Fantasia"
            value={nomeFantasia}
            onChange={(e) => setNomeFantasia(e.target.value)}
          ></input>
          <label htmlFor="nomeExibicao">Nome de exibição</label>
          <input
            id="nomeExibicao"
            name="nomeExibicao"
            type="text"
            placeholder="Nome de exibição"
            value={nomeExibicao}
            onChange={(e) => setNomeExibicao(e.target.value)}
          ></input>
          <Row>
            <Col className="d-flex flex-column" xs={12} sm={6}>
              <label htmlFor="endEstado">Estado</label>
              <select
                id="endEstado"
                name="endEstado"
                type="text"
                placeholder="Estado"
                value={endEstado}
                onChange={(e) => setEstado(e.target.value)}
              >
                <option disabled value="">
                  Estado
                </option>
                <option value="AC">Acre</option>
                <option value="AL">Alagoas</option>
                <option value="AP">Amapá</option>
                <option value="AM">Amazonas</option>
                <option value="BA">Bahia</option>
                <option value="CE">Ceará</option>
                <option value="DF">Distrito Federal</option>
                <option value="ES">Espírito Santo</option>
                <option value="GO">Goiás</option>
                <option value="MA">Maranhão</option>
                <option value="MT">Mato Grosso</option>
                <option value="MS">Mato Grosso do Sul</option>
                <option value="MG">Minas Gerais</option>
                <option value="PA">Pará</option>
                <option value="PB">Paraíba</option>
                <option value="PR">Paraná</option>
                <option value="PE">Pernambuco</option>
                <option value="PI">Piauí</option>
                <option value="RJ">Rio de Janeiro</option>
                <option value="RN">Rio Grande do Norte</option>
                <option value="RS">Rio Grande do Sul</option>
                <option value="RO">Rondônia</option>
                <option value="RR">Roraima</option>
                <option value="SC">Santa Catarina</option>
                <option value="SP">São Paulo</option>
                <option value="SE">Sergipe</option>
                <option value="TO">Tocantins</option>
              </select>
            </Col>
            <Col className="d-flex flex-column" xs={12} sm={6}>
              <label htmlFor="endCidade">Cidade</label>
              <SelectCidades endEstado={endEstado} onChange={setCidade} />
            </Col>
          </Row>
          <Row>
            <Col className="d-flex flex-column" xs={12} sm={6}>
              <label htmlFor="endBairro">Bairro</label>
              <input
                id="endBairro"
                name="endBairro"
                type="text"
                placeholder="Bairro"
                value={endBairro}
                onChange={(e) => setBairro(e.target.value)}
              ></input>
            </Col>
            <Col className="d-flex flex-column" xs={12} sm={6}>
              <label htmlFor="endComplemento">Complemento</label>
              <input
                id="endComplemento"
                name="endComplemento"
                type="text"
                placeholder="Complemento"
                value={endComplemento}
                onChange={(e) => setComplemento(e.target.value)}
              ></input>
            </Col>
          </Row>
          {enviando ? (
            <button disabled className="btn-primario" type="submit">
              CADASTRANDO...
            </button>
          ) : (
            <button className="btn-primario" type="submit">
              CADASTRAR
            </button>
          )}
        </form>
      </div>
    </Container>
  );
}
