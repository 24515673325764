import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { atualizarPerfil } from '../../../store/modules/parceiro/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useApi } from '../../../hooks/useApi';
import api from '../../../services/API';
import { toast } from 'react-toastify';

export default function AcheSeuPintor() {
  const perfilInputRef = React.useRef();
  const galeriaInputRef = React.useRef();

  const [categorias, setCategorias] = useState([]);
  const [avaliacoesPaginaEscolhida, setAvaliacoesPaginaEscolhida] = useState(1);

  const [{ data: servicos = [] }] = useApi({
    url: '/api/categorias',
    method: 'GET',
  });
  const [{ data: galeria = [] }] = useApi({
    url: '/api/sessoes/parceiro/galeria',
    method: 'GET',
  });
  const [{ data: contatos = [] }] = useApi({
    url: '/api/sessoes/parceiro/avaliacoes',
    method: 'GET',
  });
  const [{ data: certificado = true }] = useApi({
    url: '/api/sessoes/parceiro/tem-certificado',
    method: 'GET',
  });

  const avaliacoes = () => {
    const filtered = contatos.filter(
      (contact) => (contact.status || '').toUpperCase() === 'AVALIADO'
    );
    const totalPages = Math.ceil(filtered.length / 5);
    const pageArray = Array.from({ length: totalPages }, (_, i) => i + 1);

    return {
      avaliacoes: filtered,
      total: filtered.length,
      paginas: pageArray,
    };
  };

  const avaliacoesPagina = (page = 1) => {
    const allAvaliacoes = avaliacoes().avaliacoes;
    const startIndex = (page - 1) * 5;
    const endIndex = startIndex + 5;
    const pageAvaliacoes = allAvaliacoes.slice(startIndex, endIndex);

    return pageAvaliacoes || [];
  };

  const onAvaliacoesPagina = (event, int) => {
    event.preventDefault();
    setAvaliacoesPaginaEscolhida(int);
  };

  // const atualizando = useSelector((state) => {
  //   return state.parceiro.atualizando;
  // });
  const dispatch = useDispatch();

  const parceiro = useSelector((state) => {
    // console.log('parceiro: ', state.parceiro);
    return state.parceiro;
  });

  const [sobre, setSobre] = useState(parceiro.sobre);
  const [acheSeuPintor, setAcheSeuPintor] = useState(parceiro.acheSeuPintor);
  const [imgPerfil, setImgPerfil] = useState(null);
  const [imgPerfilBase64, setimgPerfilBase64] = useState(null);
  const [image, setImage] = useState([]);
  const [imagesBase64, setImagesBase64] = useState([]);
  const [updateParceiro, setUpdateParceiro] = useState(false);

  const onChangeCategoria = async (e) => {
    const find = servicos.find((s) => s.id == e.currentTarget.value);

    if (find) {
      find.mine = e.currentTarget.checked;
    }
  };

  const removeImagesBase64 = async (event, index) => {
    event.preventDefault();

    const list_base64 = imagesBase64;
    list_base64.splice(index, 1);

    setImagesBase64(JSON.parse(JSON.stringify(list_base64)));

    var attachments = document.getElementById('attachment').files; // <-- reference your file input here
    var fileBuffer = new DataTransfer();

    // append the file list to an array iteratively
    for (let i = 0; i < attachments.length; i++) {
      // Exclude file in specified index
      if (index !== i) fileBuffer.items.add(attachments[i]);
    }

    // Assign buffer to file input
    document.getElementById('attachment').files = fileBuffer.files; // <-- according to your file input reference
    setImage(fileBuffer.files);
  };

  const removeGaleria = async (event, item, index) => {
    event.preventDefault();

    const { data: deleted } = await api({
      url: '/api/sessoes/parceiro/midias/' + item.id,
      method: 'DELETE',
      data: {},
    });

    window.location.reload(false);
  };

  const atualizarParceiro = async (e) => {
    e.preventDefault();
    const data = {
      acheSeuPintor,
    };
    dispatch(atualizarPerfil(data));
  };

  const onGaleria = async (e) => {
    if (e.target.files.length > 10) {
      alert('Você pode selecionar apenas 10 imagens');
      setImage([]);
      return;
    }

    const read = (file) => {
      return new Promise((resolve) => {
        const reader = new FileReader();

        reader.onload = (readerEvt) => {
          const binaryString = readerEvt.target.result;
          resolve(`data:image/png;base64,${btoa(binaryString)}`);
        };

        reader.readAsBinaryString(file);
      });
    };
    setImage(e.target.files);

    const list = [];
    for (const file of e.target.files) {
      list.push(await read(file));
    }
    setImagesBase64(list);
  };

  const [foto, setFoto] = useState();
  const onPerfil = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setFoto(e.target.files);
      setImgUrl(
        e.target.files.length > 0 && URL.createObjectURL(e.target.files[0])
      );
    }
  };

  const perfilInputOpen = () => {
    perfilInputRef.current.click();
  };

  const galeriaInputOpen = () => {
    galeriaInputRef.current.click();
  };

  const update = async (e) => {
    e.preventDefault();

    if (!certificado && acheSeuPintor) {
      alert(
        'Você precisa completar um curso para fazer parte do Ache Seu Pintor.'
      );
    }

    const categoriasAtualizadas = servicos
      .filter((item) => item.mine)
      .map((item) => item.id);

    let formData = new FormData();

    formData.append('imgPerfil', imgPerfil);

    for (const item of image) {
      formData.append('galeria', item);
    }

    console.log('1.1');

    const { data: galeria } = await api({
      url: '/api/sessoes/parceiro/midias-ache-seu-pintor',
      method: 'POST',
      // headers: { Authorization: `Bearer ${parceiro.access_token}` },
      data: formData,
    });

    console.log('2.1');

    setCategorias(categoriasAtualizadas);
    // setUpdateParceiro(true);

    console.log('3.1', categoriasAtualizadas);

    const { data: parceiro } = await api({
      url: '/api/parceiros',
      method: 'PUT',
      data: {
        sobre,
        acheSeuPintor,
        categorias: categoriasAtualizadas,
      },
    });

    if (foto && foto.length > 0) {
      console.log('foto: ', foto);
      const formData = new FormData();
      formData.append('arquivo', foto[0]);
      formData.append('idParceiro', parceiro.id);
      try {
        const response = await api.post('/api/upload/parceiro_foto', formData);
        parceiro.foto = response.data.foto;
      } catch (e) {
        toast.error('Erro ao atualizar foto de perfil.', {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 10000,
        });
      }
    }

    const cache = JSON.parse(localStorage.getItem('persist:sherwin-williams'));
    cache.parceiro = JSON.stringify(parceiro);
    localStorage.setItem('persist:sherwin-williams', JSON.stringify(cache));

    console.log('4.1');

    toast.success(`Atualizado com sucesso`, {
      position: toast.POSITION.BOTTOM_CENTER,
    });
  };

  const [imgUrl, setImgUrl] = useState(
    `${process.env.REACT_APP_API_BASE_URL}/arquivos/${parceiro.foto}`
  );

  return (
    <div>
      {/*<img*/}
      {/*  style={{ width: '100%' }}*/}
      {/*  className="d-none d-md-block "*/}
      {/*  src={'/imagens/Banner_Cadastro_De_Pintor.1beb1d14a86b35e360a0.png'}*/}
      {/*  alt=""*/}
      {/*/>*/}

      <Container className="mt-5 mb-5">
        <div>
          <h5>ACHE SEU PINTOR</h5>
          <hr className="hr-loja mt-0" />
        </div>
        <div>
          <form onSubmit={atualizarParceiro} className="">
            <Row style={{ width: '100%' }}>
              <Col className="d-flex flex-column" xs={12} sm={12} md={12}>
                {certificado ? (
                  ''
                ) : (
                  <div className="alert alert-danger">
                    <i className="fa fa-exclamation-triangle"></i> Você precisa
                    completar um curso para fazer parte do Ache Seu Pintor.
                  </div>
                )}
              </Col>
            </Row>

            <Row style={{ width: '100%' }}>
              <Col className="d-flex flex-column" xs={12} sm={12} md={6}>
                <div
                  className="m-2 p-4 pt-2 row"
                  style={{
                    background: 'rgb(229, 229, 229)',
                    borderRadius: '1rem',
                  }}
                >
                  <div>
                    <h5 className="mb-3 h3">
                      <strong>Dúvidas Frequentes</strong>
                    </h5>
                  </div>

                  <p className="text-justify pt-3">
                    <b style={{ color: 'rgb(0, 79, 153)' }}>
                      Pintou uma dúvida?
                    </b>
                    <br />
                    <b>Não se preocupe:</b> estamos aqui para ajudar você!
                  </p>

                  <p className="text-justify pt-3">
                    <b style={{ color: 'rgb(0, 79, 153)' }}>
                      Como posso me tornar um pintor 5 estrelas?
                    </b>
                    <br />
                    Para ter 5 estrelas, é necessario que seus clientes deixem
                    pelo menos 10 comentários com a classificação "satisfeito"
                  </p>

                  <p className="text-justify pt-3">
                    <b style={{ color: 'rgb(0, 79, 153)' }}>
                      Porque um pintor receber 5 estrelas?
                    </b>
                    <br />
                    Além de ser um exemplo para seus colegas e considerado para
                    mais trabalhos, o pintor 5 estrelas ainda ganha 500 pontos
                    no Clube PRO Pintor para trocar pelo que quiser.
                  </p>
                </div>
                <div
                  className="m-2 pl-4 pr-4 pt-4 mt-5 row"
                  style={{
                    background: 'rgb(229, 229, 229)',
                    borderRadius: '1rem',
                    paddingBottom: '8px',
                  }}
                >
                  <div className="w-100">
                    <h5 className="mb-3 h3">
                      <strong>Contatos e Avaliações</strong>
                    </h5>
                  </div>

                  <div className="w-100">
                    <p className="text-justify">
                      <b style={{ color: 'rgb(0, 79, 153)' }}>
                        {contatos.length} contatos e {avaliacoes().total}{' '}
                        avaliações
                      </b>
                      <br />
                    </p>
                  </div>

                  {avaliacoesPagina(avaliacoesPaginaEscolhida).map((e) => (
                    <div
                      className="text-justify pt-2 pb-3 w-100"
                      style={{ borderBottom: '1px solid rgba(0,0,0,0.2)' }}
                    >
                      <div className="pb-2">
                        <i
                          className="fa fa-star"
                          style={{
                            color: e.nota >= 1 ? 'rgb(250, 175, 0)' : '#ccc',
                          }}
                        ></i>
                        <i
                          className="fa fa-star"
                          style={{
                            color: e.nota >= 2 ? 'rgb(250, 175, 0)' : '#ccc',
                          }}
                        ></i>
                        <i
                          className="fa fa-star"
                          style={{
                            color: e.nota >= 3 ? 'rgb(250, 175, 0)' : '#ccc',
                          }}
                        ></i>
                        <i
                          className="fa fa-star"
                          style={{
                            color: e.nota >= 4 ? 'rgb(250, 175, 0)' : '#ccc',
                          }}
                        ></i>
                        <i
                          className="fa fa-star"
                          style={{
                            color: e.nota >= 5 ? 'rgb(250, 175, 0)' : '#ccc',
                          }}
                        ></i>
                      </div>

                      {e.avaliacao}
                    </div>
                  ))}
                  <div className={'w-100 pt-3 text-right'}>
                    <ul className="pagination float-right">
                      {avaliacoes().paginas.map((item) => (
                        <li className="page-item">
                          <a
                            onClick={(e) => onAvaliacoesPagina(e, item)}
                            className="page-link"
                            href="#"
                          >
                            {item}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </Col>
              <Col className="d-flex flex-column" xs={12} sm={12} md={6}>
                <Row style={{ width: '100%' }}>
                  <Col xs={12} sm={12}>
                    <label htmlFor="cpf">
                      Quero Fazer Parte do Ache Seu Pintor
                    </label>
                    <select
                      disabled={!certificado}
                      className={'w-100 form-control'}
                      value={acheSeuPintor}
                      onChange={(e) => setAcheSeuPintor(e.target.value)}
                    >
                      <option value={true}>Sim</option>
                      <option value={false}>Não</option>
                    </select>
                  </Col>

                  <Col className="d-flex flex-column pt-3" xs={12} sm={12}>
                    <span>Foto de Pefil:</span>
                    <img
                      onClick={perfilInputOpen}
                      src={imgPerfilBase64 || imgUrl || '/imagens/no-pic.png'}
                      style={{
                        width: '250px',
                        height: '250px',
                        borderRadius: '50%',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        border: '1px solid #ccc',
                      }}
                    />

                    <input
                      type="file"
                      accept="image/*"
                      hidden
                      name={'onPerfil'}
                      onChange={onPerfil}
                      required
                      ref={perfilInputRef}
                    />
                  </Col>
                  <Col className="d-flex flex-column pt-3" xs={12} sm={12}>
                    <span>Seu Perfil:</span>
                    <br />
                    <textarea
                      className="form-control"
                      name="sobre"
                      placeholder="Escreva sobre você..."
                      rows={4}
                      value={sobre}
                      onChange={(e) => setSobre(e.target.value)}
                    />
                  </Col>
                  <Col className="d-flex flex-column pt-3" xs={12} sm={12}>
                    <span className="pb-3">
                      Em quais categorias de pintura você tem experiência e
                      expertise?
                    </span>
                  </Col>

                  {servicos.map((e) => (
                    <Col xs={12} sm={6} md={6} key={e.id}>
                      <table>
                        <tbody>
                          <tr>
                            <td style={{ paddingRight: '8px' }}>
                              <input
                                name={e.id}
                                type="checkbox"
                                defaultChecked={e.mine}
                                value={e.id}
                                onChange={onChangeCategoria}
                              />
                            </td>
                            <td>
                              <span>{e.nome}</span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </Col>
                  ))}

                  <Col className="d-flex flex-column pt-3" xs={12} sm={12}>
                    <table style={{ width: '100%' }}>
                      <tbody>
                        <tr>
                          <td
                            colSpan={imgPerfil ? 1 : 2}
                            className="pt-3"
                            style={{ verticalAlign: 'middle' }}
                          >
                            Anexe aqui sua galeria:
                          </td>
                          <td className="pt-3">
                            <button
                              className="btn-atualizar"
                              style={{ width: '100%' }}
                              onClick={galeriaInputOpen}
                            >
                              {image.length == 0
                                ? 'Selecione arquivos'
                                : `${image.length} arquivos selecionados`}
                              <input
                                type="file"
                                id="attachment"
                                hidden
                                multiple
                                required
                                accept="image/*"
                                name={'onGaleria'}
                                onChange={onGaleria}
                                ref={galeriaInputRef}
                              />
                            </button>
                          </td>
                        </tr>

                        <tr>
                          <td colSpan={imgPerfil ? 2 : 3}>
                            <Row>
                              {galeria.map((e, i) => (
                                <Col
                                  xs={4}
                                  sm={4}
                                  md={4}
                                  key={e.id}
                                  className="pt-4 text-center"
                                  id={'galeria-' + e.id}
                                >
                                  <img
                                    src={e.image}
                                    style={{
                                      width: '100%',
                                      padding: '4px',
                                      borderRadius: '4px',
                                      border: '1px solid #ccc',
                                    }}
                                  />
                                  <br />
                                  <a
                                    style={{ color: 'red' }}
                                    href="javascript:void(0)"
                                    onClick={(ev) => {
                                      ev.preventDefault();
                                      removeGaleria(ev, e, i);
                                    }}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    <small>Remover</small>
                                  </a>
                                </Col>
                              ))}
                              {imagesBase64.map((e, i) => (
                                <Col
                                  xs={4}
                                  sm={4}
                                  md={4}
                                  key={e.id}
                                  className="pt-4 text-center"
                                >
                                  <img
                                    src={e}
                                    style={{
                                      width: '100%',
                                      padding: '4px',
                                      borderRadius: '4px',
                                      border: '1px solid #ccc',
                                    }}
                                  />
                                  <br />
                                  <a
                                    style={{ color: 'red' }}
                                    href="#"
                                    onClick={(ev) => removeImagesBase64(ev, i)}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    <small>Remover</small>
                                  </a>
                                </Col>
                              ))}
                            </Row>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Col>

                  <Col xs={12} sm={12} md={12} className={'pt-3'}>
                    <button
                      className="btn-atualizar"
                      style={{ width: '100%' }}
                      onClick={update}
                    >
                      Salvar
                    </button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </form>
        </div>
      </Container>
    </div>
  );
}
