import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { store } from '../../store';

export default function RotaPainel({
  component: Component,
  rotaPrivada = false,
  ...atributos
}) {
  const { autenticadoPainelRelatorio } = store.getState().autenticacao;

  if (!autenticadoPainelRelatorio && rotaPrivada) {
    return <Redirect to="/relatorio/" />;
  }

  if (autenticadoPainelRelatorio && !rotaPrivada) {
    return <Redirect to="/relatorio/dashboard" />;
  }

  return <Route {...atributos} component={Component} />;
}
